import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Screen from "../pages/OI/screen";
import UI from "../pages/UI";
import Manufacture from "../pages/OI/Manufacture";
import Quality from "../pages/OI/Quality";
import Equipment from "../pages/OI/Equipment";
import Warehouse from "../pages/OI/Warehouse";

import UIManufacture from "../pages/UI/Manufacture";
import UIManufactureKHSX from "../pages/UI/Manufacture/KeHoachSanXuat";
import UIManufactureSL from "../pages/UI/Manufacture/SanLuong";
import UIManufactureTD from "../pages/UI/Manufacture/TienDo";
import UIManufactureLSSX from "../pages/UI/Manufacture/LichSuSanXuat";
import UIQualityPQC from "../pages/UI/Quality/PQC";
import UIQualityOQC from "../pages/UI/Quality/OQC";
import UIEquipment1 from "../pages/UI/Equipment/Equipment1";
import UIEquipment2 from "../pages/UI/Equipment/Equipment2";
import UIKPI from "../pages/UI/KPI";
import UIDashboard from "../pages/UI/Dashboard";
import WarehouseExportPlan from "../pages/UI/Warehouse/WarehouseExportPlan";
import ThanhPhamGiay from "../pages/UI/Warehouse/ThanhPhamGiay";
import UISoDoCanhBao from "../pages/UI/SoDoCanhBao";
import UIKichBanSuCo from "../pages/UI/KichBanSuCo";
import DBTinhHinhSanXuat from "../pages/DB/TinhHinhSanXuat";
import DBHieuSuatThietBi from "../pages/DB/HieuSuatThietBi";
import DBCanhBaoBatThuong from "../pages/DB/CanhBaoBatThuong";
import DashBoard from "../pages/DB";
import Logout from "../pages/Authentication/Logout";
import Kichban from "../pages/UI/Abnormal/Kichban";
import Giamsat from "../pages/UI/Abnormal/Giamsat";
import InTem from "../pages/OI/InTem";
import Machine from "../pages/UI/MasterData/Machine";
import Errors from "../pages/UI/MasterData/Errors";
import TestCriteria from "../pages/UI/MasterData/TestCriteria";
import Line from "../pages/UI/MasterData/Line";
import Users from "../pages/UI/MasterData/Users";
import Roles from "../pages/UI/MasterData/Roles";
import Permissions from "../pages/UI/MasterData/Permissions";
import ChangePassword from "../pages/Authentication/ChangePassword";
import UIQualityError from "../pages/UI/Quality/Error";
import UIQualityIQC from "../pages/UI/Quality/IQC";
import PowerConsumeByProduct from "../pages/UI/Equipment/PowerConsumeByProduct";
import PowerConsumeByMonth from "../pages/UI/Equipment/PowerConsumeByMonth";
import PowerConsumeByMachine from "../pages/UI/Equipment/PowerConsumeByMachine";
import Orders from "../pages/UI/Manufacture/Orders";
import Maintenance from "../pages/UI/Equipment/Maintenance";
import DetailMaintenance from "../pages/UI/Equipment/DetailMaintenance";
import LenhGiaoHang from "../pages/UI/Manufacture/LenhGiaoHang";
import FC from "../pages/UI/Manufacture/FC";
import KeHoachSanXuatTuDong from "../pages/UI/Manufacture/KeHoachSanXuatTuDong";
import Product from "../pages/UI/MasterData/Product";
import Material from "../pages/UI/MasterData/Material";
import Bom from "../pages/UI/MasterData/Bom";
import ProductionJourney from "../pages/UI/MasterData/ProductionJourney";
import Customer from "../pages/UI/MasterData/Customer";
import ProductOrder from "../pages/UI/MasterData/ProductOrder";
import VOC from "../pages/UI/Dashboard";
import LoadFactor from "../pages/UI/Manufacture/LoadFactor";
import Iot from "../pages/DB/Iot";
import Template from "../pages/UI/MasterData/Template";
import NangSuat from "../pages/UI/Manufacture/NangSuat";
import ProductionSituation1 from "../pages/DB/ProductionSituation1";
import ProductionSituation2 from "../pages/DB/ProductionSituation2";
import Stamp from "../pages/UI/MasterData/Stamp";
import GanttChart from "../pages/UI/Manufacture/GanttChart";
import TaoKeHoachSanXuat from "../pages/UI/Manufacture/TaoKeHoachSanXuat";
import MachinePriorityOrder from "../pages/UI/MasterData/MachinePriorityOrder";
import ExcelHeader from "../pages/UI/MasterData/ExcelHeader";
import MachineShift from "../pages/UI/MasterData/MachineShift";
import P2ManualManufacture from "../pages/OI/ManualManufacture/P2ManualManufacture";
import Shifts from "../pages/UI/MasterData/Shift";
import PPTist from "../pages/UI/KPI/PPTist";
import EditProduct from "../pages/UI/MasterData/EditProduct";
import TrackingProduction from "../components/TrackingProduction";
import Simulator from "../pages/DB/Simulator";
import ProductionSituation3 from "../pages/DB/ProductionSituation3";
import ProductionOrderPriorities from "../pages/UI/Manufacture/ProductionOrderPriorities";
import ErrorMachines from "../pages/UI/MasterData/ErrorMachine";
import ProductionMonitor from "../pages/UI/Manufacture/ProductionMonitor";

const authProtectedRoutes = [
  // Authentication Page
  {
    path: "/",
    component: () => <Redirect to="/screen" />,
  },
  { path: "/screen", component: Screen },

  // UI
  { path: ["/ui", "/ui/home"], component: UI },
  { path: ["/ui/manufacture/giay-bao-on"], component: UIManufacture },
  { path: ["/ui/manufacture/ke-hoach-san-xuat"], component: UIManufactureKHSX },
  { path: ["/ui/manufacture/lich-su-san-xuat"], component: UIManufactureLSSX },
  { path: ["/ui/manufacture/quan-ly-san-luong"], component: UIManufactureSL },
  { path: ["/ui/manufacture/order"], component: Orders },
  { path: ["/ui/manufacture/lenh-giao-hang"], component: LenhGiaoHang },
  { path: ["/ui/manufacture/fc"], component: FC },
  { path: ["/ui/manufacture/khsx-auto"], component: KeHoachSanXuatTuDong },
  { path: ["/ui/manufacture/load-factor"], component: LoadFactor },
  { path: ["/ui/manufacture/productivity"], component: NangSuat },
  { path: ["/ui/manufacture/danh-sach-don-hang-uu-tien"], component: TaoKeHoachSanXuat },
  { path: ["/ui/quality/PQC"], component: UIQualityPQC },
  { path: ["/ui/quality/errors"], component: UIQualityError },
  { path: ["/ui/quality/OQC"], component: UIQualityOQC },
  { path: ["/ui/quality/IQC"], component: UIQualityIQC },
  { path: ["/ui/equipment/thong-ke-loi"], component: UIEquipment1 },
  { path: ["/ui/equipment/thong-so-may"], component: UIEquipment2 },
  { path: ["/ui/manufacture/production-order-priorities"], component: ProductionOrderPriorities },
  {
    path: ["/ui/equipment/dien-nang-tieu-thu-theo-thang"],
    component: PowerConsumeByMonth,
  },
  {
    path: ["/ui/equipment/dien-nang-tieu-thu-theo-may"],
    component: PowerConsumeByMachine,
  },
  {
    path: ["/ui/equipment/dien-nang-tieu-thu-theo-sp"],
    component: PowerConsumeByProduct,
  },
  { path: ["/ui/equipment/maintenance"], component: Maintenance },
  { path: ["/ui/equipment/detail-maintenance"], component: DetailMaintenance },
  {
    path: ["/ui", "/ui/warehouse/ke-hoach-xuat-kho"],
    component: WarehouseExportPlan,
  },
  { path: ["/ui/warehouse/thanh-pham-giay"], component: ThanhPhamGiay },
  { path: ["/ui/kpi"], component: UIKPI },
  { path: ["/ui/pptist"], component: PPTist },
  { path: ["/ui/so-do-canh-bao"], component: UISoDoCanhBao },
  { path: ["/ui/kich-ban-su-co"], component: UIKichBanSuCo },
  { path: ["/ui/abnormal/kich-ban-bat-thuong"], component: Kichban },
  { path: ["/ui/abnormal/lich-su-bat-thuong"], component: Giamsat },
  { path: ["/ui/voc"], component: VOC },
  { path: ["/ui/master-data/user"], component: Users },
  { path: ["/ui/master-data/tem"], component: Stamp },
  { path: ["/ui/gantt-chart"], component: GanttChart },

  //OI
  { path: ["/tao-tem"], component: InTem },
  { path: ["/manufacture", "/manufacture/:line"], component: Manufacture },
  {
    path: ["/manual-manufacture", "/manual-manufacture/:line"],
    component: P2ManualManufacture,
  },
  { path: ["/quality", "/quality/:line"], component: Quality },
  { path: ["/equipment", "/equipment/:line"], component: Equipment },
  { path: ["/warehouse", "/warehouse/:line"], component: Warehouse },

  //Master Data
  { path: ["/ui/master-data/product"], component: Product },
  { path: ["/ui/master-data/edit-product/:id"], component: EditProduct },
  { path: ["/ui/master-data/add-product"], component: EditProduct },
  { path: ["/ui/master-data/material"], component: Material },
  { path: ["/ui/master-data/bom"], component: Bom },
  {
    path: ["/ui/master-data/production-journey"],
    component: ProductionJourney,
  },
  { path: ["/ui/master-data/customer"], component: Customer },
  { path: ["/ui/master-data/product-order"], component: ProductOrder },
  { path: ["/ui/master-data/template"], component: Template },
  { path: ["/ui/master-data/machine"], component: Machine },
  {
    path: ["/ui/master-data/machine-priority-order"],
    component: MachinePriorityOrder,
  },
  { path: ["/ui/master-data/excel-header"], component: ExcelHeader },
  { path: ["/ui/master-data/machine-shift"], component: MachineShift },
  { path: ["/ui/master-data/shift"], component: Shifts },
  { path: ["/ui/master-data/test-criteria"], component: TestCriteria },
  { path: ["/ui/master-data/error"], component: Errors },
  { path: ["/ui/master-data/error-machine"], component: ErrorMachines },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/change-password", component: ChangePassword },
  // dashboard
  {
    path: ["/dashboard-slide", "/dashboard-slide/:screen"],
    component: DashBoard,
  },
  { path: ["/dashboard/tinh-hinh-san-xuat"], component: ProductionSituation2 },
  {
    path: ["/dashboard/tinh-hinh-san-xuat-1"],
    component: ProductionSituation3,
  },
  { path: ["/dashboard/hieu-suat-thiet-bi"], component: DBHieuSuatThietBi },
  {
    path: ["/dashboard/tinh-hinh-san-xuat-in"],
    component: ProductionSituation1,
  },
  // { path: ["/dashboard/canh-bao-bat-thuong"], component: DBCanhBaoBatThuong },
  { path: ["/iot"], component: Iot },
  { path: ["/tracking"], component: TrackingProduction },
  { path: ["/simulator"], component: Simulator },
  { path: ["/production-monitor"], component: ProductionMonitor },
];

export { authProtectedRoutes, publicRoutes };
