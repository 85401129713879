import axios from "axios";
const prefix = "p2/ui/";
//ProductionOrderPriorities
export async function getLosxPriority(params) {
  const res = await axios.get(prefix + "losx-priorities", {
    params,
  });
  return res;
}
export async function updateLosx(params) {
  const res = await axios.post(prefix + "losx-update", params);
  return res;
}

export async function updateStatusLosx(params) {
  const res = await axios.post(prefix + "losx-update-status", params);
  return res;
}