import { Form, Modal, Row, Col, Button, Divider, Radio, Space, InputNumber, Input, message } from 'antd';
import React, { useState } from 'react';
import './popupStyle.scss'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { savePQCResult } from '../../api/phase2/oi/quality';
const ChecksheetP2 = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { line } = useParams();
    const machine = (new URLSearchParams(window.location.search).get('machine') || '');
    const { text, checksheet = {}, lotId, disabled = false, keyName, onComplete = null } = props;
    const closeModal = () => {
        setOpen(false);
    }
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const result = Form.useWatch('result', { form, preserve: true });
    const [sending, setSending] = useState(false);
    const onFinish = async (values) => {
        setSending(true);
        console.log(values);
        const params = {
            criteria_key: keyName,
            lot_id: lotId,
            line_id: line,
            machine_code: machine,
            ...values
        }
        var res = await savePQCResult(params);
        if (res.success) {
            closeModal();
            onComplete();
        } else {
            form.setFieldValue('result', null);
        }
        setSending(false);
    }
    const hanleClickOk = () => {
        form.setFieldValue('result', 'OK');
        form.submit();
    }
    const hanleClickNG = () => {
        form.setFieldValue('result', 'NG');
        form.submit();
    }
    useEffect(() => {
        form.setFieldsValue({ ...checksheet });
    }, [checksheet])
    const onSubmitFail = ({ values, errorFields, outOfDate }) => {
        form.setFieldValue('result', null);
        messageApi.error('Chưa hoàn thành chỉ tiêu kiểm tra')
    }
    const onFieldsChange = (changedFields, allFields) => {
        if (changedFields[0]?.name) {
            const field = changedFields[0]?.name[0];
            const index = changedFields[0]?.name[1];
            const name = changedFields[0]?.name[2];
            if (name === 'value' && form.getFieldValue(['data', index, 'input'])) {
                const min = parseFloat(form.getFieldValue(['data', index, 'min']));
                const max = parseFloat(form.getFieldValue(['data', index, 'max']));
                form.setFieldValue(['data', index, 'result'], (parseFloat(changedFields[0]?.value) >= min) && (changedFields[0]?.value <= max) ? "OK" : 'NG')
            }
        }
    }

    const onResultChange = (name) => {
        const value = form.getFieldValue(['data', name, 'value']);
        const result = form.getFieldValue(['data', name, 'result']);
        if (value && !isNaN(value)) {
            return result
        }
        return null;
    }
    const isOpenable = () => {
        return true;
        if ((checksheet?.data ?? []).length <= 0 || checksheet?.result === 'OK') {
            return false;
        }
        return true;
    }
    return (
        <React.Fragment>
            {contextHolder}
            <Button type={result ? "primary" : 'default'} danger={result === 'NG'} disabled={!lotId} size='large' className='w-100 text-wrap h-100' onClick={() => isOpenable() && setOpen(true)}>
                {text}
            </Button>
            <Modal
                title={"Kiểm tra " + (keyName)}
                open={open}
                onCancel={closeModal}
                footer={
                    <div className='justify-content-between d-flex'>
                        <strong>Kết luận</strong>
                        <Space>
                            <Button type='primary' style={{ backgroundColor: '#55c32a' }} onClick={hanleClickOk} loading={result === 'OK' && sending}>Duyệt</Button>
                            <Button type='primary' style={{ backgroundColor: '#fb4b50' }} onClick={hanleClickNG} loading={result === 'NG' && sending}>NG</Button>
                        </Space>
                    </div>
                }
                width={700}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onSubmitFail}
                    initialValues={{
                        ...checksheet
                    }}
                    onFieldsChange={onFieldsChange}
                    colon={false}
                >
                    <Form.Item hidden name={'result'}></Form.Item>
                    <Form.List name="data">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <Row gutter={8} className={index === 0 ? '' : 'mt-2'} key={key}>
                                        <Col
                                            span={12}
                                            style={{ paddingInline: 4 }}
                                            className="d-flex justify-content-center flex-wrap align-items-lg-center"
                                        >
                                            <div
                                                className="d-flex justify-content-center align-content-center flex-grow-1 align-items-lg-center p-2"
                                                style={{ backgroundColor: '#EBEBEB', height: '100%', flexWrap: 'wrap' }}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'hang_muc']}
                                                    noStyle
                                                    shouldUpdate
                                                >
                                                    <span>{form.getFieldValue(['data', name, 'hang_muc'])}</span>
                                                </Form.Item>
                                                {form.getFieldValue(['data', name, 'note']) && <Form.Item
                                                    {...restField}
                                                    name={[name, 'note']}
                                                    noStyle
                                                    shouldUpdate
                                                >
                                                    <span>&nbsp;</span>
                                                    <span>{`(${form.getFieldValue(['data', name, 'note'])})`}</span>
                                                </Form.Item>}
                                            </div>
                                        </Col>
                                        {form.getFieldValue(['data', name, 'input']) ? (
                                            <>
                                                <Form.Item {...restField} name={[name, 'tieu_chuan']} hidden noStyle shouldUpdate><Input /></Form.Item>
                                                <Form.Item {...restField} name={[name, 'delta']} hidden noStyle shouldUpdate><Input /></Form.Item>
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'value']}
                                                        rules={[{ required: true }]}
                                                        noStyle
                                                        getValueFromEvent={(e) => {
                                                            const { value: inputValue } = e.target;
                                                            const reg = /^-?\d*(\.\d*)?$/;
                                                            if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
                                                                return inputValue
                                                            }
                                                            else return "dcm"
                                                        }}
                                                    >
                                                        <Input
                                                            type='number'
                                                            className='text-center h-100 d-flex align-items-center justify-content-center'
                                                            inputMode="decimal"
                                                            placeholder="Nhập số"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate
                                                    >
                                                        {({ getFieldValue }) => {
                                                            return (
                                                                <Form.Item name={[name, 'result']} noStyle shouldUpdate>
                                                                    <Button className="w-100 text-center h-100 d-flex align-items-center justify-content-center"
                                                                        style={onResultChange(name) ?
                                                                            (onResultChange(name) === "OK" ?
                                                                                { backgroundColor: '#55c32a', color: 'white' } :
                                                                                { backgroundColor: '#fb4b50', color: 'white' }
                                                                            ) : null}
                                                                    >
                                                                        {onResultChange(name) ?
                                                                            onResultChange(name)
                                                                            :
                                                                            "OK/NG"
                                                                        }
                                                                    </Button>
                                                                </Form.Item>
                                                            )
                                                        }
                                                        }
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        ) : (
                                            <Col span={12}>
                                                <Form.Item name={[name, 'result']} noStyle rules={[{ required: true }]} shouldUpdate>
                                                    <Radio.Group
                                                        style={{ float: 'right', width: '100%', height: '100%' }}
                                                        className="d-flex"
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    >
                                                        <Radio.Button
                                                            value={"OK"}
                                                            className="positive-radio text-center h-100 d-flex align-items-center justify-content-center"
                                                            style={{ flex: 1 }}
                                                        >
                                                            OK
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={'NG'}
                                                            className="negative-radio text-center h-100 d-flex align-items-center justify-content-center"
                                                            style={{ flex: 1 }}
                                                        >
                                                            NG
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                    <Divider></Divider>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default ChecksheetP2;