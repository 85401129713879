import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm, Tabs, InputNumber } from 'antd';
import { api, baseURL, token } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createProduct, deleteMultipleProduct, deleteProduct, exportProduct, getProduct, getProductById, updateProduct } from '../../../api/ui/product';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined, SaveOutlined } from '@ant-design/icons';
import './MasterDataStyle.css'
import { getCustomers, getLines } from '../../../api/ui/main';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { createBom, deleteBom, getBom, saveAllBom, updateBom } from '../../../api/ui/bom';
import { createMachinePriorityOrders, deleteMachinePriorityOrders, getMachinePriorityOrders, saveAllMachinePriorityOrders, updateMachinePriorityOrders } from '../../../api/phase2/master_data/machine-priority-order';
import { getLineList, getMachineList } from '../../../api/phase2/oi/manufacture';
import { createMaterial, deleteMaterial, getMaterial, updateMaterial } from '../../../api/ui/material';
import { createQCCriteria, deleteQCCriteria, getQCCriteria, saveAllQCCriteria, updateQCCriteria } from '../../../api/phase2/master_data/qc-criteria';
import { createMachineProductionMode, deleteMachineProductionMode, getMachineProductionMode, saveAllMachineProductionMode, updateMachineProductionMode } from '../../../api/phase2/master_data/machine-production-mode';
import { createProductionJourney, deleteProductionJourney, getProductionJourney, saveAllProductionJourney, updateProductionJourney } from '../../../api/phase2/master_data/production-journey';
import { createProductCustomer, deleteProductCustomer, getProductCustomer, saveAllProductCustomer, updateProductCustomer } from '../../../api/phase2/master_data/product-customer';


const EditProduct = () => {
    const { id: productId } = useParams();
    const history = useHistory();
    const [dataProduct, setDataProduct] = useState(null);
    const [allMaterial, setAllMaterial] = useState([]);
    const [allLines, setAllLines] = useState([]);
    const [allMachines, setAllMachines] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [productForm] = Form.useForm();

    //Tab data
    const [dataProductCustomer, setDataProductCustomer] = useState([]);
    const [dataMaterial, setDataMaterial] = useState([]);
    const [dataBom, setDataBom] = useState([]);
    const [dataProductJourney, setDataProductJourney] = useState([]);
    const [dataQCCriteria, setDataQCCriteria] = useState([]);
    const [dataMachinePriorityOrder, setDataMachinePriorityOrder] = useState([]);
    const [dataMachineProductionMode, setDataMachineProductionMode] = useState([]);

    const [loading, setLoading] = useState(false);

    const [isEditProduct, setIsEditProduct] = useState(productId ? true : false);
    const [isEditTabComponent, setIsEditTabComponent] = useState(false);
    const [editComponentRecord, setEditComponentRecord] = useState();

    const loadingDataProductCustomer = useRef(false);
    const loadingDataMaterial = useRef(false);;
    const loadingDataBom = useRef(false);;
    const loadingDataProductJourney = useRef(false);;
    const loadingDataQCCriteria = useRef(false);;
    const loadingDataMachinePriorityOrder = useRef(false);;
    const loadingDataMachineProductionMode = useRef(false);;

    const [openModal, setOpenModal] = useState();

    const loadAllMaterial = async () => {
        if (allMaterial.length) {
            return;
        }
        const res = await getMaterial();
        setAllMaterial(res?.data?.data ?? []);
    }
    const loadAllLine = async () => {
        if (allLines.length) {
            return;
        }
        const res = await getLineList({ type: 'sx' });
        setAllLines(res?.data ?? []);
    }
    const loadAllMachine = async () => {
        if (allMachines.length) {
            return;
        }
        const res = await getMachineList();
        setAllMachines(res?.data ?? []);
    }
    const loadAllCustomers = async () => {
        if (allCustomers.length) {
            return;
        }
        const res = await getCustomers();
        setAllCustomers(res?.data ?? []);
    }

    //Load product data
    const loadDataProduct = async (productId) => {
        const res = await getProductById(productId);
        setDataProduct((res.data));
    }

    //Load tab data
    const loadDataProductCustomer = async (productId) => {
        loadingDataProductCustomer.value = true;
        const res = await getProductCustomer(productId);
        setDataProductCustomer((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataProductCustomer.value = false;
    }

    const loadMaterial = async (params = {}) => {
        loadingDataMaterial.value = true;
        const res = await getMaterial(params);
        setDataMaterial((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataMaterial.value = false;
    }

    const loadBom = async (params = {}) => {
        loadingDataBom.value = true;
        const res = await getBom(params);
        setDataBom((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataBom.value = false;
    }

    const loadProductJourney = async (params = {}) => {
        loadingDataProductJourney.value = true;
        const res = await getProductionJourney(params);
        setDataProductJourney((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataProductJourney.value = false
    }

    const loadQCCriteria = async (params = {}) => {
        loadingDataQCCriteria.value = true;
        const res = await getQCCriteria(params);
        setDataQCCriteria((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataQCCriteria.value = false;
    }

    const loadMachinePriorityOrder = async (params = {}) => {
        loadingDataMachinePriorityOrder.value = true;
        const res = await getMachinePriorityOrders(params);
        setDataMachinePriorityOrder((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataMachinePriorityOrder.value = false;
    }

    const loadMachineProductionMode = async (params = {}) => {
        loadingDataMachineProductionMode.value = true;
        const res = await getMachineProductionMode(params);
        setDataMachineProductionMode((res.data?.data || []).map(e => {
            return { ...e, key: e.id }
        }));
        loadingDataMachineProductionMode.value = false;
    }

    const onFinish = async (values) => {
        var res;
        if (isEditProduct) {
            res = await updateProduct(dataProduct?.id, values);
        } else {
            res = await createProduct(values);
        }
        if (res.success) {
            history.push(`/ui/master-data/edit-product/` + res.data.id);
        }

    }

    const goBack = () => {
        history.push(`/ui/master-data/product`);
    }

    const renderTextTab = (text) => {
        return "Tạo mới " + (text ? text?.toLowerCase() : "");
    }

    const [formModal] = Form.useForm();
    const onSubmit = async (values, index) => {
        const params = { product_id: dataProduct?.id, ...values };
        if (!params?.id) {
            var res = await selectedTab?.createFunction(params);
        } else {
            var res = await selectedTab?.updateFunction(params?.id, params);
        }
        console.log(res);

        if (res.success && res.data) {
            selectedTab?.setDataSource(prev => prev.map((item, i) => i === index ? res.data : item));
            setEditingRowIndexes((prev) => prev.filter((rowKey) => rowKey !== index)); // Remove row from editing list
        }
    }

    const onSaveAll = async (data) => {
        const params = { product_id: dataProduct?.id, data };
        console.log(params);
        var res = await selectedTab?.saveAllFunction(params);
        if (res.success) {
            refresh();
        }
    }

    const handleDeleteRecord = async (id, key) => {
        id && selectedTab?.deleteFunction(id);
        console.log(key);
        setEditingRowIndexes((prev) => prev.filter((rowKey) => rowKey !== key).map(e => e > key ? e - 1 : e)); // Remove row from editing list
        const formData = formModal.getFieldValue('data');
        formModal.setFieldValue('data', formData.filter((e, i) => i !== key));
        selectedTab?.setDataSource(prev => prev.filter((e, i) => i !== key));
    }

    const loadCurrentTabData = () => {
        const tab = groupTabs.find(e => e.key === selectedTab?.key);
        tab && tab.getDataFunction({ product_id: dataProduct?.id });
    }
    const groupTabs = [
        {
            key: 'customer',
            title: 'Khách hàng',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã khách hàng', dataIndex: 'customer_id', align: 'center' },
                { title: 'Tên khách hàng', dataIndex: 'customer_name', align: 'center', key: 'customer_id', type: 'select', options: allCustomers.map(e => ({ value: e.id, label: e.name })) },
            ],
            dataSource: dataProductCustomer,
            setDataSource: setDataProductCustomer,
            getDataFunction: loadDataProductCustomer,
            updateFunction: updateProductCustomer,
            createFunction: createProductCustomer,
            deleteFunction: deleteProductCustomer,
            saveAllFunction: saveAllProductCustomer,
            loading: loadingDataProductCustomer.value,
        },
        {
            key: 'bom',
            title: 'BOM',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã NVL', dataIndex: 'material_id', align: 'center' },
                { title: 'Tên NVL', dataIndex: 'material_name', align: 'center', key: 'material_id', type: 'select', options: allMaterial.map(e => ({ value: e.id, label: e.name })) },
                { title: 'Thứ tự ưu tiên', dataIndex: 'priority', align: 'center', key: 'priority' },
                { title: 'Tỷ lệ (%)', dataIndex: 'ratio', align: 'center', key: 'ratio' },
            ],
            dataSource: dataBom,
            setDataSource: setDataBom,
            getDataFunction: loadBom,
            updateFunction: updateBom,
            createFunction: createBom,
            deleteFunction: deleteBom,
            saveAllFunction: saveAllBom,
            loading: loadingDataBom.value,
        },
        {
            key: 'product_journey',
            title: 'Hành trình sản xuất',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã công đoạn', dataIndex: 'line_name', align: 'center', width: 120, key: 'line_id', type: 'select', options: allLines },
                { title: 'Thứ tự sản xuất', dataIndex: 'production_order', align: 'center', width: 120, key: 'production_order' },
                { title: 'Hao phí NVL', dataIndex: 'material_waste', align: 'center', width: 120, key: 'material_waste' },
                { title: 'Hao phí sản xuất công đoạn (%)', dataIndex: 'line_production_waste', width: 220, align: 'center', key: 'line_production_waste' },
                { title: 'Thời gian chuẩn bị đầu ca', dataIndex: 'prep_time', align: 'center', width: 180, key: 'prep_time' },
                { title: 'Hao phí vận chuyển', dataIndex: 'transportation_waste', align: 'center', width: 150, key: 'transportation_waste' },
                { title: 'Lên xuống cuộn', dataIndex: 'roll_change_time', align: 'center', width: 150, key: 'roll_change_time' },
                { title: 'Vào hàng', dataIndex: 'input_quantity', align: 'center', width: 100, key: 'input_quantity' },
                { title: 'Năng suất ấn định giờ', dataIndex: 'hourly_output', width: 170, align: 'center', key: 'hourly_output' },
                { title: 'Nhân sự đứng máy', dataIndex: 'operator_count', width: 150, align: 'center', key: 'operator_count' },
            ],
            dataSource: dataProductJourney,
            setDataSource: setDataProductJourney,
            getDataFunction: loadProductJourney,
            updateFunction: updateProductionJourney,
            createFunction: createProductionJourney,
            deleteFunction: deleteProductionJourney,
            saveAllFunction: saveAllProductionJourney,
            loading: loadingDataProductJourney.value,
        },
        {
            key: 'q_c_criteria',
            title: 'Tiêu chuẩn công đoạn',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã công đoạn', dataIndex: 'line_name', align: 'center', key: 'line_id', type: 'select', options: allLines },
                { title: 'Chỉ tiêu', dataIndex: 'criteria_name', align: 'center', key: 'criteria_name' },
                { title: 'Giá trị', dataIndex: 'criteria_value', align: 'center', key: 'criteria_value' },
            ],
            dataSource: dataQCCriteria,
            setDataSource: setDataQCCriteria,
            getDataFunction: loadQCCriteria,
            updateFunction: updateQCCriteria,
            createFunction: createQCCriteria,
            deleteFunction: deleteQCCriteria,
            saveAllFunction: saveAllQCCriteria,
            loading: loadingDataQCCriteria.value,
        },
        {
            key: 'machine_priority_order',
            title: 'Thứ tự ưu tiên máy',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã công đoạn', dataIndex: 'line_name', align: 'center', key: 'line_id', type: 'select', options: allLines },
                { title: 'Thứ tự ưu tiên', dataIndex: 'priority', align: 'center', key: 'priority' },
                { title: 'Mã máy', dataIndex: 'machine_id', align: 'center', key: 'machine_id', type: 'select', options: allMachines.map(e => ({ value: e.code, label: e.code })) },
            ],
            dataSource: dataMachinePriorityOrder,
            setDataSource: setDataMachinePriorityOrder,
            getDataFunction: loadMachinePriorityOrder,
            updateFunction: updateMachinePriorityOrders,
            createFunction: createMachinePriorityOrders,
            deleteFunction: deleteMachinePriorityOrders,
            saveAllFunction: saveAllMachinePriorityOrders,
            loading: loadingDataMachinePriorityOrder.value,
        },
        {
            key: 'machine_production_mode',
            title: 'Chế độ chạy máy',
            columns: [
                { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => index + 1 },
                { title: 'Mã máy', dataIndex: 'machine_id', align: 'center', key: 'machine_id', type: 'select', options: allMachines.map(e => ({ value: e.code, label: e.code })) },
                { title: 'Thông số', dataIndex: 'parameter_name', align: 'center', key: 'parameter_name' },
                { title: 'Giá trị tiêu chuẩn', dataIndex: 'standard_value', align: 'center', key: 'standard_value' },
            ],
            dataSource: dataMachineProductionMode,
            setDataSource: setDataMachineProductionMode,
            getDataFunction: loadMachineProductionMode,
            updateFunction: updateMachineProductionMode,
            createFunction: createMachineProductionMode,
            deleteFunction: deleteMachineProductionMode,
            saveAllFunction: saveAllMachineProductionMode,
            loading: loadingDataMachineProductionMode.value,
        },
    ];

    const [editingRowIndexes, setEditingRowIndexes] = useState([]);

    const isEditing = (index) => editingRowIndexes.includes(index);

    const findData = (key) => {
        switch (key) {
            case 'customer':
                return dataProductCustomer;
                break;
            case 'bom':
                return dataBom;
                break;
            case 'product_journey':
                return dataProductJourney;
                break;
            case 'q_c_criteria':
                return dataQCCriteria;
                break;
            case 'machine_priority_order':
                return dataMachinePriorityOrder;
                break;
            case 'machine_production_mode':
                return dataMachineProductionMode;
                break;
            default:
                return [];
                break;
        }
    }

    const create = () => {
        const data = [{}, ...findData(selectedTab.key)];
        selectedTab?.setDataSource(data);
        const formData = [{}, ...formModal.getFieldValue('data')];
        formModal.setFieldsValue({ data: formData });
        setEditingRowIndexes(prev => [0, ...prev.map(e => e + 1)]);
    }

    const edit = (key) => {
        setEditingRowIndexes((prev) => [...prev, key]); // Add row to editing list
        const formData = formModal.getFieldValue('data');
        const newArray = [...formData];
        newArray[key] = findData(selectedTab.key)[key] ?? {};
        console.log(newArray);

        formModal.setFieldValue('data', newArray);
    };

    const cancel = (key) => {
        console.log(key);
        setEditingRowIndexes((prev) => prev.filter((rowKey) => rowKey !== key).map(e => e > key ? e - 1 : e)); // Remove row from editing list
        const formData = formModal.getFieldValue('data');
        formModal.setFieldValue('data', formData.filter((e, i) => i !== key));
        selectedTab?.setDataSource(prev => prev.filter((e, i) => i !== key || e.id));
    };

    const save = async (key) => {
        try {
            const { data } = await formModal.validateFields();
            const row = data[key];
            if (row) {
                onSubmit(row, key);
            }
            // cancel(key);
        } catch (err) {
            console.log('Validation Failed:', err);
        }
    };

    const saveAll = async (key) => {
        try {
            const { data } = await formModal.validateFields();
            onSaveAll(data);
            // cancel(key);
        } catch (err) {
            console.log('Validation Failed:', err);
        }
    };

    const refresh = async () => {
        formModal.resetFields();
        setEditingRowIndexes([]);
        setSelectedTab(prev=>({...prev, loading: true}));
        await selectedTab?.getDataFunction({ product_id: dataProduct?.id });
        setSelectedTab(prev=>({...prev, loading: false}));
    }

    const items = groupTabs.map(e => {
        const columns = (e?.columns ?? []).map(e => {
            if (!e?.key) {
                return e;
            }
            var formItem = null;
            var options = [];
            switch (e.key) {
                case 'line_id':
                    options = allLines;
                    break;
                case 'machine_id':
                    options = allMachines.map(e => ({ value: e.code, label: e.code }));
                    break;
                case 'customer_id':
                    options = allCustomers.map(e => ({ value: e.id, label: e.name }));
                    break;
                case 'material_id':
                    options = allMaterial.map(e => ({ value: e.id, label: e.name }));
                    break;
                default:
                    break;
            }
            switch (e.type) {
                case 'number':
                    formItem = <InputNumber placeholder={"Vui lòng nhập " + e?.title} />;
                    break;
                case 'select':
                    formItem = <Select options={options} showSearch optionFilterProp='label' placeholder="Vui lòng chọn" />;
                    break;
                default:
                    //default is normal input
                    formItem = <Input placeholder={"Vui lòng nhập " + e?.title} />;
                    break;
            }
            return {
                ...e, render: (text, record, index) =>
                    isEditing(index) ? (
                        <Form.Item name={['data', index, e.key]} className='mb-0' label={null} rules={[{ required: e.require }]} shouldUpdate>
                            {formItem}
                        </Form.Item>
                    ) : (
                        text
                    ),
            }
        });
        if (columns.length > 0) {
            columns.push({
                title: 'Thao tác', dataIndex: 'action', align: 'center', fixed: 'right', width: 100, render: (_, record, index) => (
                    isEditing(index) ?
                        <Space wrap>
                            <Form.Item name={['data', index, 'id']} hidden>
                                <Input />
                            </Form.Item>
                            <CheckOutlined className='check-btn' onClick={() => save(index)} />
                            <CloseOutlined style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => cancel(index)} />
                        </Space>
                        :
                        <Space wrap>
                            <EditOutlined className='edit-btn' onClick={() => edit(index)} />
                            <Popconfirm title="Xoá bản ghi" description={"Bạn có chắc muốn xoá?"} onConfirm={() => handleDeleteRecord(record?.id, index)} okText="Có" cancelText="Không">
                                <DeleteOutlined className='delete-btn' />
                            </Popconfirm>
                        </Space>
                )
            });
        }
        return {
            key: e.key,
            label: e.title,
            children:
                <Form form={formModal} component={false} initialValues={{ data: [] }}>
                    <Table size='small' bordered
                        scroll={{ x: '100%', y: '43vh' }}
                        pagination={true}
                        columns={columns}
                        loading={e.loading}
                        dataSource={e?.dataSource ?? []} />
                </Form>,
        }
    });

    const [selectedTab, setSelectedTab] = useState();
    const onChangeTab = (key) => {
        setEditingRowIndexes([]);
        const tab = groupTabs.find(e => e.key === key);
        if (tab) {
            setSelectedTab(tab);
            tab?.setDataSource(prev => prev.filter(e => e.id) );
        }
    }

    useEffect(() => {
        onChangeTab(selectedTab?.key ?? 'customer');
        loadDataProduct(productId);
        loadAllMaterial();
        loadAllLine();
        loadAllMachine();
        loadAllCustomers();
    }, []);

    useEffect(() => {
        productForm.setFieldsValue(dataProduct);
        loadTabTable({ product_id: dataProduct?.id });
    }, [dataProduct]);

    const loadTabTable = async (params = {}) => {
        if (!dataProduct) {
            return;
        }
        groupTabs.forEach(e => {
            e?.getDataFunction(params)
        })
    }

    console.log(selectedTab);
    

    return <>
        <Card
            style={{ minHeight: '90vh' }}
            title={
                <Space>
                    <Button icon={<ArrowLeftOutlined />} onClick={goBack} loading={loading}>Trở lại danh sách</Button>
                    <div>Chỉnh sửa sản phẩm {dataProduct?.id}</div>
                </Space>
            }
            extra={
                <Space>
                    <Button type="primary" onClick={() => productForm.submit()} icon={<SaveOutlined />} loading={loading}>Lưu thông tin</Button>
                </Space>
            }
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form
                    layout="vertical"
                    form={productForm}
                    onFinish={onFinish}>
                    <Row gutter={[5, 5]}>
                        <Col span={8}>
                            <Form.Item name={'id'} className='mb-3' label={'Mã sản phẩm'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" disabled={isEditProduct} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'name'} className='mb-3' label={'Tên sản phẩm'} rules={[{ required: true }]}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'ver'} className='mb-3' label={'Ver'}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'his'} className='mb-3' label={'His'}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'weight'} className='mb-3' label={'Khối lượng sản phẩm (gam/pcs)'}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'paper_norm'} className='mb-3' label={'Định mức giấy sử dụng (gam/pcs)'}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'so_bat'} className='mb-3' label={'Số bát'}>
                                <Input placeholder="Vui lòng nhập" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider style={{ margin: '5px 0' }}>
                    <small>Thông tin về sản phẩm {dataProduct?.id}</small>
                </Divider>
                <Tabs activeKey={selectedTab?.key} onChange={onChangeTab} type="card" items={items} tabBarExtraContent={
                    <Space>
                        <Button type="primary" icon={<SaveOutlined />} disabled={!productId} onClick={() => saveAll()}>{'Lưu'}</Button>
                        <Button type="primary" icon={<RedoOutlined />} disabled={!productId} loading={selectedTab?.loading ?? false} onClick={() => refresh()}>{'Tải lại'}</Button>
                        <Button type="primary" icon={<PlusOutlined />} disabled={!productId} loading={loading} onClick={() => create()}>{renderTextTab(selectedTab?.title)}</Button>
                    </Space>
                } />
            </div>
        </Card>
    </>
}

export default EditProduct;
