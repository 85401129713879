import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Layout,
  Divider,
  Button,
  Form,
  Input,
  Select,
  Upload,
  message,
  Checkbox,
  Space,
  Modal,
  Spin,
  Tree,
  Radio,
  Empty,
  Popconfirm,
  Tag,
} from "antd";
import { baseURL } from "../../../config";
import React, { useState, useEffect } from "react";
import {
  getCustomers,
  getDataFilterUI,
  getProducts,
} from "../../../api/ui/main";
import {
  deleteRecordProductPlan,
  exportKHSX,
  getLine,
  getListProductPlan,
  getMachine,
  storeProductPlan,
  updateProductPlan,
  updateStatusProductionPlan,
  storeProductionPlan,
  approveProductionPlanData,
} from "../../../api";
import dayjs from "dayjs";
import { getTreeSelect } from "../../../api/phase2/ui/main";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { manufatureQuicker } from "../../../routes/quickerCollection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { uploadProductionPlan } from "../../../api/phase2/ui/production";
import { render } from "@testing-library/react";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const KeHoachSanXuat = () => {
  document.title = "Kế hoạch sản xuất";
  const [listLines, setListLines] = useState([]);
  const [listNameProducts, setListNameProducts] = useState([]);
  const [listLoSX, setListLoSX] = useState([]);
  const [listCustomers, setListCustomers] = useState([]);
  const [listCheck, setListCheck] = useState([]);
  const [openMdlEdit, setOpenMdlEdit] = useState(false);
  const [titleMdlEdit, setTitleMdlEdit] = useState("Cập nhật");
  const [isUpdate, setIsUpdate] = useState(true);
  const [form] = Form.useForm();
  const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
  const [dateType, setDateType] = useState("date");
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productionPlanModalOpen, setProductionPlanModalOpen] = useState(false);
  const [productionPlanData, setProductionPlanData] = useState([]);
  const [listProductionPlan, setListProductionPlan] = useState([]);
  const colProductionPlan = [
    {
      title: "Công đoạn SX",
      dataIndex: "cong_doan_sx",
      key: "cong_doan_sx",
      width: "180px",
      // Nếu muốn nhóm hiển thị tên công đoạn chỉ trên hàng nhóm, bạn có thể ẩn cột này ở cấp con
      render: (text, record) =>
        record.children ? <strong>{text}</strong> : text,
    },
    { title: "Ca SX", dataIndex: "ca_sx", key: "ca_sx", width: "50px" },
    { title: "Ngày SX", dataIndex: "ngay_sx", key: "ngay_sx", width: "90px" },
    {
      title: "Máy SX",
      dataIndex: "machine_id",
      key: "machine_id",
      width: "100px",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "product_id",
      key: "product_id",
      width: "100px",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "product_name",
      key: "product_name",
      width: "100px",
    },
    {
      title: "SL giao SX",
      dataIndex: "sl_giao_sx",
      key: "sl_giao_sx",
      width: "80px",
    },
    {
      title: "TG bắt đầu",
      dataIndex: "thoi_gian_bat_dau",
      key: "thoi_gian_bat_dau",
    },
    {
      title: "TG kết thúc",
      dataIndex: "thoi_gian_ket_thuc",
      key: "thoi_gian_ket_thuc",
    },
    { title: "UPH", dataIndex: "UPH", key: "UPH", width: "100px" },
  ];

  const statusPlanOptions = [
    { label: "Đang chờ", value: 0 },
    { label: 'Đang thực hiện', value: 1 },
    { label: 'Hoàn thành', value: 2 },
    { label: 'Đã dừng', value: 3 },
    { label: "Hủy đơn", value: 4 },
  ];

  const onChangeChecbox = (e) => {
    if (e.target.checked) {
      if (!listCheck.includes(e.target.value)) {
        setListCheck((oldArray) => [...oldArray, e.target.value]);
      }
    } else {
      if (listCheck.includes(e.target.value)) {
        setListCheck((oldArray) =>
          oldArray.filter((datainput) => datainput !== e.target.value)
        );
      }
    }
  };

  const renderStatus = (value) => {
    switch (value) {
      case 1:
        return <Tag color="#1677ff">Đang thực hiện</Tag>;
      case 2:
        return <Tag color="#3a851f">Hoàn thành</Tag>;
      case 3:
        return <Tag color="#ff4d4f">Đã dừng</Tag>;
      case 4:
        return <Tag color="#59554ee0">Đã hủy</Tag>;
      default:
        return <Tag color="#faad14">Đang chờ</Tag>;
    }
  };

  const col_detailTable = [
    {
      title: "Chọn",
      dataIndex: "name1",
      key: "name1",
      render: (value, item, index) => (
        <Checkbox value={item.id} onChange={onChangeChecbox}></Checkbox>
      ),
      align: "center",
      fixed: "left",
      width: "70px",
    },
    {
      title: "Thứ tự",
      dataIndex: "thu_tu_uu_tien",
      key: "thu_tu_uu_tien",
      align: "center",
      fixed: "left",
      width: "60px",
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "thoi_gian_bat_dau",
      key: "thoi_gian_bat_dau",
      align: "left",
      width: "140px",
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "thoi_gian_ket_thuc",
      key: "thoi_gian_ket_thuc",
      align: "left",
      width: "140px",
    },
    {
      title: "Công đoạn",
      dataIndex: "cong_doan_sx",
      key: "cong_doan_sx",
      align: "left",
      width: "120px",
    },
    {
      title: "Máy sx",
      dataIndex: "machine_id",
      key: "machine_id",
      align: "left",
      width: "100px",
    },
    {
      title: "Mã SP",
      dataIndex: "product_id",
      key: "product_id",
      align: "left",
      width: "80px",
    },
    {
      title: "Tên SP",
      dataIndex: "ten_san_pham",
      key: "ten_san_pham",
      align: "left",
      width: "120px",
    },
    {
      title: "Khách hàng",
      dataIndex: "khach_hang",
      key: "khach_hang",
      align: "left",
      width: "150px",
    },
    {
      title: "Ca sx",
      dataIndex: "ca_sx",
      key: "ca_sx",
      align: "left",
      width: "50px",
    },
    {
      title: "Lô SX",
      dataIndex: "lo_sx",
      key: "lo_sx",
      align: "left",
      width: "80px",
    },
    {
      title: "Số bát",
      dataIndex: "so_bat",
      key: "so_bat",
      align: "center",
      width: "100px",
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "ngay_giao_hang",
      key: "ngay_giao_hang",
      align: "center",
      width: "120px",
    },
    {
      title: "Số lượng tổng ĐH",
      dataIndex: "sl_tong_don_hang",
      key: "sl_tong_don_hang",
      align: "center",
      width: "130px",
    },
    {
      title: "SL nguyên liệu đầu vào (tờ)",
      dataIndex: "sl_nvl",
      key: "sl_nvl",
      align: "center",
      width: "200px",
    },
    {
      title: "Kế hoạch SL thành phẩm (tờ)",
      dataIndex: "sl_thanh_pham",
      key: "sl_thanh_pham",
      align: "center",
      width: "200px",
    },
    {
      title: "Kế hoạch SL TP (mảnh)",
      dataIndex: "sl_ke_hoach_manh",
      key: "sl_ke_hoach_manh",
      align: "center",
      width: "200px",
      render: (value, record) =>
        record.sl_thanh_pham
          ? record.so_bat * record.sl_thanh_pham
          : record.sl_giao_sx,
    },
    {
      title: "Thực tế SL TP (mảnh)",
      dataIndex: "sl_dau_ra_ok",
      key: "sl_dau_ra_ok",
      align: "center",
      width: "150px",
    },
    {
      title: "UPH",
      dataIndex: "UPH",
      key: "UPH",
      align: "center",
      width: "100px",
    },
    {
      title: "Tổng thời gian thực hiện",
      dataIndex: "tong_tg_thuc_hien",
      key: "tong_tg_thuc_hien",
      align: "center",
      width: "200px",
    },
    {
      title: "Nhân lực",
      dataIndex: "nhan_luc",
      key: "nhan_luc",
      align: "center",
      width: "100px",
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "100px",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: "100px",
    },
    {
      title: "Kế hoạch",
      dataIndex: "plan",
      key: "plan",
      align: "center",
      width: "100px",
    },
    {
      title: "Trạng thái",
      dataIndex: "status_plan",
      key: "status_plan",
      align: "center",
      fixed: "right",
      width: "110px",
      render: (value) => renderStatus(value),
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      width: 95,
      render: (_, record) => (
        <Space wrap>
          {record?.status_plan === 1 && (
            <Popconfirm
              title="Xác nhận dừng?"
              onConfirm={() => updateStatusPlan(record, 3)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                className="edit-btn"
                type="link"
                size="small"
                title="Dừng"
                icon={<PauseOutlined />}
              />
            </Popconfirm>
          )}
          {record?.status_plan === 3 && (
            <Popconfirm
              title="Xác nhận tiếp tục sản xuất?"
              onConfirm={() => updateStatusPlan(record, 1)}
              okText="Có"
              cancelText="Không"
            >
              <Button
                className="edit-btn"
                type="link"
                size="small"
                title="Bắt đầu"
                icon={<CaretRightOutlined />}
              />
            </Popconfirm>
          )}
          <Button
            className="edit-btn"
            type="link"
            size="small"
            disabled={record?.status_plan !== 0}
            onClick={() => editProductionPlan(record)}
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
  ];
  useEffect(() => {
    (async () => {
      const res1 = await getTreeSelect();
      setListLines(res1.data);
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    })();
  }, []);

  function btn_click() {
    loadListTable(params);
  }

  useEffect(() => {
    (async () => {
      var res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
        setListLoSX(
          Object.values(res.data.lo_sx).map((e) => {
            return { label: e, value: e };
          })
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    const res = await getListProductPlan(params);
    setData(res);
    setLoading(false);
  };
  const loadMachine = async () => {
    const res = await getMachine();
    const records = res || [];
    setEquipmentOptions(
      records.map(({ id, name, code }) => ({ label: code, value: code }))
    );
  };
  const loadProcess = async () => {
    const res = await getLine();
    const records = res || [];
    setProcessOptions(
      records.map(({ id, name }) => ({ label: name, value: id }))
    );
  };
  const loadProduct = async () => {
    const res = await getProducts();
    const records = res?.data || [];
    setProductOptions(
      records.map(({ id, name }) => ({ label: name, value: id }))
    );
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
      loadMachine();
      loadProcess();
      loadProduct();
    })();
  }, []);

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Upload file thành công",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Upload file lỗi",
    });
  };

  function convertToSlug(str) {
    if (!str) return null;
    return String(str)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "-");
  }

  const onFinish = async (values) => {
    if (values?.line_id) {
      const line = processOptions.find(({ value }) => value === values.line_id);
      if (line?.label) values.cong_doan_sx = convertToSlug(line.label);
    }

    if (values?.thoi_gian_bat_dau && dayjs(values?.thoi_gian_bat_dau).isValid())
      values.thoi_gian_bat_dau = dayjs(values?.thoi_gian_bat_dau).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    if (values.id) {
      const res = await updateProductPlan(values);
    } else {
      const res = await storeProductPlan({
        ...values,
        status_plan: 0, // 👈 Default pending
      });
      if (!res.success && res.message) message.error(res.message);
    }
    setOpenMdlEdit(false);
    loadListTable(params);
  };

  const deleteRecord = async () => {
    if (listCheck.length > 0) {
      // Check status_plan = 0 (Đang chờ) -> chỉ xóa đơn đang chờ
      const result = data.filter((record) => listCheck.includes(record.id));
      const check = result.filter((record) => record?.status_plan === 0);
      console.log(listCheck, check);
      if (check.length > 0) {
        const res = await deleteRecordProductPlan(listCheck);
        setListCheck([]);
        loadListTable(params);
      } else
        message.info(
          `Đơn ${result
            .map((item) => `${item?.lo_sx}(${item?.id})`)
            .join(", ")} đã thực hiện, vui lòng không xóa!`
        );
    } else {
      message.info("Chưa chọn bản ghi cần xóa");
    }
  };

  const updateStatusPlan = async (record, status) => {
    if ([1, 3].includes(record?.status_plan)) {
      const response = await updateStatusProductionPlan(record?.id, {
        status_plan: status,
      });
      if (response) {
        loadListTable(params);
      }
    } else message.error("Thao tác thất bại!");
  };

  const editRecord = () => {
    setTitleMdlEdit("Cập nhật");
    setIsUpdate(true);
    if (listCheck.length > 1) {
      message.info("Chỉ chọn 1 bản ghi để chỉnh sửa");
    } else if (listCheck.length == 0) {
      message.info("Chưa chọn bản ghi cần chỉnh sửa");
    } else {
      const result = data.find((record) => record.id === listCheck[0]);
      form.setFieldsValue({
        id: listCheck[0],
        thoi_gian_bat_dau: result.thoi_gian_bat_dau,
        product_id: result.product_id,
      });
      setOpenMdlEdit(true);
    }
  };
  const editProductionPlan = (record) => {
    setTitleMdlEdit("Cập nhật");
    setIsUpdate(true);
    if (record?.id) {
      form.setFieldsValue({
        ...record,
        id: record.id,
        thoi_gian_bat_dau: dayjs(record?.thoi_gian_bat_dau).isValid()
          ? dayjs(record?.thoi_gian_bat_dau)
          : null,
        product_id: record.product_id,
        machine_id: record.machine_id,
        status_plan: record?.status_plan || 0,
      });
      setOpenMdlEdit(true);
    } else message.error("Không tìm thấy dữ liệu bản ghi!");
  };
  const insertRecord = () => {
    setTitleMdlEdit("Thêm mới");
    setIsUpdate(false);
    form.resetFields();
    setOpenMdlEdit(true);
  };
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const exportRecord = async () => {
    setLoadingExport(true);
    const res = await exportKHSX(params);
    if (res.success) {
      window.location.href = baseURL + res.data;
    }
    setLoadingExport(false);
  };
  const createProductionPlan = async () => {
    try {
      const res = await storeProductionPlan();
      if (res.success) {
        // Nhóm dữ liệu theo công đoạn sản xuất
        setListProductionPlan(res.data);
        const groupedData = res.data.reduce((groups, record, index) => {
          const groupKey = record.cong_doan_sx;
          if (!groups[groupKey]) {
            groups[groupKey] = {
              key: groupKey, // Key cho group
              cong_doan_sx: groupKey,
              children: [], // Mảng chứa các bản ghi con của nhóm
            };
          }
          // Ở đây, bạn có thể gán thêm một key duy nhất cho mỗi bản ghi con,
          // ví dụ kết hợp product_order_id với index của reduce (hoặc một trường duy nhất khác)
          record._uniqueKey = `${groupKey}-${index}-${record.product_order_id}`;
          groups[groupKey].children.push(record);
          return groups;
        }, {});

        // Chuyển đối tượng thành mảng để truyền vào Table
        const tableData = Object.values(groupedData);

        setProductionPlanData(tableData);
        setProductionPlanModalOpen(true);
      } else {
        message.error("Tạo kế hoạch sản xuất thất bại!");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra khi tạo kế hoạch sản xuất!");
    }
  };

  const approveProductionPlan = async () => {
    try {
      // Gửi dữ liệu kế hoạch đã nhóm (productionPlanData) về backend
      const res = await approveProductionPlanData({
        productionPlanData: listProductionPlan,
      });
      if (res.success) {
        message.success("Duyệt kế hoạch thành công!");
        setProductionPlanModalOpen(false);
        // Optionally: Cập nhật lại danh sách, hoặc làm các xử lý khác
      } else {
        message.error("Duyệt kế hoạch thất bại!");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra khi duyệt kế hoạch!");
    }
  };
  const onCheck = (checkedKeys, info) => {
    const selectedLineKeys = [];
    const selectedMachineKeys = [];
    if (info.node.type === "factory") {
      if (info.checked) {
        info.node.children.map((e) => {
          selectedLineKeys.push(e.key?.toString());
        });
      }
    } else {
      info.checkedNodes.map((e) => {
        switch (e.type) {
          case "line":
            selectedLineKeys.push(e.key?.toString());
            break;
          case "machine":
            selectedMachineKeys.push(e.key?.toString());
            break;
          default:
            break;
        }
      });
    }
    setParams({
      ...params,
      line_id: [...new Set(selectedLineKeys)],
      machine_code: selectedMachineKeys,
    });
  };
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
        break;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
        break;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
        break;
      default:
        return dayjs(value).format("DD/MM/YYYY");
        break;
    }
  };
  const renderQuicker = () => {
    console.log(window.location.pathname);
    const className = (path) => {
      if (window.location.pathname.indexOf(path) > -1) {
        return "active";
      } else {
        return "inactive";
      }
    };
    return (
      <ul>
        {manufatureQuicker.map((e) => {
          return (
            <li>
              <Link to={e.path} className={"quicker " + className(e.path)}>
                {e.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  useEffect(() => {
    onChangeDate("start_date", params.date[0], dateType);
    onChangeDate("end_date", params.date[1], dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("isoWeek"),
              dayjs(params.date[1]).endOf("isoWeek"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("isoWeek"),
              dayjs(value).endOf("isoWeek"),
            ],
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("month"),
              dayjs(params.date[1]).endOf("month"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("month"),
              dayjs(value).endOf("month"),
            ],
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("year"),
              dayjs(params.date[1]).endOf("year"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("year"),
              dayjs(value).endOf("year"),
            ],
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] });
        } else {
          setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
        }
        break;
    }
  };
  return (
    <>
      {contextHolder}
      <Row style={{ padding: "8px" }} gutter={[8, 8]} className="custom-row">
        <Col span={4} className="custom-col">
          <Card
            bodyStyle={{ padding: 0 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Form style={{ margin: "0 8px" }} layout="vertical">
              <Divider>Công đoạn</Divider>
              <Form.Item className="mb-3">
                {listLines.length ? (
                  <Tree
                    defaultExpandedKeys={[2]}
                    style={{ maxHeight: 250, overflowY: "auto" }}
                    checkable
                    selectable={false}
                    onCheck={onCheck}
                    treeData={listLines}
                  />
                ) : (
                  <LoadingOutlined />
                )}
              </Form.Item>
              <Divider>Quicker</Divider>
              {renderQuicker()}
              <Divider>Thời gian truy vấn</Divider>
              <Radio.Group
                options={[
                  {
                    value: "date",
                    label: "Ngày",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "week",
                    label: "Tuần",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "month",
                    label: "Tháng",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "year",
                    label: "Năm",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                ]}
                className="d-flex justify-content-center mb-2 w-100"
                onChange={(e) => setDateType(e.target.value)}
                value={dateType}
                optionType="button"
                buttonStyle="solid"
              />
              <Space direction="vertical" style={{ width: "100%" }}>
                <DatePicker
                  allowClear={false}
                  placeholder="Bắt đầu"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("start_date", value, dateType)
                  }
                  value={params.date[0]}
                  format={customDateFormat}
                  picker={dateType}
                />
                <DatePicker
                  allowClear={false}
                  placeholder="Kết thúc"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("end_date", value, dateType)
                  }
                  value={params.date[1]}
                  format={customDateFormat}
                  picker={dateType}
                />
              </Space>
              <Divider>Điều kiện truy vấn</Divider>
              <Form.Item label="Khách hàng" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn khách hàng"
                  onChange={(value) =>
                    setParams({ ...params, khach_hang: value })
                  }
                  optionFilterProp="label"
                  popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                  options={listCustomers}
                />
              </Form.Item>
              <Form.Item label="Tên sản phẩm" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => {
                    setParams({ ...params, ten_sp: value });
                  }}
                  placeholder="Nhập tên sản phẩm"
                  optionFilterProp="label"
                  options={listNameProducts}
                />
              </Form.Item>
              <Form.Item label="Lô Sản xuất" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  placeholder="Nhập lô sản xuất"
                  optionFilterProp="label"
                  onChange={(value) => setParams({ ...params, lo_sx: value })}
                  options={listLoSX}
                />
              </Form.Item>
              <Form.Item label="Trạng thái đơn" className="mb-3">
                <Select
                  onChange={(value) => {
                    setParams({ ...params, status_plan: value });
                  }}
                  placeholder="Trạng thái đơn"
                  options={[
                    { label: "Tất cả", value: null },
                    { label: "Đang chờ", value: 0 },
                    { label: "Đang thực hiện", value: 1 },
                    { label: "Hoàn thành", value: 2 },
                    { label: "Đã dừng", value: 3 },
                    { label: "Đã hủy", value: 4 },
                  ]}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={20} className="custom-col-table">
          <Card
            style={{ height: "100%" }}
            title="Kế hoạch sản xuất"
            extra={
              <Space>
                <Upload
                  showUploadList={false}
                  customRequest={async ({ file, onSuccess, onError }) => {
                    setLoadingUpload(true);
                    const formData = new FormData();
                    formData.append("file", file);
                    const res = await uploadProductionPlan(formData);
                    if (res.success) {
                      loadListTable(params);
                      setLoadingUpload(false);
                    } else {
                      loadListTable(params);
                      setLoadingUpload(false);
                    }
                  }}
                >
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    loading={loadingUpload}
                  >
                    Upload Excel
                  </Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={exportRecord}
                  loading={loadingExport}
                >
                  Export Excel
                </Button>
                {/* <Button type="primary" onClick={deleteRecord}>
                  Delete
                </Button> */}
                <Popconfirm
                  title="Delete"
                  description={"Bạn có chắc muốn xoá?"}
                  onConfirm={deleteRecord}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button type="primary">Delete</Button>
                </Popconfirm>
                {/* <Button type="primary" onClick={editRecord}>
                  Edit
                </Button> */}
                <Button type="primary" onClick={insertRecord}>
                  Insert
                </Button>
                <Button type="primary" onClick={createProductionPlan}>
                  Tạo kế hoạch
                </Button>
              </Space>
            }
          >
            <Spin spinning={loading}>
              <Table
                size="small"
                bordered
                locale={{
                  emptyText: (
                    <Empty
                      style={{ height: "100vh" }}
                      image={null}
                      description={null}
                    />
                  ),
                }}
                className="table-khsx"
                loading={loading}
                pagination={false}
                scroll={{
                  x: "2190px",
                  y: CalculateTableHeight("table-khsx"),
                }}
                columns={col_detailTable}
                dataSource={data}
              />
            </Spin>
          </Card>
        </Col>
      </Row>
      <Modal
        title={titleMdlEdit}
        open={openMdlEdit}
        onCancel={() => setOpenMdlEdit(false)}
        footer={null}
        width={800}
      >
        <Form
          style={{ margin: "0 15px" }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]} className="ui-modal-content-scroll">
            <Col span={12} className="d-none">
              <Form.Item name="id" className="mb-3 d-none">
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Thời gian bắt đầu"
                name="thoi_gian_bat_dau"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder="Thời gian bắt đầu"
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY HH:mm:ss"
                  showTime
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Máy"
                name="machine_id"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Select
                  options={equipmentOptions}
                  placeholder="Chọn máy"
                  optionFilterProp="label"
                  optionLabelProp="label"
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mã sản phẩm"
                name="product_id"
                className="mb-3"
                rules={[{ required: true }]}
              >
                <Select
                  options={productOptions}
                  placeholder="Chọn sản phẩm"
                  optionFilterProp="label"
                  optionLabelProp="label"
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            {!isUpdate && (
              <Col span={12}>
                <Form.Item label="Số lượng giao sản xuất" name="sl_giao_sx">
                  <Input type="number" placeholder="Số lượng giao sản xuất" />
                </Form.Item>
              </Col>
            )}
            {isUpdate && (
              <Col span={12}>
                <Form.Item
                  label="Trạng thái đơn"
                  name="status_plan"
                  className="mb-3"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Trạng thái đơn"
                    options={statusPlanOptions}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item className="mb-0" style={{ marginTop: "5px" }}>
            <Button type="primary" htmlType="submit">
              Lưu lại
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Chi tiết kế hoạch sản xuất"
        open={productionPlanModalOpen}
        onCancel={() => setProductionPlanModalOpen(false)}
        footer={[
          <Button key="approve" type="primary" onClick={approveProductionPlan}>
            Duyệt
          </Button>,
          <Button key="close" onClick={() => setProductionPlanModalOpen(false)}>
            Đóng
          </Button>,
        ]}
        width={1200}
      >
        <Table
          bordered={true}
          columns={colProductionPlan}
          dataSource={productionPlanData}
          size="small"
          pagination={false}
          expandable={{ defaultExpandAllRows: true }}
          scroll={{ x: "max-content", y: 400 }}
          rowKey={(record) =>
            record.children ? record.key : record._uniqueKey
          }
        />
      </Modal>
    </>
  );
};

export default KeHoachSanXuat;
