import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getErrorMachines(params) {
    const res = await axios.get(prefix+'error-machines', { params });
    return res;
}
export async function createErrorMachines(params) {
    const res = await axios.post(prefix+'error-machines', params);
    return res;
}
export async function updateErrorMachines(id, params) {
    const res = await axios.patch(prefix+'error-machines/' + id, params);
    return res;
}
export async function deleteErrorMachines(id) {
    const res = await axios.delete(prefix+'error-machines/' + id);
    return res;
}
export async function exportErrorMachines(params) {
    const res = await axios.post(prefix+'error-machines/export', { params });
    return res;
}
export async function importErrorMachines(params) {
    const res = await axios.post(prefix+'error-machines/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}