import axios from "axios";
const prefix = "p2/ui/";
//ProductionOrderPriorities
export async function updateProductionOrderHistory(params) {
  const res = await axios.post(
    prefix + "production-order-history/update",
    params
  );
  return res;
}
