import axios from "axios";
const prefix = 'p2/ui/master-data/';
//ProductOrders
export async function getProductOrders(params) {
    const res = await axios.get(prefix+'product-orders', { params });
    return res;
}

export async function getProductOrderPriority() {
    const res = await axios.get(prefix+'product_orders/priority');
    return res;
}

export async function createProductOrders(params) {
    const res = await axios.post(prefix+'product-orders', params);
    return res;
}
export async function updateProductOrders(id, params) {
    const res = await axios.patch(prefix+'product-orders/' + id, params);
    return res;
}
export async function deleteProductOrders(id) {
    const res = await axios.delete(prefix+'product-orders/' + id);
    return res;
}
export async function deleteMultipleProductOrders(params) {
    const res = await axios.post(prefix+'product-orderss/delete', params);
    return res;
}
export async function exportProductOrders(params) {
    const res = await axios.get(prefix+'product-orders-export', { params });
    return res;
}
export async function importProductOrders(params) {
    const res = await axios.post(prefix+'product-orders/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}
export async function updateNumberMachine(params) {
    const res = await axios.post(prefix+'product-orders/update-number-machine', params );
    return res;
}
export async function updateProductOrderConfirmDate(params) {
    const res = await axios.post(prefix+'product-orders/update-confirm-date', params );
    return res;
}