import axios from "axios";
const prefix = "p2/ui/master-data/";
export async function getMachineShifts(params) {
  const res = await axios.get(prefix + "machine-shift", { params });
  return res;
}
export async function createMachineShifts(params) {
  const res = await axios.post(prefix + "machine-shift", params);
  return res;
}
export async function updateMachineShifts(params) {
  const res = await axios.put(prefix + "machine-shift", params);
  return res;
}
export async function deleteMachineShifts(params) {
  const res = await axios.post(prefix + "machine-shift/delete", params);
  return res;
}
