import { Form, Modal, Row, Col, Button, Divider, Radio, Space, InputNumber, Input, Table } from 'antd';
import React, { useState } from 'react';
import './popupStyle.scss'
import ScanButton from '../Button/ScanButton';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { getChecksheetList, scanError, sendResultError } from '../../api/oi/quality';
import { updateErrorLog } from '../../api/phase2/oi/quality';
import { findError, getLotErrorLogList, updateLotErrorLog } from '../../api/phase2/oi/manufacture';
import Title from 'antd/es/typography/Title';
const PopupDauNoi = (props) => {
    const { line } = useParams();
    const { text = "", lotId, onSubmit = null, data = null } = props;
    const closeModal = () => {
        setOpen(false);
    }
    const machine = (new URLSearchParams(window.location.search).get('machine') || '');
    const [errorsList, setErrorsList] = useState(data);
    const [errorsListHistory, setErrorsListHistory] = useState([]);
    const onScan = async (result) => {
        var res = await findError({ error_id: result });
        if (res.success) {
            const formData = form.getFieldValue('log');
            if (formData.every(e => e?.id?.toLowerCase() !== result?.toLowerCase())) {
                console.log(res.data);
                const data = res.data;
                const newErrorsList = [...formData, data];
                setErrorsList(newErrorsList)
                form.setFieldValue('log', newErrorsList);
            }
        }
    }
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const result = Form.useWatch('result', form);
    const fetchErrorHistory = async () => {
        if (lotId) {
            var res = await getLotErrorLogList({ lot_id: lotId, machine_code: machine, line_id: line });
            if (res.success) {
                setErrorsListHistory(res.data.errorList ?? []);
                // form.setFieldValue('log', res.data.errorList ?? []);
            }
        }
    }
    const onFinish = async (values) => {
        const newLog = (values.log ?? []).reduce((a, v) => ({ ...a, [v.id]: v.value }), {});
        if (lotId) {
            const params = {
                lot_id: lotId,
                line_id: line,
                machine_code: machine,
                log: newLog
            }
            console.log(params);
            var res = await updateLotErrorLog(params);
            if (res.success) {
                closeModal();
                onSubmit && onSubmit();
            }
        }
    }
    useEffect(() => {
        if (open) {
            fetchErrorHistory();
        } else {
            form.resetFields();
        }
    }, [open]);

    const errorLogColumns = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: 50,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Tổng số lượng",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: 90,
        },
        {
            title: "Người nhập",
            dataIndex: "user_name",
            key: "user_name",
            align: "center",
            width: 150,
        },
        {
            title: "Thời gian ghi nhận",
            dataIndex: "date",
            key: "date",
            align: "center",
            width: 150,
        },
    ];

    const detailErrorColumns = [
        {
            title: "Mã lỗi",
            dataIndex: "error_id",
            key: "error_id",
            align: "center",
            width: 50,
        },
        {
            title: "Số lỗi",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: 50,
        },
    ];

    const expandedTable = (data) => (
        <Table columns={detailErrorColumns} dataSource={data} pagination={false} size='small' bordered style={{ marginTop: 4, marginBottom: 4 }} />
    );


    return (
        <React.Fragment>
            <Button block size='large' type='primary' style={{ height: '100%', width: '100%' }} onClick={() => { setOpen(true); form.resetFields() }}>Dấu nối</Button>
            <Modal
                title="Bảng quản lý lỗi"
                open={open}
                onCancel={closeModal}
                footer={null}
                width={600}
            >
                <ScanButton placeholder={'Nhập mã lỗi hoặc quét mã QR'} onScan={onScan} />
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        log: []
                    }}
                    colon={false}
                // {...formItemLayout}
                >
                    <Form.List name={'log'}>
                        {(fields, { add, remove }, { errors }) =>
                            fields.map((e, index) => {
                                return (
                                    <Row gutter={8} className={'mt-2'}>
                                        <Col span={12} style={{ paddingInline: 4 }} className='d-flex justify-content-center flex-wrap align-items-lg-center'>
                                            <div className='d-flex justify-content-center align-content-center flex-grow-1 align-items-lg-center' style={{ backgroundColor: '#EBEBEB', height: '100%' }}>
                                                {errorsList.find((e, i) => i === index)?.noi_dung}
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={[index, 'id']} noStyle hidden>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name={[index, 'value']} noStyle>
                                                <InputNumber inputMode='numeric' placeholder='Nhập số lượng' min={0} style={{ width: '100%' }} required />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            })}
                    </Form.List>
                    <div className='d-flex flex-column mt-1'>
                        <Title level={5}>Lịch sử nhập dấu nối</Title>
                        <Table bordered size='small' pagination={false} columns={errorLogColumns} dataSource={errorsListHistory} expandable={{
                            rowExpandable: (record) => (record.log ?? []).length,
                            expandedRowRender: (record) =>
                                expandedTable(record.log ?? []),
                        }}
                            summary={(pageData) => {
                                let total = 0;
                                pageData.forEach(({ quantity }) => {
                                    total += quantity;
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={2}><strong>Tổng số lượng</strong></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align='center'>
                                                <strong>{total}</strong>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        >
                        </Table>
                    </div>
                    <Row>
                        <Col span={24} className='mt-3'>
                            <Form.Item noStyle>
                                <Button type='primary' style={{ width: '100%' }} onClick={()=>form.submit()}>Lưu dữ liệu</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default PopupDauNoi;