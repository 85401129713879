import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createErrorMachines, deleteErrorMachines, exportErrorMachines, getErrorMachines, importErrorMachines, updateErrorMachines } from '../../../api/phase2/master_data/error_machine';
import './MasterDataStyle.css'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getLineList } from '../../../api/phase2/oi/manufacture';
import { downloadExcel } from '../../../helpers/data_blob';

const ErrorMachines = () => {
    document.title = "Quản lý lỗi máy";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [lineList, setLineList] = useState([]);
    const col_detailTable = [
        {
            title: 'Mã lỗi ',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            width: 50,
        },
        {
            title: 'Nội dung',
            dataIndex: 'noi_dung',
            key: 'noi_dung',
            align: 'center',
            width: 150,
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line',
            key: 'line',
            align: 'center',
            render: (value, item, index) => value?.name,
            width: 100,
        },
        {
            title: 'Loại lỗi',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: 80,
        },
        {
            title: 'Nguyên nhân',
            dataIndex: 'nguyen_nhan',
            key: 'nguyen_nhan',
            align: 'center',
            width: 200,
        },
        {
            title: 'Khắc phục',
            dataIndex: 'khac_phuc',
            key: 'khac_phuc',
            align: 'center',
            width: 200,
        },
        {
            title: 'Phòng ngừa',
            dataIndex: 'phong_ngua',
            key: 'phong_ngua',
            align: 'center',
            width: 200,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            width: 80,
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => editRecord(record)} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ]
    const formFields = [
        {
            title: 'Mã lỗi ',
            key: 'code',
            required: true
        },
        {
            title: 'Nội dung',
            key: 'noi_dung',
            required: true
        },
        {
            title: 'Công đoạn',
            key: 'line_id',
            required: true,
            options: lineList,
        },
        {
            title: 'Nguyên nhân',
            key: 'nguyen_nhan',
            // required: true
        },
        {
            title: 'Khắc phục',
            key: 'khac_phuc',
            // required: true
        },
        {
            title: 'Phòng ngừa',
            key: 'phong_ngua',
            // required: true
        },
    ];

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getErrorMachines(params);
        setData(res.data.data.map(e => {
            return { ...e, key: e.id }
        }));
        setTotalPage(res.data.total);
        setLoading(false);
    }
    useEffect(() => {
        fetchLine();
        btn_click();
    }, []);

    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...params, page: page, pageSize: pageSize });
    }

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const onFinish = async (values) => {
        console.log(values);
        if (isEdit) {
            const res = await updateErrorMachines(values.id, values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        } else {
            const res = await createErrorMachines(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        }
    }

    const deleteRecord = async (record) => {
        const res = await deleteErrorMachines(record.id);
        setListCheck([]);
        loadListTable(params);
    }
    const editRecord = (record) => {
        setIsEdit(true)
        form.setFieldsValue({ ...record });
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportErrorMachines(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    const fetchLine = async () => {
        const lineList = await getLineList({ type: 'sx' });
        setLineList(lineList.data || []);
        // setMachineOption([]);
    }

    const handleExportData = async () => {
        setLoading(true);
        const response = await exportErrorMachines();
        if (response.success) {
            const { data, type, file } = response.data;
            if (data && type && file) {
                downloadExcel(data, type, file);
            } else message.error("Data invalid!");
        }
        setLoading(false);
    };
    return <>
        {contextHolder}
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }} className='custom-ant-card'>
                    <Divider>Tìm kiếm</Divider>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Form.Item label="Mã" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, id: e.target.value })} placeholder='Nhập mã' />
                            </Form.Item>
                            <Form.Item label="Tên" className='mb-3'>
                                <Input allowClear onChange={(e) => setParams({ ...params, name: e.target.value })} placeholder='Nhập tên' />
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={
                        {
                            padding: '10px',
                            textAlign: 'center'
                        }
                    }
                        layout="vertical">
                        <Button type='primary'
                            style={{ width: '80%' }} onClick={() => btn_click()}>
                            Tìm kiếm
                        </Button>
                    </div>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý lỗi máy" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                setLoadingExport(true);
                                const formData = new FormData();
                                formData.append("file", file);
                                const res = await importErrorMachines(formData);
                                if (res.success) {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                } else {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={handleExportData} loading={exportLoading}>Export Excel</Button>
                        {/* <Button type="primary" onClick={editRecord} disabled={listCheck.length <= 0}>Edit</Button> */}
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        {/* <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + listCheck.length + " bản ghi đã chọn?"}
                            onConfirm={deleteRecord}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={listCheck.length <= 0}>Delete</Button>
                        </Popconfirm> */}

                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            pagination={{
                                current: page,
                                size: 'default',
                                total: totalPage,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize);
                                }
                            }}
                            scroll={
                                {
                                    y: '80vh'
                                }
                            }
                            columns={col_detailTable}
                            dataSource={data}
                        />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Form.Item name={'id'} className='mb-3' hidden >
                        <Input />
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item name={'code'} className='mb-3' label={'Mã lỗi'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'noi_dung'} className='mb-3' label={'Nội dung'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'line_id'} className='mb-3' label={'Công đoạn'} rules={[{ required: true }]}>
                            <Select options={lineList} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'type'} className='mb-3' label={'Loại lỗi'} rules={[{ required: true }]}>
                            <Select options={[{ value: 1, label: 'Máy lỗi' }, { value: 2, label: 'Dừng nghỉ' }]} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'nguyen_nhan'} className='mb-3' label={'Nguyên nhân'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'khac_phuc'} className='mb-3' label={'Khắc phục'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'phong_ngua'} className='mb-3' label={'Phòng ngừa'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default ErrorMachines;
