import {
  Col,
  Row,
  Card,
  Table,
  Divider,
  Button,
  Form,
  Space,
  message,
  Modal,
  InputNumber,
  Progress,
  Flex,
  Tag,
  Popconfirm,
  Select,
} from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { getCustomers, getDataFilterUI } from "../../../api/ui/main";
import dayjs from "dayjs";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { getCustomer } from "../../../api/ui/customer";
import { getProduct } from "../../../api/ui/product";
import { manufatureQuicker } from "../../../routes/quickerCollection";
import {
  getProductionOrderPriorities,
  reorderProductionOrder,
  updateProductionOrderPriorities,
} from "../../../api/phase2/ui/production-order-priorities";
import { updateProductionOrderHistory } from "../../../api/phase2/ui/production-order-histories";
import {
  getLosxPriority,
  updateLosx,
  updateStatusLosx,
} from "../../../api/phase2/ui/losx";
import {
  CaretRightOutlined,
  EditOutlined,
  EyeOutlined,
  HolderOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import {
  DndContext,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { render } from "@testing-library/react";

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};
const DraggableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
const ProductionOrderPriorities = () => {
  const [listNameProducts, setListNameProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [listCustomers, setListCustomers] = useState([]);
  const [params, setParams] = useState({
    start_date: dayjs(),
    end_date: dayjs(),
  });
  const [dateType, setDateType] = useState("date");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [openMdl, setOpenMdl] = useState(false);
  // State để lưu record đang được sửa và giá trị confirm_date hiện tại
  const [editRecord, setEditRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editHistory, setEditHistory] = useState({});
  const [editValues, setEditValues] = useState({
    new_order_quantity: 0,
    inventory_quantity: 0,
    fc_order_quantity: 0,
    outstanding_order: 0,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
const [editedPriorities, setEditedPriorities] = useState({});
  const onCancel = () => {
    setOpenMdl(false);
  };

  const [columns, setColumns] = useState([]);

  const defaultColumns = [
    {
      title: "Thứ tự",
      dataIndex: "priority",
      align: "center",
      width: "50px",
    },
    {
      title: "Mã hàng hoá",
      dataIndex: "product_id",
      align: "center",
      width: "90px",
      fixed: "left",
    },
    {
      title: "Tên hàng hoá",
      dataIndex: "product_name",
      align: "center",
      width: "110px",
      fixed: "left",
      render: (value, record) => record.product?.name ?? "",
    },
    {
      title: "Mã lô",
      dataIndex: "id",
      align: "center",
      width: "90px",
    },
    {
      title: "Tổng đơn hàng",
      dataIndex: "order_quantity",
      align: "center",
      width: "110px",
    },
    {
      title: "SL đã sản xuất",
      dataIndex: "produced_quantity",
      align: "center",
      width: "100px",
    },
    {
      title: "SL còn lại",
      dataIndex: "production_quantity",
      align: "center",
      width: "100px",
      render: (value, record) =>
        parseInt(record.order_quantity) - parseInt(record.produced_quantity),
    },
    {
      title: "Tiến độ",
      dataIndex: "progress",
      align: "center",
      width: "110px",
      render: (value, record) => (
        <Flex justifyContent="center" gap="small">
          <Progress
            strokeLinecap="butt"
            percent={
              parseInt(
                (record.produced_quantity /
                  record.order_quantity
              ) * 100)
            }
          />
        </Flex>
      ),
    },
    {
      title: "Tồn công đoạn",
      dataIndex: "ton",
      key: "ton",
      align: "center",
      children: [
        {
          title: "Gấp dán",
          dataIndex: "gdlh",
          key: "gdlh",
          align: "center",
          width: "120px",
          render: (_, record) => {
            return (
              (record?.product?.line_inventory ?? []).find(
                (e) => e.line_id == 24
              )?.quantity ?? 0
            );
          },
        },
        {
          title: "In flexo",
          dataIndex: "in",
          key: "in",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (
              (record?.product?.line_inventory ?? []).find(
                (e) => e.line_id == 25
              )?.quantity ?? 0
            );
          },
        },
        {
          title: "Dán Liner",
          dataIndex: "liner",
          key: "liner",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (
              (record?.product?.line_inventory ?? []).find(
                (e) => e.line_id == 27
              )?.quantity ?? 0
            );
          },
        },
        {
          title: "Đục cắt",
          dataIndex: "dc",
          key: "dc",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (
              (record?.product?.line_inventory ?? []).find(
                (e) => e.line_id == 26
              )?.quantity ?? 0
            );
          },
        },
        {
          title: "Chọn",
          dataIndex: "chon",
          key: "chon",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (
              (record?.product?.line_inventory ?? []).find(
                (e) => e.line_id == 29
              )?.quantity ?? 0
            );
          },
        },
      ],
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "delivery_date",
      key: "delivery_date",
      align: "center",
      width: "110px",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      align: "center",
      fixed: "right",
      width: "110px",
      render: (value) => {
        switch (value) {
          case 0:
            return <Tag color="default">Đang chờ</Tag>;
          case 1:
            return <Tag color="success">Đang thực hiện</Tag>;
          case 2:
            return <Tag color="success">Hoàn thành</Tag>;
          case 3:
            return <Tag color="red">Tạm dừng</Tag>;
          default:
            return "";
        }
      },
    },
  ];

  const handlePriorityChange = (key, value) => {
    setEditedPriorities((prev) => ({ ...prev, [key]: value }));
    setData((prev) =>
      prev.map((item) => (item.key === key ? { ...item, priority: value } : item))
    );
  };

  useEffect(() => {
    if (isEditing) {
      const editingColumns = defaultColumns.map((col) => {
        if (col.dataIndex === "priority") {
          return {
            ...col,
            render: (value, record) => (
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                value={
                  data[record.key] !== undefined
                    ? data[record.key]
                    : value
                }
                onChange={(val) => handlePriorityChange(record.key, val)}
              />
            ),
          };
        }
        return col;
      });
      setColumns([
        // {
        //   key: "sort",
        //   align: "center",
        //   width: 80,
        //   render: () => <DragHandle />,
        // },
        ...editingColumns,
      ]);
    } else {
      setColumns([
        ...defaultColumns,
        {
          title: "Tác vụ", // Cột tác vụ mới
          key: "action",
          align: "center",
          width: "100px",
          fixed: "right",
          render: (text, record) => (
            <Space size={5}>
              {record?.status === 1 && (
                <Popconfirm
                  title="Xác nhận dừng?"
                  onConfirm={() => updateStatusPlan(record, 3)}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button
                    className="edit-btn"
                    type="link"
                    size="small"
                    icon={<PauseOutlined />}
                  />
                </Popconfirm>
              )}
              {(record?.status === 3 || record?.status === 0) && (
                <Popconfirm
                  title="Xác nhận sản xuất?"
                  onConfirm={() => updateStatusPlan(record, 1)}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button
                    className="edit-btn"
                    type="link"
                    size="small"
                    icon={<CaretRightOutlined />}
                  />
                </Popconfirm>
              )}
              <EyeOutlined
                style={{ marginRight: 5 }}
                className="edit-btn"
                size="small"
                onClick={() => openHistory(record)}
              />
              <EditOutlined
                className="edit-btn"
                size="small"
                onClick={() => handleEdit(record)}
                style={{ marginRight: 5 }}
              />
            </Space>
          ),
        },
      ]);
    }
  }, [isEditing]);

  const updateStatusPlan = async (record, status) => {
    if ([1, 0, 3].includes(record?.status)) {
      const response = await updateStatusLosx({
        id: record?.id,
        status: status,
      });
      if (response) {
        loadListTable(params);
      }
    } else message.error("Thao tác thất bại!");
  };

  const handleMarkCompleted = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning("Vui lòng chọn ít nhất một dòng");
      return;
    }
    // Lấy các dòng được chọn từ data
    const rowsToUpdate = data.filter((row) =>
      selectedRowKeys.includes(row.key)
    );
    try {
      await Promise.all(
        rowsToUpdate.map((row) => updateStatusLosx({ id: row.id, status: 2 }))
      );
      setSelectedRowKeys([]); // xóa lựa chọn sau khi cập nhật
      loadListTable(params);
    } catch (error) {
      message.error("Cập nhật thất bại");
    }
  };

  const openHistory = (record) => {
    setOpenMdl(true);
    setEditHistory(record);
    setDataHistory(record?.production_order_history ?? []);
  };
  const handleActualQuantityChange = (record, value) => {
    const newData = dataHistory.map((item) => {
      if (item.id === record.id) {
        return { ...item, produced_quantity: value };
      }
      return item;
    });
    setDataHistory(newData);
  };
  const handleEdit = (record) => {
    setEditRecord(record);
    setEditValues({
      order_quantity: record.order_quantity,
    });
    setOpenEditModal(true);
  };
  const columnsHistory = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "50px",
      fixed: "left",
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Công đoạn",
      dataIndex: "line",
      align: "center",
      render: (value) => value?.name ?? "",
    },
    {
      title: "Tổng đơn hàng",
      dataIndex: "order_quantity",
      align: "center",
    },
    {
      title: "SL đã sản xuất",
      dataIndex: "produced_quantity",
      align: "center",
      render: (value, record) => (
        <InputNumber
          value={record.produced_quantity}
          onChange={(val) => handleActualQuantityChange(record, val)}
        />
      ),
    },
    {
      title: "SL còn lại",
      dataIndex: "production_quantity",
      align: "center",
      render: (value, record) =>
        record.order_quantity - record.produced_quantity,
    },
    {
      title: "Tiến độ",
      dataIndex: "progress",
      align: "center",
      width: "140px",
      render: (value, record) => (
        <Flex justifyContent="center" gap="small">
          <Progress
            strokeLinecap="butt"
            percent={(
              (record.produced_quantity / record.order_quantity) *
              100
            ).toFixed(2)}
          />
        </Flex>
      ),
    },
  ];

  const [dataHistory, setDataHistory] = useState([]);

  useEffect(() => {
    (async () => {
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => ({ ...e, label: e.name, value: e.id }))
      );
    })();
  }, []);

  function btn_click(page = 1, pageSize = 20) {
    setPage(page);
    setPageSize(pageSize);
    loadListTable({ ...params, page, pageSize });
  }

  const handleUpdateProductionOrder = async () => {
    // Giả sử có API cập nhật đơn hàng, ví dụ:
    // await updateProductionOrder({ production_order_id: editRecord.production_order_id, ...editValues });
    const res = await updateLosx({
      id: editRecord.id,
      ...editValues,
    });
    if (res.success) {
      setOpenEditModal(false);
      setEditRecord(null);
      loadListTable(params);
    } else {
      message.error("Cập nhật thất bại");
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => ({ ...e, label: e.name, value: e.id }))
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    const res = await getLosxPriority(params);
    setData((res.data?.data ?? []).map((e) => ({ ...e, key: e.id })));
    setTotalPage(res.data.total);
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
    })();
  }, []);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
      default:
        return dayjs(value).format("DD/MM/YYYY");
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.start_date, dateType);
    onChangeDate("end_date", params.end_date, dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("isoWeek"),
            end_date: dayjs(params.end_date).endOf("isoWeek"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("isoWeek"),
            end_date: dayjs(value).endOf("isoWeek"),
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("month"),
            end_date: dayjs(params.end_date).endOf("month"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("month"),
            end_date: dayjs(value).endOf("month"),
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("year"),
            end_date: dayjs(params.end_date).endOf("year"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("year"),
            end_date: dayjs(value).endOf("year"),
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({ ...params, start_date: dayjs(value) });
        } else {
          setParams({ ...params, end_date: dayjs(value) });
        }
        break;
    }
  };

  const fetchCustomer = async () => {
    setLoading(true);
    const response = await getCustomer();
    if (response?.success) {
      setCustomerOptions(
        (response.data?.data ?? []).map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    }
    setLoading(false);
  };

  const fetchProduct = async () => {
    setLoading(true);
    const response = await getProduct();
    if (response?.success) {
      setProductOptions(
        (response.data?.data ?? []).map((item) => ({
          label: (
            <>
              <span style={{ fontWeight: 600 }}>{item?.id}</span>
              <small style={{ color: "gray" }}>({item?.name})</small>
            </>
          ),
          value: item?.id,
        }))
      );
    }
    setLoading(false);
  };

  const renderQuicker = () => {
    const className = (path) =>
      window.location.pathname.indexOf(path) > -1 ? "active" : "inactive";
    return (
      <ul>
        {manufatureQuicker.map((e) => (
          <li key={e.path}>
            <Link to={e.path} className={`quicker ${className(e.path)}`}>
              {e.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    fetchProduct();
    fetchCustomer();
  }, []);

  function detectSensor() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  const sensors = useSensors(
    useSensor(detectSensor() ? TouchSensor : MouseSensor)
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setData((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        const newData = arrayMove(prev, activeIndex, overIndex);
        newData.map((e, i) => {
          return { ...e, new_order: i + 1 };
        });
        return newData;
      });
    }
  };

  const handleChange = () => {
    if (isEditing) {
      saveChange();
    }
    setIsEditing(!isEditing);
  };

  const handleProductionOrderHistory = async () => {
    if (!dataHistory) {
      return;
    }
    const res = await updateProductionOrderHistory({
      lo_sx: editHistory.id,
      dataHistory: dataHistory,
    });
    if (res.success) {
      setOpenMdl(false);
      setDataHistory(null);
      loadListTable();
    } else {
      message.error("Cập nhật lịch sử sản xuất thất bại");
    }
  };

  const saveChange = async () => {
    console.log(data);

    const transformedOrders = data.reduce((acc, order, index) => {
      acc[order.id] = order.priority;
      return acc;
    }, {});
    var res = await reorderProductionOrder(transformedOrders);
    loadListTable();
  };

  return (
    <>
      <Row style={{ padding: "8px" }} className="custom-row" gutter={[8, 8]}>
        <Col span={4} className="custom-col">
          <Card
            className="custom-ant-card"
            style={{ height: "100%" }}
            bodyStyle={{ padding: 0 }}
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <div className="mb-3">
              <Form style={{ margin: "0 15px" }} layout="vertical">
                <Divider>Quicker</Divider>
                {renderQuicker()}
                <Divider>Điều kiện truy vấn</Divider>
                <Form.Item label="Tên sản phẩm" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    onChange={(value) =>
                      setParams({ ...params, product_id: value })
                    }
                    placeholder="Nhập tên sản phẩm"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listNameProducts}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
        <Col className="custom-col-table" span={20}>
          <Card
            style={{ height: "100%" }}
            title="Thứ tự đơn hàng"
            extra={
              <Space>
                <Button type="primary" onClick={() => handleChange()}>
                  {isEditing ? "Lưu" : "Sắp xếp"}
                </Button>
                <Button type="primary" onClick={handleMarkCompleted}>
                  Đánh dấu hoàn thành
                </Button>
              </Space>
            }
          >
            <DndContext
              sensors={sensors}
              collisionDetection={rectIntersection}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={data.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  size="small"
                  bordered
                  loading={loading}
                  locale={{ emptyText: "" }}
                  className="table-order"
                  pagination={{
                    current: page,
                    pageSize: 20,
                    size: "small",
                    total: totalPage,
                    onChange: (page, pageSize) => btn_click(page, pageSize),
                  }}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (keys) => setSelectedRowKeys(keys),
                  }}
                  scroll={{ y: CalculateTableHeight("table-order"), x: 1600 }}
                  columns={columns}
                  dataSource={data}
                  components={{
                    body: {
                      row: DraggableRow,
                    },
                  }}
                />
              </SortableContext>
            </DndContext>
          </Card>
        </Col>
      </Row>
      {/* Modal để cập nhật ngày PKHSX xác nhận */}
      <Modal
        title="Lịch sử sản xuất"
        open={openMdl}
        onCancel={onCancel}
        width={800}
        onOk={handleProductionOrderHistory}
      >
        <Table
          size="small"
          bordered
          loading={loading}
          locale={{ emptyText: "" }}
          pagination={false}
          scroll={{ y: "40vh" }}
          columns={columnsHistory}
          dataSource={dataHistory}
        />
      </Modal>
      <Modal
        title="Chỉnh sửa đơn hàng"
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false);
          setEditRecord(null);
        }}
        onOk={handleUpdateProductionOrder}
      >
        <Form layout="vertical">
          <Form.Item label="Tổng đơn hàng">
            <InputNumber
              style={{ width: "100%" }}
              value={editValues.order_quantity}
              onChange={(val) =>
                setEditValues({ ...editValues, order_quantity: val })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProductionOrderPriorities;
