import { DeleteOutlined, EditOutlined, UploadOutlined, PlusOutlined, FileExcelOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { deleteRecordProductPlan, exportInfoCongDoan, getInfoCongDoan, getListProductPlan, storeProductPlan, updateInfoCongDoan, updateProductPlan } from '../../../api';
import dayjs from "dayjs";
import { getTreeSelect } from '../../../api/phase2/ui/main';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const SanLuong = () => {
    document.title = "Quản lý sản lượng";
    const [listLines, setListLines] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [selectedLine, setSelectedLine] = useState();
    const [listCheck, setListCheck] = useState([]);
    const [openMdlEdit, setOpenMdlEdit] = useState(false);
    const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
    const [form] = Form.useForm();
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [dateType, setDateType] = useState('date');
    const onChangeChecbox = (e) => {
        console.log(e);
        if (!e.target.checked) {
            setselectedRecord();
        } else {
            setselectedRecord(e.target.value);
        }
    }
    const [selectedRecord, setselectedRecord] = useState();
    useEffect(() => {
        console.log(selectedRecord);
    }, [selectedRecord])
    const col_detailTable = [
        {
            title: 'Chọn',
            dataIndex: 'select',
            key: 'select',
            render: (value, item, index) => <Checkbox checked={selectedRecord === item.id} value={item.id} onChange={onChangeChecbox}></Checkbox>,
            align: 'center',
            width: 60,
            fixed: 'left'
        },
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            render: (value, item, index) => index + 1,
            width: 60,
            fixed: 'left'
        },
        {
            title: 'Mã pallet/thùng',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line',
            key: 'line',
            align: 'center',
            render: (value, item, index) => value.name,
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Máy',
            dataIndex: 'machine_code',
            key: 'machine_code',
            align: 'center',
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Thời gian bắt đầu',
            dataIndex: 'thoi_gian_bat_dau',
            key: 'thoi_gian_bat_dau',
            align: 'center',
            width: 150,
        },
        {
            title: 'Thời gian bấm máy',
            dataIndex: 'thoi_gian_bam_may',
            key: 'thoi_gian_bam_may',
            align: 'center',
            width: 150,
        },
        {
            title: 'Thời gian kết thúc',
            dataIndex: 'thoi_gian_ket_thuc',
            key: 'thoi_gian_ket_thuc',
            align: 'center',
            width: 150,
        },
        {
            title: 'Sản lượng đầu vào vào hàng',
            dataIndex: 'sl_dau_vao_chay_thu',
            key: 'sl_dau_vao_chay_thu',
            align: 'center',
            width: 200,
        },
        {
            title: 'Sản lượng đầu ra vào hàng',
            dataIndex: 'sl_dau_ra_chay_thu',
            key: 'sl_dau_ra_chay_thu',
            align: 'center',
            width: 200,
        },
        {
            title: 'Sản lượng đầu vào thực tế',
            dataIndex: 'sl_dau_vao_hang_loat',
            key: 'sl_dau_vao_hang_loat',
            align: 'center',
            width: 200,
        },
        {
            title: 'Sản lượng đầu ra thực tế',
            dataIndex: 'sl_dau_ra_hang_loat',
            key: 'sl_dau_ra_hang_loat',
            align: 'center',
            width: 200,
        },
        {
            title: 'Số lượng tem vàng',
            dataIndex: 'sl_tem_vang',
            key: 'sl_tem_vang',
            align: 'center',
            width: 150,
        },
        {
            title: 'Số lượng NG',
            dataIndex: 'sl_ng',
            key: 'sl_ng',
            align: 'center',
            width: 120,
        },
    ]
    const formFields = [
        {
            title: 'Mã pallet/thùng',
            key: 'lot_id',
            disabled: true
        },
        {
            title: 'Công đoạn',
            key: 'line_id',
            hidden: true,
            disabled: true
        },
        {
            title: 'Công đoạn',
            key: 'line',
            ignore: true,
            disabled: true
        },
        {
            title: 'Máy',
            key: 'machine_code',
            ignore: true,
            disabled: true
        },
        {
            title: 'Thời gian bắt đầu',
            key: 'thoi_gian_bat_dau',
        },
        {
            title: 'Thời gian bấm máy',
            key: 'thoi_gian_bam_may',
        },
        {
            title: 'Thời gian kết thúc',
            key: 'thoi_gian_ket_thuc',
        },
        {
            title: 'Sản lượng đầu vào vào hàng',
            key: 'sl_dau_vao_chay_thu',
        },
        {
            title: 'Sản lượng đầu ra vào hàng',
            key: 'sl_dau_ra_chay_thu',
        },
        {
            title: 'Sản lượng đầu vào thực tế',
            key: 'sl_dau_vao_hang_loat',
        },
        {
            title: 'Sản lượng đầu ra thực tế',
            key: 'sl_dau_ra_hang_loat',
        },
        {
            title: 'Số lượng tem vàng',
            key: 'sl_tem_vang',
        },
        {
            title: 'Số lượng NG',
            key: 'sl_ng',
        },
    ]
    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
            const res2 = await getProducts();
            setListNameProducts(res2.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
            const res3 = await getLoSanXuat();
            setListLoSX(Object.values(res3.data).map(e => {
                return { label: e, value: e }
            }));
        })()
    }, [])

    function btn_click() {
        loadListTable(params)
    }

    // useEffect(()=>{
    //     (async ()=>{
    //         var res = await getDataFilterUI({khach_hang: params.khach_hang});
    //         if(res.success){
    //             setListNameProducts(res.data.product.map(e => {
    //                 return { ...e, label: e.name, value: e.id }
    //             }));
    //             setListLoSX(Object.values(res.data.lo_sx).map(e => {
    //                 return { label: e, value: e }
    //             }));
    //         }
    //     })()
    // }, [params.khach_hang])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getInfoCongDoan(params);
        setData(res);
        setLoading(false);
    }
    useEffect(() => {
        (async () => {
            loadListTable(params);
        })()
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    const onFinish = async (values) => {
        const res = await updateInfoCongDoan(values);
        setOpenMdlEdit(false);
        loadListTable(params);
    }

    const deleteRecord = async () => {
        if (listCheck.length > 0) {
            const res = await deleteRecordProductPlan(listCheck);
            setListCheck([]);
            loadListTable(params);
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }
    const editRecord = () => {
        setTitleMdlEdit('Cập nhật')
        if (!selectedRecord) {
            message.info('Chọn 1 bản ghi để chỉnh sửa');
        } else {
            const result = data.find((record) => record.id === selectedRecord);
            form.setFieldsValue({ ...result, line: result.line.name });
            setOpenMdlEdit(true);
        }
    }
    const insertRecord = () => {

    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportInfoCongDoan(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const onCheck = (checkedKeys, info) => {
        console.log(checkedKeys, info);

        const selectKeys = {
            line_id: [],
            machine_id: [],
        };
        function traverse(node) {
            switch (node.type) {
                case 'line':
                    selectKeys.line_id.push(node.key?.toString());
                    break;
                case 'machine':
                    selectKeys.machine_id.push(node.key?.toString());
                    break;
                default:
                    break;
            }
        }
        info.checkedNodes.map(traverse)
        setParams({ ...params, ...selectKeys });
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Divider>Công đoạn</Divider>
                        <Form.Item className='mb-3'>
                            {
                                listLines.length ?
                                    <Tree
                                        defaultExpandedKeys={[2]}
                                        style={{ maxHeight: 250, overflowY: 'auto' }}
                                        checkable
                                        selectable={false}
                                        onCheck={onCheck}
                                        treeData={listLines}
                                    />
                                    :
                                    <LoadingOutlined />
                            }
                        </Form.Item>
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                            ]}
                            size='small'
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Mã pallet/thùng" className='mb-3'>
                            <Input allowClear onChange={(e) => setParams({ ...params, lot_id: e.target.value })} placeholder='Nhập mã' />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Card style={{ height: '100%' }} title="Sản lượng" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            action={baseURL + "/api/info-cong-doan/import"}
                            headers={{
                                authorization: 'authorization-text',
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    error()
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        loadListTable(params);
                                        success();
                                        setLoadingExport(false);
                                    } else {
                                        loadListTable(params);
                                        message.error(info.file.response.message);
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        <Button type="primary" onClick={editRecord}>Edit</Button>
                    </Space>
                }>
                    <Table size='small' bordered
                        locale={{emptyText: ''}}
                        className="table-mh80"
                        loading={loading}
                        pagination={false}
                        scroll={
                            {
                                x: 1700,
                            }
                        }
                        columns={col_detailTable}
                        dataSource={data} />
                </Card>
            </Col>
        </Row>
        <Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    {formFields.map(e => {
                        if (e.key !== 'select' && e.key !== 'stt') return <Col span={!e.hidden ? 12 : 0}>
                            <Form.Item name={e.key} className='mb-3' label={e.title} hidden={e.hidden}>
                                <Input disabled={e.disabled}></Input>
                            </Form.Item>
                        </Col>
                    })}
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default SanLuong;
