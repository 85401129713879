import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getProductCustomer(params) {
    const res = await axios.get(prefix+'product-customer', { params });
    return res;
}
export async function createProductCustomer(params) {
    const res = await axios.post(prefix+'product-customer', params);
    return res;
}
export async function updateProductCustomer(id, params) {
    const res = await axios.patch(prefix+'product-customer/' + id, params);
    return res;
}
export async function deleteProductCustomer(id) {
    const res = await axios.delete(prefix+'product-customer/' + id);
    return res;
}
export async function deleteManyProductCustomer(ids) {
    const res = await axios.post(prefix+'product-customer/delete', {ids});
    return res;
}
export async function exportProductCustomer(params) {
    const res = await axios.get(prefix+'product-customer/export', { params });
    return res;
}
export async function importProductCustomer(params) {
    const res = await axios.post(prefix+'product-customer/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}
export async function saveAllProductCustomer(params) {
    const res = await axios.post(prefix+'product-customer/save-all', params);
    return res;
}