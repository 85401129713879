import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Divider,
  Button,
  Form,
  Select,
  Upload,
  Space,
  Radio,
  message,
  Modal,
  Badge,
  Tag,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { getCustomers, getDataFilterUI } from "../../../api/ui/main";
import dayjs from "dayjs";
import {
  exportProductOrders,
  getProductOrders,
  importProductOrders,
  updateProductOrderConfirmDate,
} from "../../../api/phase2/master_data/product-order";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { getCustomer } from "../../../api/ui/customer";
import { getProduct } from "../../../api/ui/product";
import { manufatureQuicker } from "../../../routes/quickerCollection";
import { downloadExcel } from "../../../helpers/data_blob";

const Orders = () => {
  const [listNameProducts, setListNameProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [listCustomers, setListCustomers] = useState([]);
  const [params, setParams] = useState({
    start_date: dayjs(),
    end_date: dayjs(),
  });
  const [dateType, setDateType] = useState("date");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [openMdl, setOpenMdl] = useState(false);
  // State để lưu record đang được sửa và giá trị confirm_date hiện tại
  const [editingRecord, setEditingRecord] = useState(null);
  const [editingConfirmDate, setEditingConfirmDate] = useState(null);
  const [modalForm] = Form.useForm();

  const onCancel = () => {
    setOpenMdl(false);
    setEditingRecord(null);
    modalForm.resetFields();
  };
  const handleSaveInventory = async (type) => {
    try {
      const values = await modalForm.validateFields();
      const res = await updateProductOrderConfirmDate({
        id: editingRecord.id,
        type: type,
        confirm_date: values.confirm_date.format("YYYY-MM-DD HH:mm"),
        sl_ton: values.sl_ton,
        ton: [
          { line_id: 24, value: values.gdlh },
          { line_id: 25, value: values.in },
          { line_id: 27, value: values.liner },
          { line_id: 26, value: values.dc },
          { line_id: 29, value: values.chon },
        ],
      });
      if (res.success) {
        loadListTable(params);
        setOpenMdl(false);
        setEditingRecord(null);
        modalForm.resetFields();
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
    }
  };
  const handleUpdateConfirmDate = async (confirmType) => {
    if (!editingRecord || !editingConfirmDate) {
      message.error("Vui lòng chọn ngày hợp lệ");
      return;
    }
    try {
      const res = await updateProductOrderConfirmDate({
        type: confirmType,
        id: editingRecord.id,
        confirm_date: editingConfirmDate.format("YYYY-MM-DD HH:mm"),
      });
      if (res.success) {
        loadListTable(params);
        setOpenMdl(false);
        setEditingRecord(null);
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "50px",
      fixed: "left",
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Số đơn hàng",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: "90px",
    },
    {
      title: "Ngày đặt hàng",
      dataIndex: "order_date",
      align: "center",
      width: "120px",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "customer_id",
      key: "customer_id",
      align: "center",
      width: "100px",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "center",
      width: "200px",
    },
    {
      title: "Mã hàng hoá",
      dataIndex: "product_id",
      key: "product_id",
      align: "center",
      width: "90px",
    },
    {
      title: "Tên hàng hoá",
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
      width: "110px",
      render: (value, record) => record?.product?.name ?? "",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "80px",
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "delivery_date",
      align: "center",
      width: "120px",
      render: (value) => dayjs(value).format("YYYY-MM-DD"),
    },
    {
      title: "Ngày PKHSX xác nhận",
      dataIndex: "confirm_date",
      align: "center",
      width: "150px",
      render: (value) => (value ? value : "Chưa xác nhận"),
    },
    {
      title: "Tồn kho",
      dataIndex: "sl_ton",
      key: "sl_ton",
      align: "center",
      width: "80px",
    },
    {
      title: "Tồn công đoạn",
      dataIndex: "ton",
      key: "ton",
      align: "center",
      children: [
        {
          title: "Gấp dán liên hoàn",
          dataIndex: "gdlh",
          key: "gdlh",
          align: "center",
          width: "120px",
          render: (_, record) => {
            return (record.ton ?? []).find((e) => e.line_id == 24)?.value ?? 0;
          },
        },
        {
          title: "In flexo",
          dataIndex: "in",
          key: "in",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (record.ton ?? []).find((e) => e.line_id == 25)?.value ?? 0;
          },
        },
        {
          title: "Dán Liner",
          dataIndex: "liner",
          key: "liner",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (record.ton ?? []).find((e) => e.line_id == 27)?.value ?? 0;
          },
        },
        {
          title: "Đục cắt",
          dataIndex: "dc",
          key: "dc",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (record.ton ?? []).find((e) => e.line_id == 26)?.value ?? 0;
          },
        },
        {
          title: "Chon",
          dataIndex: "chon",
          key: "chon",
          align: "center",
          width: "80px",
          render: (_, record) => {
            return (record.ton ?? []).find((e) => e.line_id == 29)?.value ?? 0;
          },
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "110px",
      render: (value) => {
        switch (value) {
          case 1:
            return <Tag color="blue">Đã xác nhận</Tag>;
          case 2:
            return <Tag color="blue">Đã hoàn thành</Tag>;
          default:
            return <Tag color="default">Chờ xác nhận</Tag>;
        }
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "left",
      width: "330px",
      ellipsis: true,
    },
    {
      title: "Tác vụ", // Cột tác vụ mới
      key: "action",
      align: "center",
      width: "100px",
      fixed: "right",
      render: (text, record) =>
        (record.status == 0 || !record.status) && (
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setEditingRecord(record);
              setEditingConfirmDate(
                record.confirm_date
                  ? dayjs(record.confirm_date)
                  : dayjs(record.delivery_date)
              );
              modalForm.setFieldsValue({
                confirm_date: record.confirm_date
                  ? dayjs(record.confirm_date)
                  : dayjs(record.delivery_date),
                sl_ton: record.sl_ton,
                gdlh:
                  (record.ton ?? []).find((e) => e.line_id == 24)?.value ?? 0,
                in: (record.ton ?? []).find((e) => e.line_id == 25)?.value ?? 0,
                liner:
                  (record.ton ?? []).find((e) => e.line_id == 27)?.value ?? 0,
                dc: (record.ton ?? []).find((e) => e.line_id == 26)?.value ?? 0,
                chon:
                  (record.ton ?? []).find((e) => e.line_id == 29)?.value ?? 0,
              });
              setOpenMdl(true);
            }}
          >
            Xác nhận
          </Button>
        ),
    },
  ];

  useEffect(() => {
    (async () => {
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => ({ ...e, label: e.name, value: e.id }))
      );
    })();
  }, []);

  function btn_click(page = 1, pageSize = 20) {
    setPage(page);
    setPageSize(pageSize);
    loadListTable({ ...params, page, pageSize });
  }

  useEffect(() => {
    (async () => {
      const res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => ({ ...e, label: e.name, value: e.id }))
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    params.withs = "product.unit";
    const res = await getProductOrders(params);
    setData((res.data?.data ?? []).map((e) => ({ ...e, key: e.id })));
    setTotalPage(res.data.total);
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
    })();
  }, []);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
      default:
        return dayjs(value).format("DD/MM/YYYY");
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.start_date, dateType);
    onChangeDate("end_date", params.end_date, dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("isoWeek"),
            end_date: dayjs(params.end_date).endOf("isoWeek"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("isoWeek"),
            end_date: dayjs(value).endOf("isoWeek"),
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("month"),
            end_date: dayjs(params.end_date).endOf("month"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("month"),
            end_date: dayjs(value).endOf("month"),
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("year"),
            end_date: dayjs(params.end_date).endOf("year"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("year"),
            end_date: dayjs(value).endOf("year"),
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({ ...params, start_date: dayjs(value) });
        } else {
          setParams({ ...params, end_date: dayjs(value) });
        }
        break;
    }
  };

  const fetchCustomer = async () => {
    setLoading(true);
    const response = await getCustomer();
    if (response?.success) {
      setCustomerOptions(
        (response.data?.data ?? []).map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    }
    setLoading(false);
  };

  const fetchProduct = async () => {
    setLoading(true);
    const response = await getProduct();
    if (response?.success) {
      setProductOptions(
        (response.data?.data ?? []).map((item) => ({
          label: (
            <>
              <span style={{ fontWeight: 600 }}>{item?.id}</span>
              <small style={{ color: "gray" }}>({item?.name})</small>
            </>
          ),
          value: item?.id,
        }))
      );
    }
    setLoading(false);
  };

  const renderQuicker = () => {
    const className = (path) =>
      window.location.pathname.indexOf(path) > -1 ? "active" : "inactive";
    return (
      <ul>
        {manufatureQuicker.map((e) => (
          <li key={e.path}>
            <Link to={e.path} className={`quicker ${className(e.path)}`}>
              {e.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    fetchProduct();
    fetchCustomer();
  }, []);
  const handleExportData = async () => {
    setLoading(true);
    const response = await exportProductOrders();
    if (response.success) {
      const { data, type, file } = response.data;
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error("Data invalid!");
    }
    setLoading(false);
  };

  return (
    <>
      <Row style={{ padding: "8px" }} className="custom-row" gutter={[8, 8]}>
        <Col span={4} className="custom-col">
          <Card
            className="custom-ant-card"
            style={{ height: "100%" }}
            bodyStyle={{ padding: 0 }}
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <div className="mb-3">
              <Form style={{ margin: "0 15px" }} layout="vertical">
                <Divider>Quicker</Divider>
                {renderQuicker()}
                <Divider>Thời gian truy vấn</Divider>
                <Radio.Group
                  size="small"
                  options={[
                    {
                      value: "date",
                      label: "Ngày",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "week",
                      label: "Tuần",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "month",
                      label: "Tháng",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "year",
                      label: "Năm",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                  ]}
                  className="d-flex justify-content-center mb-2 w-100"
                  onChange={(e) => setDateType(e.target.value)}
                  value={dateType}
                  optionType="button"
                  buttonStyle="solid"
                />
                <Space direction="vertical" style={{ width: "100%" }}>
                  <DatePicker
                    allowClear={false}
                    placeholder="Bắt đầu"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      onChangeDate("start_date", value, dateType)
                    }
                    value={params.start_date}
                    format={customDateFormat}
                    picker={dateType}
                  />
                  <DatePicker
                    allowClear={false}
                    placeholder="Kết thúc"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      onChangeDate("end_date", value, dateType)
                    }
                    value={params.end_date}
                    format={customDateFormat}
                    picker={dateType}
                  />
                </Space>
                <Divider>Điều kiện truy vấn</Divider>
                <Form.Item label="Mã khách hàng" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Chọn mã khách hàng"
                    onChange={(value) =>
                      setParams({ ...params, customer_id: value })
                    }
                    optionFilterProp="label"
                    options={listCustomers.map((e) => ({
                      label: e.id,
                      value: e.id,
                    }))}
                  />
                </Form.Item>
                <Form.Item label="Khách hàng" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Chọn khách hàng"
                    onChange={(value) =>
                      setParams({ ...params, khach_hang: value })
                    }
                    optionFilterProp="label"
                    options={listCustomers}
                  />
                </Form.Item>
                <Form.Item label="Tên sản phẩm" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    onChange={(value) =>
                      setParams({ ...params, product_id: value })
                    }
                    placeholder="Nhập tên sản phẩm"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listNameProducts}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
        <Col className="custom-col-table" span={20}>
          <Card
            style={{ height: "100%" }}
            title="Đơn hàng"
            extra={
              <Space>
                <Upload
                  showUploadList={false}
                  customRequest={async ({ file }) => {
                    setLoadingUpload(true);
                    const formData = new FormData();
                    formData.append("file", file);
                    const res = await importProductOrders(formData);
                    loadListTable(params);
                    setLoadingUpload(false);
                  }}
                >
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    loading={loadingUpload}
                  >
                    Upload
                  </Button>
                </Upload>
                <Button type="primary" onClick={handleExportData}>
                  Xuất dữ liệu
                </Button>
              </Space>
            }
          >
            <Table
              size="small"
              bordered
              loading={loading}
              locale={{ emptyText: "" }}
              className="table-order"
              pagination={{
                current: page,
                pageSize: 20,
                size: "small",
                total: totalPage,
                onChange: (page, pageSize) => btn_click(page, pageSize),
              }}
              scroll={{ x: 1100, y: CalculateTableHeight("table-order") }}
              columns={columns}
              dataSource={data}
            />
          </Card>
        </Col>
      </Row>
      {/* Modal để cập nhật ngày PKHSX xác nhận */}
      <Modal
        title="Sửa ngày PKHSX xác nhận"
        open={openMdl}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Hủy</Button>,
          <Button type="primary" onClick={() => handleSaveInventory(1)}>
            Tạo lô mới
          </Button>,
          <Button type="primary" onClick={() => handleSaveInventory(2)}>
            Gom lô cũ
          </Button>,
          <Button type="primary" onClick={() => handleSaveInventory(3)}>
            Xác nhận đã có tồn
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="vertical">
          <Form.Item
            label="Ngày PKHSX xác nhận"
            name="confirm_date"
            rules={[{ required: true, message: "Vui lòng chọn ngày" }]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format="DD-MM-YYYY HH:mm"
              showTime
            />
          </Form.Item>
          <Form.Item
            label="Tồn kho"
            name="sl_ton"
            rules={[{ required: true, message: "Vui lòng nhập tồn kho" }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Divider>Chỉnh tồn công đoạn</Divider>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                className="mb-3"
                label="Gấp dán liên hoàn"
                name="gdlh"
                rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="In flexo"
                name="in"
                rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
                className="mb-3"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dán Liner"
                name="liner"
                rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
                className="mb-3"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Đục cắt"
                name="dc"
                rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
                className="mb-3"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Chon"
            name="chon"
            rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Orders;
