import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, DatePicker, Input, Space, Table } from 'antd';
import dayjs from "dayjs";
import axios from "axios";
import HeaderUI from "../../../layouts/HeaderUI";
const columns = [
  {
    title: "Mã đơn hàng",
    dataIndex: "product_order_id",
    key: "product_order_id",
    align: "left",
    fixed: "left",
    width: 120,
  },
  {
    title: "Mã lô",
    dataIndex: "lo_sx",
    key: "lo_sx",
    align: "left",
    fixed: "left",
    width: 120,
  },
  {
    title: "Tên sản phẩm",
    dataIndex: "product_name",
    key: "lo_sx",
    align: "left",
    fixed: "left",
    width: 120,
  },
  {
    title: "Mã máy",
    dataIndex: "machine_code",
    key: "machine_code",
    align: "left",
    width: 150,
  },
  {
    title: "Công đoạn",
    dataIndex: "line_name",
    key: "line_name",
    align: "left",
    width: 150,
  },
  {
    title: "Tổng sản lượng",
    dataIndex: "sum_san_luong",
    key: "sum_san_luong",
    align: "right",
    width: 210,
  },
  {
    title: "Tổng sl tem vàng",
    dataIndex: "sum_tem_vang",
    key: "sum_tem_vang",
    align: "right",
    width: 210,
  },
  {
    title: "Tổng NG",
    dataIndex: "sum_ng",
    key: "sum_ng",
    align: "right",
    width: 200,
  },
  {
    title: "Tổng thời gian sản xuất",
    dataIndex: "producton_time",
    key: "producton_time",
    align: "right",
    width: 200,
  },
];
const ProductionMonitor = (props) => {
  document.title = "Giám sát sản lượng";
  const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    setLoading(true);
    var res = await axios.get('production-monitor', { params: {...params, start_date: params.date[0].format('YYYY-MM-DD'), end_date: params.date[1].format('YYYY-MM-DD')} });
    setData(res.data);
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <React.Fragment>
      <div id="layout-wrapper" style={{ height: '100%', minHeight: '100vh' }}>
        <HeaderUI />

        <Card title={'Giám sát sản lượng'} extra={<Space>
          <Input allowClear placeholder='Mã đơn hàng' onBlur={(event) => setParams({ ...params, product_order_id: event.target.value })} onPressEnter={(event) => setParams({ ...params, product_order_id: event.target.value })} />
          <Input allowClear placeholder='Mã máy' onBlur={(event) => setParams({ ...params, machine_code: event.target.value })} onPressEnter={(event) => setParams({ ...params, machine_code: event.target.value })} />
          <Input allowClear placeholder='Tên sản phẩm' onBlur={(event) => setParams({ ...params, product_name: event.target.value })} onPressEnter={(event) => setParams({ ...params, product_name: event.target.value })} />
          <DatePicker.RangePicker onChange={(date) => setParams({ ...params, date })} />
          <Button type='primary' onClick={fetchData}>Tải dữ liệu</Button>
        </Space>}>
          <Table dataSource={data} columns={columns} bordered size='small' virtual={true} loading={loading} />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductionMonitor);
