import axios from "axios";
const prefix = 'p2/ui/';
//MaintenancePlans
export async function getMaintenancePlans(params) {
    const res = await axios.get(prefix+'maintenance-plans', { params });
    return res;
}
export async function createMaintenancePlans(params) {
    const res = await axios.post(prefix+'maintenance-plans', params);
    return res;
}
export async function updateMaintenancePlans(id, params) {
    const res = await axios.patch(prefix+'maintenance-plans' + id, params);
    return res;
}
export async function deleteMaintenancePlans(id) {
    const res = await axios.delete(prefix+'maintenance-plans/' + id);
    return res;
}
export async function exportMaintenancePlans(params) {
    const res = await axios.get(prefix+'maintenance-plans/export', { params });
    return res;
}
export async function getMaintenancePlansCalendarTable(params) {
    const res = await axios.get(prefix+'maintenance-plans/calendar-table', { params });
    return res;
}
export async function getMaintenancePlansDetail(params) {
    const res = await axios.get(prefix+'maintenance-plans/detail/list', { params });
    return res;
}
export async function getMaintenancePlansImport(params) {
    const res = await axios.post(prefix+'maintenance-plans/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}

//MaintenanceCategories
export async function getMaintenanceCategories(params) {
    const res = await axios.get(prefix+'maintenance-categories', { params });
    return res;
}
export async function createMaintenanceCategories(params) {
    const res = await axios.post(prefix+'maintenance-categories', params);
    return res;
}
export async function updateMaintenanceCategories(id, params) {
    const res = await axios.patch(prefix+'maintenance-categories' + id, params);
    return res;
}
export async function deleteMaintenanceCategories(id) {
    const res = await axios.delete(prefix+'maintenance-categories/' + id);
    return res;
}
export async function exportMaintenanceCategories(params) {
    const res = await axios.get(prefix+'maintenance-categories/export', { params });
    return res;
}
//MaintenanceItems
export async function getMaintenanceItems(params) {
    const res = await axios.get(prefix+'maintenance-items', { params });
    return res;
}
export async function createMaintenanceItems(params) {
    const res = await axios.post(prefix+'maintenance-items', params);
    return res;
}
export async function updateMaintenanceItems(id, params) {
    const res = await axios.patch(prefix+'maintenance-items' + id, params);
    return res;
}
export async function deleteMaintenanceItems(id) {
    const res = await axios.delete(prefix+'maintenance-items/' + id);
    return res;
}
export async function exportMaintenanceItems(params) {
    const res = await axios.get(prefix+'maintenance-items/export', { params });
    return res;
}

//MaintenanceSchedules
export async function getMaintenanceSchedules(params) {
    const res = await axios.get(prefix+'maintenance-schedules', { params });
    return res;
}
export async function createMaintenanceSchedules(params) {
    const res = await axios.post(prefix+'maintenance-schedules', params);
    return res;
}
export async function updateMaintenanceSchedules(id, params) {
    const res = await axios.patch(prefix+'maintenance-schedules/' + id, params);
    return res;
}
export async function deleteMaintenanceSchedules(id) {
    const res = await axios.delete(prefix+'maintenance-schedules/' + id);
    return res;
}
export async function exportMaintenanceSchedules(params) {
    const res = await axios.get(prefix+'maintenance-schedules/export', { params });
    return res;
}

//MaintenanceLogs
export async function getMaintenanceLogs(params) {
    const res = await axios.get(prefix+'maintenance-logs', { params });
    return res;
}
export async function createMaintenanceLogs(params) {
    const res = await axios.post(prefix+'maintenance-logs', params, {headers: { "Content-Type": "multipart/form-data" }});
    return res;
}
export async function updateMaintenanceLogs(id, params) {
    const res = await axios.post(prefix+'maintenance-logs/' + id, params, {headers: { "Content-Type": "multipart/form-data" }});
    return res;
}
export async function deleteMaintenanceLogs(id) {
    const res = await axios.delete(prefix+'maintenance-logs/' + id);
    return res;
}
export async function exportMaintenanceLogs(params) {
    const res = await axios.get(prefix+'maintenance-logs/export', { params });
    return res;
}
export async function completeAllSelectedMaintenanceDetail(params) {
    const res = await axios.post(prefix+'maintenance-log/complete-all', params);
    return res;
}
export async function uploadMaintenanceLogImages(params) {
    const res = await axios.post(prefix+'maintenance-log-images/upload', params, {headers: { "Content-Type": "multipart/form-data" }});
    return res;
}
//MaintenanceLogImages
export async function getMaintenanceLogImages(params) {
    const res = await axios.get(prefix+'maintenance-log-images', { params });
    return res;
}
export async function createMaintenanceLogImages(params) {
    const res = await axios.post(prefix+'maintenance-log-images', params);
    return res;
}
export async function updateMaintenanceLogImages(id, params) {
    const res = await axios.patch(prefix+'maintenance-log-images/' + id, params);
    return res;
}
export async function deleteMaintenanceLogImages(id) {
    const res = await axios.delete(prefix+'maintenance-log-images/' + id);
    return res;
}



