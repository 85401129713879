import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Divider,
  Button,
  Table,
  Modal,
  Select,
  Form,
  InputNumber,
  Spin,
  message,
  Popconfirm,
} from "antd";
import "../style.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ScanButton from "../../../components/Button/ScanButton";
import SelectButton from "../../../components/Button/SelectButton";
import { getLineUser } from "../../../api/oi/manufacture";
import Tem from "../../UI/Manufacture/Tem";
import TemChon from "./TemChon";
import { useReactToPrint } from "react-to-print";
import {
  createAssignment,
  getAssignment,
  getLineList,
  getMachineList,
  // getLotProductionList,
  getProductionOverall,
  printTemSelectionLine,
  scanForSelectionLine,
  updateAssignment,
  updateOutputProduction,
  getInfoPrintSelection,
} from "../../../api/phase2/oi/manufacture";
import {
  endOfProduction,
  getLotProductionList,
  scanForSelectionLineV2,
} from "../../../api/phase2/oi/manufactureV2";
import DataDetail2 from "../../../components/DataDetail2";
import { type } from "@testing-library/user-event/dist/type";

const columns = [
  {
    title: "STT",
    dataIndex: "index",
    key: "index",
    align: "center",
    render: (value, record, index) => index + 1,
  },
  {
    title: "Lô sản xuất",
    dataIndex: "lo_sx",
    key: "lo_sx",
    align: "center",
  },
  {
    title: "Mã NVL vào",
    dataIndex: "input_lot_id",
    key: "input_lot_id",
    align: "center",
  },
  {
    title: "Mã Pallet/cuộn",
    dataIndex: "lot_id",
    key: "lot_id",
    align: "center",
  },
  {
    title: "Tên sản phẩm",
    dataIndex: "ten_sp",
    key: "ten_sp",
    align: "center",
  },
  {
    title: "Mã hàng",
    dataIndex: "ma_hang",
    key: "ma_hang",
    align: "center",
  },
  {
    title: "TG bắt đầu (KH)",
    dataIndex: "thoi_gian_bat_dau_kh",
    key: "thoi_gian_bat_dau_kh",
    align: "center",
  },
  {
    title: "TG kết thúc (KH)",
    dataIndex: "thoi_gian_ket_thuc_kh",
    key: "thoi_gian_ket_thuc_kh",
    align: "center",
  },
  {
    title: "TG bắt đầu (TT)",
    dataIndex: "thoi_gian_bat_dau",
    key: "thoi_gian_bat_dau",
    align: "center",
  },
  {
    title: "TG kết thúc (TT)",
    dataIndex: "thoi_gian_ket_thuc",
    key: "thoi_gian_ket_thuc",
    align: "center",
  },
  {
    title: "SL đầu vào (KH)",
    dataIndex: "sl_dau_ra_kh",
    key: "sl_dau_vao_kh",
    align: "center",
  },
  {
    title: "SL đầu ra (KH)",
    dataIndex: "sl_dau_ra_kh",
    key: "sl_dau_ra_kh",
    align: "center",
  },
  {
    title: "SL đầu vào (TT)",
    dataIndex: "sl_dau_vao_hang_loat",
    key: "sl_dau_vao",
    align: "center",
  },
  {
    title: "SL đầu ra (TT)",
    dataIndex: "sl_dau_ra_hang_loat",
    key: "sl_dau_ra",
    align: "center",
  },
  {
    title: "SL đầu ra (TT OK)",
    dataIndex: "sl_dau_ra_ok",
    key: "sl_dau_ra_ok",
    align: "center",
  },
  {
    title: "SL tem vàng",
    dataIndex: "sl_tem_vang",
    key: "sl_tem_vang",
    align: "center",
    className: "yellow",
  },
  {
    title: "Số lượng NG",
    dataIndex: "sl_tem_ng",
    key: "sl_tem_ng",
    align: "center",
    className: "red",
  },
  {
    title: "Tỉ lệ HT",
    dataIndex: "ti_le_ht",
    key: "ti_le_ht",
    align: "center",
  },
];
const defaultRow1 = [
  {
    title: "SL KH ngày",
    key: "tong_sl_trong_ngay_kh",
  },
  {
    title: "SL T.Tế",
    key: "tong_sl_thuc_te",
  },
  {
    title: "SL Tem vàng",
    key: "tong_sl_tem_vang",
    bg: "#f7ac27",
  },
  {
    title: "SL NG",
    key: "tong_sl_ng",
    bg: "#fb4b50",
  },
  {
    title: "Tỷ lệ hoàn thành (%)",
    key: "ty_le_hoan_thanh",
  },
];

const P2ManufactureSelection = (props) => {
  document.title = "Sản xuất";
  const { line } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [options, setOption] = useState([]);
  const [data, setData] = useState([]);
  const [remain, setRemain] = useState(0);
  const [oldQuantity, setOldQuantity] = useState(0);
  const [formAssign] = Form.useForm();
  const [formPrint] = Form.useForm();
  const [listCheck, setListCheck] = useState([]);
  const [openPrint, setOpenPrint] = useState(false);
  const [selectedLot, setSelectedLot] = useState();
  const [dataOverall, setDataOverall] = useState();
  const [openAssign, setOpenAssign] = useState(false);
  const [openAssignTable, setOpenAssignTable] = useState(false);
  const [machineOptions, setMachineOption] = useState([]);
  const [isMdlEnterOuput, setIsMdlEnterOuput] = useState(false);
  const [formEnterOuput] = Form.useForm();
  const [infoPrint, setInfoPrint] = useState([]);
  const machine =
    new URLSearchParams(window.location.search).get("machine") || "";
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const fetchLine = async () => {
    const lineList = await getLineList({ type: "sx" });
    setOption(lineList.data);
  };
  const fetchOverall = async () => {
    const lineOverall = await getProductionOverall({ line_id: line, machine_code: machine });
    setDataOverall(lineOverall.data);
  };
  const fetchLotDataTable = async () => {
    setLoading(true);
    const infoPallet = await getLotProductionList({
      line_id: line,
      machine_code: machine,
    });
    if (infoPallet.success) {
      setData(
        (infoPallet.data ?? []).map((e, i) => {
          if (e.status == 1) {
            setSpecifiedRowKey(i);
          }
          return { ...e, key: i };
        })
      );
      setSelectedLot(infoPallet.data.find((e) => e.status === 1));
    } else {
      setSelectedLot();
    }
    setListCheck([]);
    setLoading(false);
  };
  const onChangeMachine = (value) => {
    redirectBetweenView(machineOptions, value);
  };
  const redirectBetweenView = (machineOptions, machine) => {
    if (machineOptions.length) {
      if (!machine) {
        history.push({ search: "?machine=" + machineOptions[0]?.value });
      } else {
        const target = machineOptions.find((e) => e.value === machine);
        // if (!target?.is_iot) {
        //   history.push({
        //     pathname: "/manual-manufacture/" + line,
        //     search: "?machine=" + target?.value,
        //   });
        // } else {
        history.push({
          pathname: "/manufacture/" + line,
          search: "?machine=" + target?.value,
        });
        // }
      }
    }
  };
  const fetchWorkers = async () => {
    const workers = await getLineUser({ line_id: line });
    setUsers(workers.data.map((e) => ({ ...e, label: e.name, value: e.id })));
  };
  const fetchAssignment = async () => {
    const assignment = await getAssignment({ lot_id: selectedLot?.lot_id });
    setAssignData(assignment.data);
    formConfirm.setFieldsValue(assignment.data[0] ?? null);
  };
  useEffect(() => {
    fetchLine();
    fetchWorkers();
  }, []);
  useEffect(() => {
    setSpecifiedRowKey(null);
    if (line && machine) {
      fetchOverall();
      fetchLotDataTable();
    }
  }, [line, machine]);
  useEffect(() => {
    redirectBetweenView(machineOptions, machine);
  }, [machineOptions]);
  useEffect(() => {
    if (selectedLot && (openAssign || openAssignTable)) {
      fetchAssignment();
    }
  }, [openAssign, openAssignTable]);
  const tableRef = useRef();
  const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
  const handleScrollToRow = () => {
    if (specifiedRowKey !== null && tableRef.current) {
      tableRef.current?.scrollTo({ key: specifiedRowKey });
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      handleScrollToRow();
    }
  }, [specifiedRowKey]);
  useEffect(() => {
    if (listCheck.length > 0) {
      print();
    }
  }, [listCheck]);
  const print = useReactToPrint({
    content: () => componentRef1.current,
  });
  const printChon = useReactToPrint({
    content: () => componentRef2.current,
  });
  const onChangeLine = (value) => {
    history.push("/manufacture/" + value);
  };
  const onScan = async (result) => {
    // const selectedInfo = data.find((e) => e.status === 0);
    // if (!selectedInfo || data.length === 0) {
    //   message.error("Không tìm thấy lô sản xuất nào cần chạy");
    //   return;
    // }
    var res = await scanForSelectionLineV2({
      scanned_lot: result,
      line_id: line,
      machine_code: machine,
      // lot_id: selectedInfo.lot_id,
    });
    if (res.success) {
      fetchLotDataTable();
    }
  };

  const resultColumn = [
    {
      title: "Công nhân",
      dataIndex: "worker",
      key: "worker",
      align: "center",
      render: (value) => value.name,
    },
    {
      title: "Số lượng đầu vào giao việc",
      dataIndex: "assigned_quantity",
      key: "assigned_quantity",
      align: "center",
    },
    {
      title: "Số lượng TT đầu vào chọn",
      dataIndex: "actual_quantity",
      key: "actual_quantity",
      align: "center",
      render: (value, record, index) => (
        <Form.Item name={`actual_quantity`} noStyle>
          <InputNumber inputMode="numeric" min={0} />
        </Form.Item>
      ),
    },
    {
      title: "Số lượng kiểm tra TT OK",
      dataIndex: "ok_quantity",
      key: "ok_quantity",
      align: "center",
      render: (value, record, index) => (
        <Form.Item name={`ok_quantity`} noStyle>
          <InputNumber
            inputMode="numeric"
            min={0}
            // disabled={record.ok_quantity}
          />
        </Form.Item>
      ),
    },
  ];

  const [formConfirm] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [assignData, setAssignData] = useState([]);
  useEffect(() => {
    counter.current = 0;
    if (!selectedLot) {
      setListCheck([]);
    }
  }, [selectedLot]);
  const onAssign = async (values) => {
    console.log(values);
    if (selectedLot) {
      var res = await createAssignment({
        line_id: line,
        lot_id: selectedLot?.lot_id,
        worker_id: values.worker_id,
        assigned_quantity: selectedLot?.sl_dau_vao_hang_loat,
        actual_quantity: selectedLot?.sl_dau_vao_hang_loat,
      });
      if (res.success) {
        fetchAssignment();
        fetchLotDataTable();
        setOpenAssign(false);
      }
      formAssign.resetFields();
    }
  };

  const rowClassName = (record, index) => {
    switch (record.status) {
      case 1:
        return "table-row-green";
        break;
      case 2:
        return "table-row-grey";
        break;
      default:
        return "";
        break;
    }
  };

  const counter = useRef(0);
  const handleOpenAssign = () => {
    if (selectedLot) {
      if (!selectedLot.is_assign) {
        setOpenAssign(true);
      }
    }
  };
  const defaultRow2 = [
    {
      title: "Mã thùng",
      key: "lot_id",
    },
    {
      title: "Tên sản phẩm",
      key: "ten_sp",
    },
    {
      title: "UPH (Ấn định)",
      key: "uph",
    },
    {
      title: "UPH (Thực tế)",
      key: "uph_tt",
    },
    {
      title: "SL đầu ra (KH)",
      key: "sl_dau_ra_kh",
    },
    {
      title: "SL đầu ra (TT)",
      key: "sl_dau_ra_hang_loat",
    },
    {
      title: "SL đầu ra (TT OK)",
      key: "sl_dau_ra_ok",
      onClick: () => {
        handleOpenAssign();
      },
    },
  ];
  useEffect(() => {
    formAssign.resetFields();
  }, [openAssign]);

  const onFinishPrint = async (values) => {
    if (
      values.sl_in_tem >
      parseInt(infoPrint?.sl_ok) + parseInt(infoPrint?.sl_ton)
    ) {
      message.error("Số lượng in tem phải nhỏ hơn số lượng OK + số lượng tồn");
    } else {
      console.log(values);
      const res = await printTemSelectionLine({
        lot_id: selectedLot.lot_id,
        line_id: line,
        sl_in_tem: values.sl_in_tem,
        type: values.type,
      });
      if (res.success) {
        setListCheck(res.data);
        setOpenPrint(false);
        fetchLotDataTable();
        formPrint.resetFields();
      }
    }
  };
  const openMdlPrint = async () => {
    if (selectedLot) {
      fetchAssignment();
      const res = await getInfoPrintSelection({
        lot_id: selectedLot.lot_id,
        lo_sx: selectedLot.lo_sx,
      });
      if (res.success) {
        setInfoPrint(res.data);
      }
      setOpenPrint(true);
    } else {
      message.info("Chưa chọn mã");
    }
  };
  useEffect(() => {
    fetchMachine();
  }, [line]);
  const fetchMachine = async () => {
    const machineList = await getMachineList({ line });
    setMachineOption(
      machineList.data.map((e) => ({ ...e, value: e.code, label: e.code }))
    );
  };

  const confirmResult = async (values) => {
    if (assignData.length < 0) {
      message.error("Chưa có dữ liệu");
      return;
    }
    var res = await updateAssignment(assignData[0]?.id, {
      ...assignData[0],
      ...values,
    });
    if (res.success) {
      fetchAssignment();
      fetchLotDataTable();
      setOpenAssignTable(false);
    }
  };

  const confirmOuput = async (values) => {
    values.lot_id = selectedLot.lot_id;
    values.line_id = line;
    values.machine_code = machine;
    const res = await updateOutputProduction(values);
    if (res.success) {
      fetchLotDataTable();
      setIsMdlEnterOuput(false);
    }
  };
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Row className="mt-3" gutter={[8, 12]}>
          <Col span={4}>
            <SelectButton
              value={options.length > 0 && parseInt(line)}
              options={options}
              label="Chọn công đoạn"
              onChange={onChangeLine}
            />
          </Col>
          <Col span={4}>
            <SelectButton
              value={
                machineOptions.length > 0 &&
                machineOptions.some((e) => e.value == machine)
                  ? machine
                  : null
              }
              options={machineOptions}
              label="Chọn thiết bị"
              onChange={onChangeMachine}
              allowClear={true}
            />
          </Col>
          <Col span={16}>
            <DataDetail2 data={defaultRow1} value={dataOverall} />
          </Col>
          <Col span={24}>
            <ScanButton
              onScan={onScan}
              placeholder={"Quét mã QR hoặc nhập mã thùng"}
            />
          </Col>
          <Col span={20}>
            <DataDetail2 data={defaultRow2} value={selectedLot} />
          </Col>
          <Col span={4}>
            <Button
              size="large"
              type="primary"
              style={{
                height: "45%",
                width: "100%",
                whiteSpace: "normal",
                marginBottom: "6px",
              }}
              onClick={openMdlPrint}
            >
              Nhập số lượng in tem
            </Button>
            <Button
              size="large"
              type="primary"
              style={{ height: "45%", width: "100%", whiteSpace: "normal" }}
              onClick={printChon}
            >
              In tem
            </Button>
            <div className="report-history-invoice">
              <Tem ref={componentRef1} listCheck={listCheck} />
              <TemChon listCheck={listCheck} ref={componentRef2} />
            </div>
          </Col>
          <Col span={24}>
            <Table
              scroll={{
                x: 200,
                y: 350,
              }}
              size="small"
              rowClassName={rowClassName}
              pagination={false}
              bordered
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => onClickRow(record),
              //   };
              // }}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
        <Modal
          title="Giao việc"
          open={openAssign}
          width={600}
          onCancel={() => setOpenAssign(false)}
          onOk={() => {
            formAssign.submit();
          }}
        >
          <Form
            form={formAssign}
            layout={"vertical"}
            onFinish={onAssign}
            labelCol={{ span: 6 }}
            labelAlign="left"
            initialValues={{
              table_id: "",
              user_id: "",
            }}
          >
            <Divider
              style={{ marginTop: "0px", marginBottom: "10px" }}
              orientation="left"
            ></Divider>
            <Form.Item name={"worker_id"} label={"Người phụ trách"}>
              <Select options={users} showSearch optionFilterProp="label" />
            </Form.Item>
          </Form>
          <h6>
            <span className="float-left">
              Số lượng giao: {selectedLot?.sl_dau_vao_hang_loat}
            </span>
          </h6>
        </Modal>
        <Modal
          title={"Ghi nhận kết quả"}
          open={openAssignTable}
          width={800}
          onCancel={() => setOpenAssignTable(false)}
          onOk={() => {
            formConfirm.submit();
          }}
          cancelText="Đóng"
        >
          <Form form={formConfirm} onFinish={confirmResult}>
            <Table
              pagination={false}
              bordered
              columns={resultColumn}
              dataSource={assignData}
              size="small"
            />
          </Form>
        </Modal>
        <Modal
          title={"Nhập số lượng đầu ra"}
          open={isMdlEnterOuput}
          width={500}
          footer={null}
          onCancel={() => setIsMdlEnterOuput(false)}
        >
          <Form
            form={formEnterOuput}
            onFinish={confirmOuput}
            layout={"vertical"}
          >
            <Form.Item
              name="output"
              label="Số lượng đầu ra thực tế"
              width={"100%"}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType={"submit"}>
                Lưu lại
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={"Nhập số lượng in tem"}
          open={openPrint}
          width={600}
          onCancel={() => setOpenPrint(false)}
          footer={null}
        >
          <Divider className="mb-3 mt-1" />
          <p>Số lượng OK: {infoPrint?.sl_ok}</p>
          <p>Số lượng tồn: {infoPrint?.sl_ton}</p>
          <Form layout="vertical" onFinish={onFinishPrint} form={formPrint}>
            <Form.Item label="" name="sl_in_tem">
              <InputNumber
                placeholder="Nhập số lượng in tem"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="type" hidden>
              <input type="hidden" />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: "10px" }}
                htmlType="submit"
                type="primary"
                onClick={() => {
                  formPrint.setFieldsValue({ type: 1 });
                }}
              >
                In hết tem
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                htmlType="submit"
                type="primary"
                onClick={() => {
                  formPrint.setFieldsValue({ type: 2 });
                }}
              >
                In tem chẵn
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  formPrint.setFieldsValue({ type: 3 });
                }}
              >
                Kết thúc không in tem
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </React.Fragment>
  );
};

export default P2ManufactureSelection;
