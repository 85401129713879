import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Popconfirm, Row, Space, Table, Tag } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getListProductPlan, updatePlanStatusFromOI } from "../../api";

const PlanControlTable = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        runChildFunction() {
            fetchPlans();
        }
    }));
    const { machineId, lineId, onComplete } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: "Tên sản phẩm",
            key: "ten_san_pham",
            dataIndex: "ten_san_pham",
            align: 'center',
        },
        {
            title: "UPH (Ấn định)",
            key: "UPH",
            dataIndex: "UPH",
            align: 'center',
        },
        {
            title: "UPH (Thực tế)",
            key: "uph_thuc_te",
            dataIndex: "uph_thuc_te",
            align: 'center',
        },
        {
            title: "SL đầu ra (KH)",
            key: "sl_giao_sx",
            dataIndex: "sl_giao_sx",
            align: 'center',
        },
        {
            title: "SL đầu ra (TT)",
            key: "sl_dau_ra",
            dataIndex: "sl_dau_ra",
            align: 'center',
        },
        {
            title: "SL đầu ra (TT OK)",
            key: "sl_dau_ra_ok",
            dataIndex: "sl_dau_ra_ok",
            align: 'center',
        },
        {
            title: "Hao phí sản xuất",
            key: "hao_phi",
            dataIndex: "hao_phi",
            align: 'center',
        },
        {
            title: "Định mức hao phí công đoạn",
            key: "hao_phi_cong_doan",
            dataIndex: "hao_phi_cong_doan",
            align: 'center',
            width: 200
        },
        {
            title: "Trạng thái",
            dataIndex: "status_plan",
            key: "status_plan",
            align: "center",
            width: "110px",
            render: (value) => renderStatus(value),
        },
        {
            title: "Thao tác",
            dataIndex: "action",
            align: "center",
            width: 95,
            render: (_, record) => (
                <Space wrap>
                    {record?.status_plan === 1 && (
                        <Popconfirm
                            title="Xác nhận dừng?"
                            onConfirm={() => updateStatusPlan(record?.id, 3)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Button
                                className="edit-btn"
                                type="link"
                                size="small"
                                icon={<PauseOutlined />}
                            />
                        </Popconfirm>
                    )}
                    {record?.status_plan !== 1 && (
                        <Popconfirm
                            title="Xác nhận sản xuất?"
                            onConfirm={() => updateStatusPlan(record?.id, 1)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Button
                                className="edit-btn"
                                type="link"
                                size="small"
                                icon={<CaretRightOutlined />}
                            />
                        </Popconfirm>
                    )}
                </Space>
            ),
        },
    ];
    const renderStatus = (value) => {
        switch (value) {
            case 1:
                return <Tag color="#1677ff">Đang thực hiện</Tag>;
            case 2:
                return <Tag color="#3a851f">Hoàn thành</Tag>;
            case 3:
                return <Tag color="#ff4d4f">Đã dừng</Tag>;
            case 4:
                return <Tag color="#59554ee0">Đã hủy</Tag>;
            default:
                return <Tag color="#faad14">Đang chờ</Tag>;
        }
    };
    const rowClassName = (record, index) => {
        switch (record.status_plan) {
            // case 3:
            //     return "table-row-yellow";
            //     break;
            case 1:
                return "table-row-green";
                break;
            default:
                return "";
                break;
        }
    };
    const fetchPlans = async () => {
        setLoading(true);
        const res = await getListProductPlan({ machine_code: machineId, line_id: lineId });
        setData((res ?? []).sort((a, b) => {
            return (b.status_plan === 1 ? 1 : 0) - (a.status_plan === 1 ? 1 : 0);
        }));
        setLoading(false);
    }
    useEffect(() => {
        if(lineId && machineId){
            fetchPlans();
        }
    }, [lineId, machineId]);
    const updateStatusPlan = async (plan_id, status) => {
        const params = data.map(e => {
            // Nếu status là 1 và bản ghi có id = plan_id, cập nhật status_plan thành 1
            if (status === 1 && e.id === plan_id) {
                return { plan_id: e.id, status_plan: 1 };
            }
        
            // Nếu status là 1, các bản ghi khác có status_plan = 1 sẽ được cập nhật thành 3
            if (status === 1 && e.status_plan === 1) {
                return { plan_id: e.id, status_plan: 3 };
            }
        
            // Nếu status khác 1, chỉ cập nhật bản ghi có id = plan_id, giữ nguyên các bản ghi khác
            if (status !== 1 && e.id === plan_id) {
                return { plan_id: e.id, status_plan: status };
            }
        
            // Các bản ghi còn lại giữ nguyên
            return { plan_id: e.id, status_plan: e.status_plan };
        });
        var res = await updatePlanStatusFromOI(params);
        fetchPlans();
        onComplete && onComplete();
    }


    return (
        <Table
            dataSource={data}
            columns={columns}
            bordered
            size="small"
            pagination={false}
            scroll={{ y: '100px' }}
            rowClassName={rowClassName}
        ></Table>
    )
});

export default PlanControlTable;