import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getMachineProductionMode(params) {
    const res = await axios.get(prefix+'machine-production-mode', { params });
    return res;
}
export async function createMachineProductionMode(params) {
    const res = await axios.post(prefix+'machine-production-mode', params);
    return res;
}
export async function updateMachineProductionMode(id, params) {
    const res = await axios.patch(prefix+'machine-production-mode/' + id, params);
    return res;
}
export async function deleteMachineProductionMode(id) {
    const res = await axios.delete(prefix+'machine-production-mode/' + id);
    return res;
}
export async function deleteManyMachineProductionMode(ids) {
    const res = await axios.post(prefix+'machine-production-mode/delete', {ids});
    return res;
}
export async function exportMachineProductionMode(params) {
    const res = await axios.get(prefix+'machine-production-mode/export', { params });
    return res;
}
export async function importMachineProductionMode(params) {
    const res = await axios.post(prefix+'machine-production-mode/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}
export async function saveAllMachineProductionMode(params) {
    const res = await axios.post(prefix+'machine-production-mode/save-all', params);
    return res;
}