const manufatureQuicker = [
    { path: '/ui/manufacture/order', name: 'Đơn hàng' },
    { path: '/ui/manufacture/production-order-priorities', name: 'Thứ tự đơn hàng' },
    { path: '/ui/manufacture/load-factor', name: 'Hệ số tải' },
    // { path: '/ui/manufacture/ke-hoach-san-xuat', name: 'Danh sách lô' },
    // { path: '/ui/manufacture/khsx-auto', name: 'Danh sách lot' },
    { path: '/ui/manufacture/fc', name: 'FC' },
    { path: '/ui/manufacture/lenh-giao-hang', name: 'Lệnh giao hàng' },
    
];

const maintenanceQuicker = [
    { path: '/ui/equipment/maintenance', name: 'Kế hoạch thực hiện BTBD' },
    { path: '/ui/equipment/detail-maintenance', name: 'Chi tiết thực hiện kiểm tra' },
];

export { manufatureQuicker, maintenanceQuicker };