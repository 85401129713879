import { Form, Modal, Row, Col, Button, Divider, Radio, Space, InputNumber, Input, Table, message } from 'antd';
import React, { useState } from 'react';
import './popupStyle.scss'
import ScanButton from '../Button/ScanButton';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { getChecksheetList, scanError, sendResultError } from '../../api/oi/quality';
import { updateErrorLog } from '../../api/phase2/oi/quality';
import { changeStatusNgTracking, checkNgTracking, findError, getLotErrorLogList, getNGTrackingResultList, startNgTracking, stopNgTracking, updateLotErrorLog } from '../../api/phase2/oi/manufacture';
import Title from 'antd/es/typography/Title';
const PopupNGTracking = (props) => {
    const { line } = useParams();
    const { text = "", lotId, onSubmit = null, data = [], infoCongDoanId } = props;
    const closeModal = () => {
        setOpen(false);
        form.resetFields();
    }
    const [form] = Form.useForm();
    const machine = (new URLSearchParams(window.location.search).get('machine') || '');
    const [errorsList, setErrorsList] = useState(data);
    const [errorsListHistory, setErrorsListHistory] = useState([]);
    const [isNGRecording, setIsNGRecording] = useState(false);
    const [NGQuantity, setNGQuantity] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const [NGTracking, setNGTracking] = useState();

    const onScan = async (result) => {
        var res = await findError({ error_id: result });
        if (res.success) {
            const formData = form.getFieldValue('log');
            if (formData.every(e => e?.id?.toLowerCase() !== result?.toLowerCase())) {
                console.log(res.data);
                var data = null;
                if (formData.length === 0) {
                    data = { ...res.data, value: form.getFieldValue('ng_quantity') };
                } else {
                    data = res.data;
                }
                if (data) {
                    const newErrorsList = [...formData, data];
                    // setErrorsList(newErrorsList)
                    form.setFieldValue('log', newErrorsList);
                }
            }
        }
    }
    const [open, setOpen] = useState(false);

    const save_type = Form.useWatch('save_type', form);
    const fetchErrorHistory = async () => {
        if (lotId) {
            var res = await getNGTrackingResultList({info_cong_doan_id: infoCongDoanId});
            if (res.success) {
                setErrorsListHistory(res.data.errorList ?? []);
                // form.setFieldValue('log', res.data.errorList ?? []);
            }
        }
    }

    const onFinish = async (values) => {
        console.log(values);

        var totalNGQuantity = 0;
        (values.log ?? []).forEach(element => {
            totalNGQuantity += element.value;
        });
        if ((values.log ?? []).length <= 0) {
            message.error('Không có lỗi nào được phát hiện');
            return;
        }
        if (totalNGQuantity !== form.getFieldValue('ng_quantity')) {
            message.error('Số lượng lỗi nhập phải bằng số lượng ghi nhận');
            return;
        }
        if(lotId){
            try {
                var res;
                const newLog = (values.log ?? []).reduce((a, v) => ({ ...a, [v.id]: v.value }), {});
                if (values.save_type === 'lot_error_log') {
                    const params = {
                        lot_id: lotId,
                        line_id: line,
                        machine_code: machine,
                        log: newLog
                    }
                    res = await saveToLotErrorLog(params);
                } else if (values.save_type === 'ng') {
                    const params = {
                        lot_id: lotId,
                        line_id: line,
                        machine_code: machine,
                        data: newLog,
                    }
                    res = await saveToErrorHistory(params);
                } else if (values.save_type === 'ng_and_lot_error_log'){
                    const dau_noi_params = {
                        lot_id: lotId,
                        line_id: line,
                        machine_code: machine,
                        log: {IN13: 0}//Nếu là lựa chọn thứ 3 thì lưu cả ng và dấu nối, dấu nối sẽ có lỗi là "Dấu nối" (IN13), sl = 0
                    }
                    res = await saveToLotErrorLog(dau_noi_params);

                    const ng_params = {
                        lot_id: lotId,
                        line_id: line,
                        machine_code: machine,
                        data: newLog,
                    }
                    res = await saveToErrorHistory(ng_params);
                }
                if (res?.success) {
                    closeModal();
                    completeTracking();
                    onSubmit && onSubmit();
                }
            } catch (error) {
               console.error(error); 
            }
            
        }
    }

    useEffect(() => {
        if (open) {
            fetchErrorHistory();
        }
    }, [open]);

    const onNGRecord = async () => {
        if (!infoCongDoanId) {
            message.error('Chưa có lot đang chạy')
            return;
        }
        setBtnLoading(true);
        if (!isNGRecording) {
            await startTracking();
        } else {
            await stopTracking();
        }
        setBtnLoading(false);
    }

    const startTracking = async () => {
        var res = await changeStatusNgTracking({ info_cong_doan_id: infoCongDoanId, status: 1 });
        if (res.success) {
            setIsNGRecording(res.data?.status === 1);
        }
    }

    const stopTracking = async () => {
        var res = await changeStatusNgTracking({ info_cong_doan_id: infoCongDoanId, status: 2 });
        if (res.success) {
            setNGQuantity(res?.data?.ng_quantity ?? 0);
            form.setFieldValue('ng_quantity', res?.data?.ng_quantity);
            setOpen(true);
            setIsNGRecording(res.data?.status === 1);
        }
    }

    const resumeTracking = async () => {
        var res = await changeStatusNgTracking({ info_cong_doan_id: infoCongDoanId, status: 1 });
        if (res.success) {
            closeModal();
            setIsNGRecording(res.data?.status === 1);
        }
    }

    const completeTracking = async () => {
        var res = await changeStatusNgTracking({ info_cong_doan_id: infoCongDoanId, status: 3 });
        if (res.success) {
            closeModal();
            setIsNGRecording(res.data?.status === 1);
        }
    }

    const onCheckNgTracking = async () => {
        setBtnLoading(true);
        var res = await checkNgTracking({ info_cong_doan_id: infoCongDoanId });
        if (res.success) {
            setNGTracking(res.data);
        }
        setBtnLoading(false);
    }

    useEffect(() => {
        if (infoCongDoanId) {
            onCheckNgTracking();
        }
    }, [infoCongDoanId]);

    useEffect(() => {
        if (NGTracking) {
            setIsNGRecording(NGTracking.status === 1);
        } else {
            setIsNGRecording(false);
        }
    }, [NGTracking]);

    const errorLogColumns = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: 50,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Lưu thành",
            dataIndex: "type",
            key: "type",
            align: "center",
            width: 90,
        },
        {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: 90,
        },
        {
            title: "Người nhập",
            dataIndex: "user_name",
            key: "user_name",
            align: "center",
            width: 150,
        },
        {
            title: "Thời gian ghi nhận",
            dataIndex: "date",
            key: "date",
            align: "center",
            width: 150,
        },
    ];

    const detailErrorColumns = [
        {
            title: "Mã lỗi",
            dataIndex: "error_id",
            key: "error_id",
            align: "center",
            width: 50,
        },
        {
            title: "Số lỗi",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: 50,
        },
    ];

    const expandedTable = (data) => (
        <Table columns={detailErrorColumns} dataSource={data} pagination={false} size='small' bordered style={{ marginTop: 4, marginBottom: 4 }} />
    );

    const saveToErrorHistory = async (params) => {
        console.log(params);
        const res = await updateErrorLog(params);
        return res;
    }

    const saveToLotErrorLog = async (params) => {
        console.log(params);
        const res = await updateLotErrorLog(params);
        return res;
    }

    return (
        <React.Fragment>
            <Button type="primary" style={{ height: "100%", width: "100%" }} onClick={onNGRecord} disabled={!infoCongDoanId}>
                {isNGRecording ? "⏸ Dừng ghi nhận sl lỗi" : "▶ "+ (NGTracking?.status === 2 ? "Tiếp tục" : "Bắt đầu") + " ghi nhận sl lỗi"}
            </Button>
            <Modal
                title="Ghi nhận sản lượng lỗi"
                open={open}
                onCancel={closeModal}
                footer={null}
                width={700}
                maskClosable={false}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        log: [],
                        save_type: 'ng'
                    }}
                    colon={false}
                // {...formItemLayout}
                >
                    <Title level={5}>Số lượng lỗi hiện tại: {form.getFieldValue('ng_quantity')}</Title>

                    <Form.Item label="Lưu thành: " name={"save_type"} className='mb-2'>
                        <Radio.Group>
                            <Radio value="ng"> Lỗi NG </Radio>
                            <Radio value="lot_error_log"> Dấu nối </Radio>
                            <Radio value="ng_and_lot_error_log"> Lỗi NG và Dấu nối </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <ScanButton placeholder={'Nhập mã lỗi hoặc quét mã QR'} onScan={onScan} />
                    <Form.List name={'log'}>
                        {(fields, { add, remove }, { errors }) =>
                            fields.map((e, index) => {
                                return (
                                    <Row gutter={8} className={'mt-2'}>
                                        <Col span={12} style={{ paddingInline: 4 }} className='d-flex justify-content-center flex-wrap align-items-lg-center'>
                                            <div className='d-flex justify-content-center align-content-center flex-grow-1 align-items-lg-center' style={{ backgroundColor: '#EBEBEB', height: '100%' }}>
                                                {form.getFieldValue('log').find((e, i) => i === index)?.noi_dung}
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={[index, 'id']} noStyle hidden>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name={[index, 'value']} noStyle>
                                                <InputNumber inputMode='numeric' placeholder='Nhập số lượng' min={0} style={{ width: '100%' }} required />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            })}
                    </Form.List>
                    {errorsListHistory.length > 0 && <div className='d-flex flex-column mt-2'>
                        <Title level={5}>Lịch sử ghi nhận</Title>
                        <Table bordered size='small' pagination={false} columns={errorLogColumns} dataSource={errorsListHistory} 
                        scroll={{y: '40vh'}}
                        expandable={{
                            columnWidth: 30,
                            rowExpandable: (record) => (record.log ?? []).length,
                            expandedRowRender: (record) =>
                                expandedTable(record.log ?? []),
                        }}
                            summary={(pageData) => {
                                let total = 0;
                                pageData.forEach(({ quantity }) => {
                                    total += quantity;
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={2}><strong>Tổng SL:</strong></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align='center'>
                                                <strong>{total}</strong>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        >
                        </Table>
                    </div>}
                    <Row gutter={8}>
                        <Col span={12} className='mt-3'>
                            <Form.Item noStyle>
                                <Button type='primary' style={{ width: '100%' }} onClick={() => form.submit()}>Lưu dữ liệu</Button>
                            </Form.Item>
                        </Col>
                        <Col span={12} className='mt-3'>
                            <Form.Item noStyle>
                                <Button style={{ width: '100%' }} onClick={() => resumeTracking()}>Tiếp tục ghi nhận sl NG</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default PopupNGTracking;