import axios from "axios";
const prefix = "p2/ui/";
//ProductionOrderPriorities
export async function getProductionOrderPriorities(params) {
  const res = await axios.get(prefix + "production-order-priorities", {
    params,
  });
  return res;
}
export async function createProductionOrderPriorities(params) {
  const res = await axios.post(prefix + "production-order-priorities", params);
  return res;
}
export async function updateProductionOrderPriorities(params) {
  const res = await axios.post(
    prefix + "production-order-priorities/update",
    params
  );
  return res;
}
export async function deleteProductionOrderPriorities(id) {
  const res = await axios.delete(prefix + "production-order-priorities/" + id);
  return res;
}
export async function exportProductionOrderPriorities(params) {
  const res = await axios.get(prefix + "production-order-priorities/export", {
    params,
  });
  return res;
}
export async function reorderProductionOrder(params) {
  const res = await axios.post(
    prefix + "production-order-priorities/reorder",
    params
  );
  return res;
}
export async function completeProductionOrderPriorities(params) {
  const res = await axios.post(
    prefix + "production-order-priorities/complete",
    params
  );
  return res;
}
