import axios from "axios";
const prefix = 'p2/v2/oi/';
export async function getLotProductionList(params) {
    const res = await axios.get(prefix+'lot-production-list', {params});
    return res;
}
export async function scanMaterial(params) {
    const res = await axios.post(prefix+'scan-material', params);
    return res;
}
export async function scanManufacture(params) {
    const res = await axios.post(prefix+'scan-manufacture', params);
    return res;
}
export async function scanForSelectionLineV2(params) {
    const res = await axios.post(prefix+'scan-for-selection-line', params);
    return res;
}
export async function endOfProduction(params) {
    const res = await axios.post(prefix+'end-of-production', params);
    return res;
}