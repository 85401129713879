import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getMachinePriorityOrders(params) {
    const res = await axios.get(prefix+'machine-priority-orders', { params });
    return res;
}
export async function createMachinePriorityOrders(params) {
    const res = await axios.post(prefix+'machine-priority-orders', params);
    return res;
}
export async function updateMachinePriorityOrders(id, params) {
    const res = await axios.patch(prefix+'machine-priority-orders/' + id, params);
    return res;
}
export async function deleteMachinePriorityOrders(id) {
    const res = await axios.delete(prefix+'machine-priority-orders/' + id);
    return res;
}
export async function deleteManyMachinePriorityOrders(ids) {
    const res = await axios.post(prefix+'machine-priority-orders/delete', {ids});
    return res;
}
export async function exportMachinePriorityOrders(params) {
    const res = await axios.get(prefix+'machine-priority-orders/export', { params });
    return res;
}
export async function importMachinePriorityOrders(params) {
    const res = await axios.post(prefix+'machine-priority-orders/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}
export async function saveAllMachinePriorityOrders(params) {
    const res = await axios.post(prefix+'machine-priority-orders/save-all', params);
    return res;
}