import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Spin,
  FloatButton,
  Form,
  message,
} from "antd";
import DataDetail2 from "../../../components/DataDetail2";
import "../style.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ScanButton from "../../../components/Button/ScanButton";
import SelectButton from "../../../components/Button/SelectButton";
import { useReactToPrint } from "react-to-print";
import Tem from "../../UI/Manufacture/Tem";
import { useRef } from "react";
import echo from "../../../helpers/echo";
import { RollbackOutlined } from "@ant-design/icons";
import {
  // endOfProduction,
  // getLotProductionList,
  getMachineList,
  // scanMaterial,
  getProductionOverall,
  // scanManufacture,
  getLineList,
  getLotErrorLogList,
  reprintTem,
} from "../../../api/phase2/oi/manufacture";
import {
  endOfProduction,
  getLotProductionList,
  scanMaterial,
  scanManufacture,
} from "../../../api/phase2/oi/manufactureV2";
import PopupDauNoi from "../../../components/Popup/PopupDauNoi";
import axios from "axios";
import PlanControlTable from "../../../components/Table/PlanControlTable";
import PopupNGTracking from "../../../components/Popup/PopupNGTracking";

const defaultRow1 = [
  {
    title: "SL KH ngày",
    key: "tong_sl_trong_ngay_kh",
  },
  {
    title: "SL T.Tế",
    key: "tong_sl_thuc_te",
  },
  {
    title: "SL Tem vàng",
    key: "tong_sl_tem_vang",
    bg: "#f7ac27",
  },
  {
    title: "SL NG",
    key: "tong_sl_ng",
    bg: "#fb4b50",
  },
  {
    title: "Tỷ lệ hoàn thành (%)",
    key: "ty_le_hoan_thanh",
  },
];
const defaultRow2 = [
  {
    title: "Mã Palet",
    key: "lot_id",
  },
  {
    title: "Tên sản phẩm",
    key: "ten_sp",
  },
  {
    title: "UPH (Ấn định)",
    key: "uph_an_dinh",
  },
  {
    title: "UPH (Thực tế)",
    key: "uph_thuc_te",
  },
  {
    title: "SL đầu ra (KH)",
    key: "sl_dau_ra_kh",
  },
  {
    title: "SL đầu ra (TT)",
    key: "sl_dau_ra_hang_loat",
  },
  {
    title: "SL đầu ra (TT OK)",
    key: "sl_dau_ra_ok",
  },
  {
    title: "Hao phí sản xuất",
    key: "hao_phi",
  },
  {
    title: "Định mức hao phí công đoạn",
    key: "hao_phi_cong_doan",
  },
];
const columns = [
  {
    title: "STT",
    dataIndex: "index",
    key: "index",
    align: "center",
    width: 60,
    render: (value, record, index) => index + 1,
  },
  {
    title: "Lô sản xuất",
    dataIndex: "lo_sx",
    key: "lo_sx",
    align: "center",
  },
  {
    title: "Mã NVL vào",
    dataIndex: "input_lot_id",
    key: "input_lot_id",
    align: "center",
  },
  {
    title: "Mã Pallet/cuộn",
    dataIndex: "lot_id",
    key: "lot_id",
    align: "center",
  },
  {
    title: "Tên sản phẩm",
    dataIndex: "ten_sp",
    key: "ten_sp",
    align: "center",
  },
  {
    title: "Mã hàng",
    dataIndex: "ma_hang",
    key: "ma_hang",
    align: "center",
  },
  {
    title: "Kế hoạch",
    key: "ke_hoach",
    children: [
      {
        title: "TG bắt đầu",
        dataIndex: "thoi_gian_bat_dau_kh",
        key: "thoi_gian_bat_dau_kh",
        align: "center",
      },
      {
        title: "Số lượng đầu ra (pcs)",
        dataIndex: "sl_dau_ra_kh",
        key: "sl_dau_ra_kh",
        align: "center",
      },
    ],
  },
  {
    title: "Thực tế",
    key: "thuc_te",
    children: [
      {
        title: "TG bắt đầu",
        dataIndex: "thoi_gian_bat_dau",
        key: "thoi_gian_bat_dau",
        align: "center",
      },
      {
        title: "Số lượng đầu vào (m)",
        dataIndex: "sl_dau_vao_hang_loat",
        key: "sl_dau_vao",
        align: "center",
      },
      {
        title: "Số lượng đầu ra (pcs)",
        dataIndex: "sl_dau_ra_hang_loat",
        key: "sl_dau_ra",
        align: "center",
      },
      {
        title: "Số lượng đầu ra OK (pcs)",
        dataIndex: "sl_dau_ra_ok",
        key: "sl_dau_ra_kh",
        align: "center",
      },
      {
        title: "Số lượng tem vàng (pcs)",
        dataIndex: "sl_tem_vang",
        key: "sl_tem_vang",
        align: "center",
        className: "yellow",
      },
      {
        title: "Số dấu nối",
        dataIndex: "so_dau_noi",
        key: "so_dau_noi",
        align: "center",
      },
      {
        title: "Số lượng NG (pcs)",
        dataIndex: "sl_ng",
        key: "sl_ng",
        align: "center",
        className: "red",
      },
    ],
  },
  {
    title: "Tỉ lệ hoàn thành (%)",
    dataIndex: "ti_le_ht",
    key: "ti_le_ht",
    align: "center",
    // render: (_, record) => record?.status === 1 ? (record.sl_dau_ra_ok > 0 ? ((record.sl_dau_ra_ok / record.sl_ke_hoach) * 100).toFixed(0) : 0) + '%' : _,
  },
];
const P2ManufactureV2 = (props) => {
  document.title = "Sản xuất";
  const { line } = useParams();
  const history = useHistory();
  const [options, setOption] = useState([]);
  const [machineOptions, setMachineOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLot, setSelectedLot] = useState();
  const [listCheck, setListCheck] = useState([]);
  const [isQualityCheck, setIsQualityCheck] = useState(true);
  const machine =
    new URLSearchParams(window.location.search).get("machine") || "";
  const [form] = Form.useForm();
  const [errorList, setErrorList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [dataOverall, setDataOverall] = useState();
  const planControlTableRef = useRef();

  const fetchLine = async () => {
    const lineList = await getLineList({ type: "sx" });
    setOption(lineList.data);
    // setMachineOption([]);
  };
  const fetchOverall = async () => {
    const lineOverall = await getProductionOverall({
      line_id: line,
      machine_code: machine,
    });
    setDataOverall(lineOverall.data);
  };
  const fetchLotDataTable = async () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setLoading(true);
    const infoPallet = await getLotProductionList({
      line_id: line,
      machine_code: machine,
    });
    setIsQualityCheck(true);
    if (infoPallet.success) {
      setData(
        (infoPallet.data ?? []).map((e, i) => {
          if (e.status === 1) {
            setSpecifiedRowKey(i);
            if (e.is_qc === 1) {
              setIsQualityCheck(false);
            }
          }
          return { ...e, key: i };
        })
      );
      setSelectedLot(infoPallet.data.find((e) => e.status === 1));
    } else {
      setSelectedLot();
    }
    setErrorList([]);
    setListCheck([]);
    setLoading(false);
  };
  const fetchMachine = async () => {
    const machineList = await getMachineList({ line });
    setMachineOption(
      machineList.data.map((e) => ({ ...e, value: e.code, label: e.code }))
    );
  };
  const redirectBetweenView = (machineOptions, machine) => {
    if (machineOptions.length) {
      if (!machine) {
        history.push({ search: "?machine=" + machineOptions[0]?.value });
      } else {
        const target = machineOptions.find((e) => e.value === machine);
        if (!target?.is_iot) {
          history.push({
            pathname: "/manual-manufacture/" + line,
            search: "?machine=" + target?.value,
          });
        } else {
          history.push({
            pathname: "/manufacture/" + line,
            search: "?machine=" + target?.value,
          });
        }
      }
    }
  };
  useEffect(() => {
    (async () => {
      await fetchLine();
    })();
  }, []);
  useEffect(() => {
    fetchMachine();
  }, [line]);
  useEffect(() => {
    redirectBetweenView(machineOptions, machine);
  }, [machineOptions]);
  useEffect(() => {
    fetchOverall();
    setSpecifiedRowKey(null);
    if (line && machine) {
      fetchLotDataTable();
    }
  }, [line, machine]);
  const [data, setData] = useState([]);
  const onChangeLine = (value) => {
    history.push("/manufacture/" + value);
  };
  const onChangeMachine = (value) => {
    redirectBetweenView(machineOptions, value);
  };
  const onScan = async (result) => {
    if (line === "24") {
      const params = {
        roll_id: result,
        line_id: line,
        machine_code: machine,
      };
      const res = await scanMaterial(params);
      if (res.success) {
        fetchLotDataTable();
      }
    } else {
      const params = {
        machine_code: machine,
        line_id: line,
        scanned_lot: result,
      };
      const res = await scanManufacture(params);
      if (res.success) {
        fetchLotDataTable();
      }
    }
  };

  const rowClassName = (record, index) => {
    switch (record.status) {
      case 1:
        return "table-row-green";
        break;
      case 2:
        return "table-row-grey";
        break;
      default:
        return "";
        break;
    }
  };

  useEffect(() => {
    if (!selectedLot) {
      setListCheck([]);
    }
  }, [selectedLot]);

  const componentRef1 = useRef();
  const handlePrint = async () => {
    if (selectedLot) {
      const res = await endOfProduction({
        lot_id: selectedLot.lot_id,
        machine_code: machine,
        line_id: line,
      });
      if (res.success) {
        setListCheck([res.data]);
        fetchLotDataTable();
        if (planControlTableRef.current) {
          planControlTableRef.current.runChildFunction();
        }
      }
    }
  };

  const handleReprint = async () => {
    if (selectedRows.length > 0) {
      const data = selectedRows.map(({ info_id, lot_id }) => ({ info_id, lot_id }));
      const res = await reprintTem({
        list: data
      });
      if (res.success) {
        setListCheck(res.data || []);
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }
    }
  };

  const print = useReactToPrint({
    content: () => componentRef1.current,
  });
  useEffect(() => {
    if (listCheck.length > 0) {
      print();
    }
  }, [listCheck]);

  useEffect(() => {
    echo.connector.socket.on("connect", () => {
      console.log("WebSocket connected!");
    });
    echo.connector.socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });
    echo.connector.socket.on("disconnect", () => {
      console.log("WebSocket disconnected!");
    });
    echo
      .channel("laravel_database_production-channel")
      .listen(".production-updated", (e) => {
        console.log(e);
        setData((prevData) => {
          let updatedData = prevData.map((row) => {
            if (
              row.lot_id === e?.productionData?.lot_id &&
              (new URLSearchParams(window.location.search).get("machine") ||
                "") === e?.productionData?.machine_code
            ) {
              setSelectedLot({
                ...row,
                ...e.productionData,
                sl_dau_ra_ok:
                  e.productionData.sl_dau_ra_hang_loat -
                  row.sl_tem_vang -
                  row.sl_ng,
              });
              return {
                ...row,
                ...e.productionData,
                sl_dau_ra_ok:
                  e.productionData.sl_dau_ra_hang_loat -
                  row.sl_tem_vang -
                  row.sl_ng,
              };
            }
            return row;
          });
          return updatedData;
        });
      });
    echo
      .channel("laravel_database_quality-channel")
      .listen(".quality-updated", (e) => {
        console.log(e, machine, e?.qualityData?.is_check);
        if (
          (new URLSearchParams(window.location.search).get("machine") || "") ===
          e?.qualityData?.machine_code
        ) {
          setIsQualityCheck(e?.qualityData?.is_check);
        }
      });
    return () => {
      echo.leaveChannel("laravel_database_production-channel");
      echo.leaveChannel("laravel_database_quality-channel");
      // echo.disconnect();
    };
  }, []);

  const tableRef = useRef();
  const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
  const handleScrollToRow = () => {
    if (specifiedRowKey !== null && tableRef.current) {
      tableRef.current?.scrollTo({ key: specifiedRowKey, behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      handleScrollToRow();
    }
  }, [specifiedRowKey]);

  const openErrorListModal = async () => {
    // if (selectedLot) {
    //   var res = await getLotErrorLogList({ lot_id: selectedLot.lot_id });
    //   if (res.success) {
    //     setErrorList(res.data.errorList ?? []);
    //     form.setFieldsValue(res.data.log ?? {});
    //     // setOpenModalErrorList(true);
    //   }
    // }
  };
  useEffect(() => {
    form.setFieldsValue({
      errorList: errorList.map((item) => ({
        title: item.id,
        value: item.value,
      })),
    });
  }, [form, errorList]);

  useEffect(() => {
    // (async () => {
    //   const data = [{}].map(({ info_id, lot_id }) => ({ info_id, lot_id: '25010034.L.0004' }));
    //   const res = await reprintTem({
    //     list: data
    //   });
    //   if (res.success) {
    //     setListCheck(res.data || []);
    //     setSelectedRowKeys([]);
    //     setSelectedRows([]);
    //   }
    // })();
  }, []);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status !== 2,
    }),
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Row className="mt-3" gutter={[12, 12]}>
          <Col span={4}>
            <SelectButton
              value={options.length > 0 && parseInt(line)}
              options={options}
              label="Chọn công đoạn"
              onChange={onChangeLine}
            />
          </Col>
          <Col span={4}>
            <SelectButton
              value={
                machineOptions.length > 0 &&
                  machineOptions.some((e) => e.value == machine)
                  ? machine
                  : null
              }
              options={machineOptions}
              label="Chọn thiết bị"
              onChange={onChangeMachine}
              allowClear={true}
            />
          </Col>
          <Col span={16}>
            <DataDetail2 data={defaultRow1} value={dataOverall} />
          </Col>
          <Col span={24}>
            <ScanButton onScan={onScan} />
          </Col>
          <Col span={24}>
            <PlanControlTable ref={planControlTableRef} machineId={machine} lineId={line} onComplete={fetchLotDataTable} />
          </Col>

          <Col span={24}>
            <div className="d-flex gap-2 w-100 justify-content-around h-100">
              {selectedRows.length > 0 && (
                <Button
                  block
                  size="large"
                  type="primary"
                  style={{ height: "100%", width: "100%" }}
                  onClick={handleReprint}
                >
                  In lại tem
                </Button>
              )}
              <PopupNGTracking
                infoCongDoanId={selectedLot?.id}
                lotId={selectedLot?.lot_id}
                onSubmit={fetchLotDataTable}
                data={errorList}
              />
              <PopupDauNoi
                lotId={selectedLot?.lot_id}
                onSubmit={fetchLotDataTable}
                data={errorList}
              />
              <Button
                disabled={isQualityCheck}
                block
                size="large"
                type="primary"
                style={{ height: "100%", width: "100%" }}
                onClick={handlePrint}
              >
                In tem
              </Button>
            </div>
            <div className="report-history-invoice">
              <Tem listCheck={listCheck} ref={componentRef1} />
              {/* <TemTest listCheck={listCheck} ref={componentRef1} /> */}
            </div>
          </Col>
          <Col span={24}>
            <Table
              ref={tableRef}
              scroll={{
                x: 200,
                y: 350,
              }}
              rowHoverable={false}
              size="small"
              rowClassName={rowClassName}
              pagination={false}
              bordered
              columns={columns}
              dataSource={data}
              rowSelection={rowSelection}
              footer={() =>
                specifiedRowKey !== null ? (
                  <FloatButton
                    type="primary"
                    icon={<RollbackOutlined />}
                    tooltip={<div>Quay lại lot đang chạy</div>}
                    style={{ position: "absolute", bottom: 40, right: 30 }}
                    onClick={handleScrollToRow}
                  />
                ) : null
              }
            />
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};

export default P2ManufactureV2;
