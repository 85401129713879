import axios from "axios";
const prefix = "p2/ui/master-data/";
//ProductionJourney
export async function getProductionJourney(params) {
  const res = await axios.get(prefix + "production-journey", {
    params,
  });
  return res;
}
export async function createProductionJourney(params) {
  const res = await axios.post(prefix + "production-journey", params);
  return res;
}
export async function updateProductionJourney(id, params) {
  const res = await axios.patch(
    prefix + "production-journey/" + id,
    params
  );
  return res;
}
export async function deleteProductionJourney(id) {
  const res = await axios.delete(prefix + "production-journey/" + id);
  return res;
}
export async function deleteMultipleProductionJourney(params) {
    const res = await axios.post(prefix + "production-journeydelete", params);
    return res;
}
export async function exportProductionJourney(params) {
  const res = await axios.get(prefix + "production-journey/export", {
    params,
  });
  return res;
}
export async function saveAllProductionJourney(params) {
  const res = await axios.post(prefix + 'production-journey/save-all', params);
  return res;
}
