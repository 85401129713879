import { EditOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Divider,
  Button,
  Form,
  Select,
  Upload,
  message,
  Space,
  Radio,
  Modal,
  Input,
  Popconfirm,
  Badge,
  Tag,
} from "antd";
import { baseURL } from "../../../config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { getCustomers, getDataFilterUI } from "../../../api/ui/main";
import {
  getWarehouseExportPlan,
  updateWHExport,
  deleteWHExport,
  approvalWHExport,
} from "../../../api";
import dayjs from "dayjs";
import { getTreeSelect } from "../../../api/phase2/ui/main";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { manufatureQuicker } from "../../../routes/quickerCollection";

const { RangePicker } = DatePicker;

const LenhGiaoHang = () => {
  const [listLines, setListLines] = useState([]);
  const [listNameProducts, setListNameProducts] = useState([]);
  const [listLoSX, setListLoSX] = useState([]);
  const [listCustomers, setListCustomers] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [form] = Form.useForm();
  const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
  const [dateType, setDateType] = useState("date");
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
      align: "center",
      fixed: "left",
      width: "70px",
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "ngay_xuat_hang",
      align: "center",
      width: "120px",
      render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Số lượng nợ đơn hàng",
      align: "center",
      children: [
        {
          title: "Mã hàng hóa",
          dataIndex: "product_id",
          align: "center",
          width: "100px",
        },
        {
          title: "Tên hàng hóa",
          dataIndex: "ten_san_pham",
          align: "center",
          width: "200px",
        },
        {
          title: "SL yêu cầu giao",
          dataIndex: "sl_yeu_cau_giao",
          align: "center",
          width: "200px",
        },
      ],
    },
    {
      title: "ĐVT",
      dataIndex: "dvt",
      align: "center",
      width: "100px",
    },
    {
      title: "Tổng KG",
      dataIndex: "tong_kg",
      align: "center",
      width: "100px",
    },
    {
      title: "Tồn kho",
      dataIndex: "ton_kho",
      align: "center",
      width: "100px",
    },
    {
      title: "Xác nhận SX",
      dataIndex: "xac_nhan_sx",
      align: "center",
      width: "100px",
      render: (_, record) =>
        record.approval ? (
          <Tag size="small" color="success">
            Đã xác nhận
          </Tag>
        ) : (
          <Tag color="default">Chưa xác nhận</Tag>
        ),
    },
    {
      title: "SL thực xuất",
      dataIndex: "sl_thuc_xuat",
      align: "center",
      width: "100px",
    },
    {
      title: "Quy cách (số thùng/bó)",
      dataIndex: "quy_cach",
      align: "center",
      width: "100px",
    },
    {
      title: "Cửa giao hàng",
      dataIndex: "cua_xuat_hang",
      align: "left",
      width: "300px",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghi_chu",
      align: "left",
      width: "500px",
    },
    {
      title: "Hành động",
      key: "actions",
      align: "center",
      fixed: "right",
      width: "90px",
      render: (_, record) => (
        <Space>
          <EditOutlined
            className="edit-btn"
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Xoá bản ghi"
            description={"Bạn có chắc muốn xoá?"}
            onConfirm={() => handleDelete(record)}
            okText="Có"
            cancelText="Không"
          >
            <DeleteOutlined className="delete-btn" />
          </Popconfirm>
          {!record.approval && <CheckOutlined
            style={{ color: "green", fontSize: "16px" }}
            onClick={() => handleApproval(record)}
          />}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    (async () => {
      const res1 = await getTreeSelect();
      setListLines(res1.data);
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    })();
  }, []);
  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleApproval = async (record) => {
    const res = await approvalWHExport({ id: record.id });
    if (res.success) {
      setData((prevData) =>
        prevData.map((item) =>
          item.id === record.id ? { ...item, approval: true } : item
        )
      );
    }
  };

  const handleDelete = async (record) => {
    const res = await deleteWHExport({ id: record.id });
    if (res.success) {
      setData((prevData) => prevData.filter((item) => item.id !== record.id));
    }
  };

  const handleEditSubmit = async () => {
    const res = await updateWHExport(editingRecord);
    if (res.success) {
      setData((prevData) =>
        prevData.map((item) =>
          item.id === editingRecord.id ? editingRecord : item
        )
      );
      setIsEditModalVisible(false);
    }
  };

  function btn_click(page = 1, pageSize = 20) {
    setPage(page);
    setPageSize(pageSize);
    loadListTable({ ...params, page, pageSize });
  }

  useEffect(() => {
    (async () => {
      var res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
        setListLoSX(
          Object.values(res.data.lo_sx).map((e) => {
            return { label: e, value: e };
          })
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    if (params?.date && params?.date?.length === 2) {
      if (params?.date[0])
        params.start_date = dayjs(params.date[0]).format("YYYY-MM-DD");
      if (params?.date[1])
        params.end_date = dayjs(params.date[1]).format("YYYY-MM-DD");
    }
    const res = await getWarehouseExportPlan(params);
    if (res.success) {
      if (res.data?.paginate) {
        setPage(res.data.paginate.page);
        setPageSize(res.data.paginate.page_size);
        setTotalPage(res.data.paginate.total_items);
      }
      setData(res.data?.data || []);
    }
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
    })();
  }, []);

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Upload file thành công",
    });
  };

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
        break;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
        break;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
        break;
      default:
        return dayjs(value).format("DD/MM/YYYY");
        break;
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.date[0], dateType);
    onChangeDate("end_date", params.date[1], dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("isoWeek"),
              dayjs(params.date[1]).endOf("isoWeek"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("isoWeek"),
              dayjs(value).endOf("isoWeek"),
            ],
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("month"),
              dayjs(params.date[1]).endOf("month"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("month"),
              dayjs(value).endOf("month"),
            ],
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            date: [
              dayjs(value).startOf("year"),
              dayjs(params.date[1]).endOf("year"),
            ],
          });
        } else {
          setParams({
            ...params,
            date: [
              dayjs(params.date[0]).startOf("year"),
              dayjs(value).endOf("year"),
            ],
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] });
        } else {
          setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
        }
        break;
    }
  };
  const renderQuicker = () => {
    console.log(window.location.pathname);
    const className = (path) => {
      if (window.location.pathname.indexOf(path) > -1) {
        return "active";
      } else {
        return "inactive";
      }
    };
    return (
      <ul>
        {manufatureQuicker.map((e) => {
          return (
            <li>
              <Link to={e.path} className={"quicker " + className(e.path)}>
                {e.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <>
      {contextHolder}
      <Row style={{ padding: "8px" }} gutter={[8, 8]} className="custom-row">
        <Col span={4} className="custom-col">
          <Card
            bodyStyle={{ padding: 0 }}
            className="custom-ant-card"
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <Form style={{ margin: "0 8px" }} layout="vertical">
              <Divider>Quicker</Divider>
              {renderQuicker()}
              <Divider>Thời gian truy vấn</Divider>
              <Radio.Group
                options={[
                  {
                    value: "date",
                    label: "Ngày",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "week",
                    label: "Tuần",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "month",
                    label: "Tháng",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                  {
                    value: "year",
                    label: "Năm",
                    style: {
                      width: "25%",
                      justifyContent: "center",
                      display: "flex",
                    },
                  },
                ]}
                className="d-flex justify-content-center mb-2 w-100"
                onChange={(e) => setDateType(e.target.value)}
                value={dateType}
                optionType="button"
                buttonStyle="solid"
              />
              <Space direction="vertical" style={{ width: "100%" }}>
                <DatePicker
                  allowClear={false}
                  placeholder="Bắt đầu"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("start_date", value, dateType)
                  }
                  value={params.date[0]}
                  format={customDateFormat}
                  picker={dateType}
                />
                <DatePicker
                  allowClear={false}
                  placeholder="Kết thúc"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    onChangeDate("end_date", value, dateType)
                  }
                  value={params.date[1]}
                  format={customDateFormat}
                  picker={dateType}
                />
              </Space>
              <Divider>Điều kiện truy vấn</Divider>
              <Form.Item label="Khách hàng" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn khách hàng"
                  onChange={(value) =>
                    setParams({ ...params, khach_hang: value })
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                  options={listCustomers}
                />
              </Form.Item>
              <Form.Item label="Tên sản phẩm" className="mb-3">
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => {
                    setParams({ ...params, ten_sp: value });
                  }}
                  placeholder="Nhập tên sản phẩm"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listNameProducts}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={20} className="custom-col-table">
          <Card
            style={{ height: "100%" }}
            title="Lệnh giao hàng"
            extra={
              <Space>
                <Upload
                  showUploadList={false}
                  name="file"
                  action={baseURL + "/api/p2/ui/warehouse-export-plans-import"}
                  headers={{
                    authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser"))?.token
                      }`,
                  }}
                  onChange={(info) => {
                    setLoadingUpload(true);
                    if (info.file.status === "error") {
                      setLoadingUpload(false);
                      message.error(
                        info.file?.response?.message ??
                        `${info.file.name} - Thực hiện thất bại`
                      );
                    } else if (info.file.status === "done") {
                      if (info.file.response.success === true) {
                        loadListTable(params);
                        success();
                        setLoadingUpload(false);
                      } else {
                        loadListTable(params);
                        message.error(info.file.response.message);
                        setLoadingUpload(false);
                      }
                    }
                  }}
                >
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    loading={loadingUpload}
                  >
                    Upload Excel
                  </Button>
                </Upload>
              </Space>
            }
          >
            <Table
              size="small"
              bordered
              locale={{ emptyText: "" }}
              className="table-lgh"
              loading={loading}
              pagination={{
                current: page,
                pageSize: pageSize,
                size: "small",
                total: totalPage,
                onChange: (page, pageSize) => {
                  btn_click(page, pageSize);
                },
              }}
              scroll={{
                x: "1440px",
                y: CalculateTableHeight("table-lgh"),
              }}
              columns={columns}
              dataSource={data}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Cập nhật"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleEditSubmit}
      >
        <Form layout="vertical">
          <Form.Item label="SL tồn kho">
            <Input
              value={editingRecord?.ton_kho}
              onChange={(e) =>
                setEditingRecord({
                  ...editingRecord,
                  ton_kho: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="SL yêu cầu giao">
            <Input
              value={editingRecord?.sl_yeu_cau_giao}
              onChange={(e) =>
                setEditingRecord({
                  ...editingRecord,
                  sl_yeu_cau_giao: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LenhGiaoHang;
