import axios from "axios";
const prefix = 'p2/ui/master-data/';
export async function getQCCriteria(params) {
    const res = await axios.get(prefix+'qc-criteria', { params });
    return res;
}
export async function createQCCriteria(params) {
    const res = await axios.post(prefix+'qc-criteria', params);
    return res;
}
export async function updateQCCriteria(id, params) {
    const res = await axios.patch(prefix+'qc-criteria/' + id, params);
    return res;
}
export async function deleteQCCriteria(id) {
    const res = await axios.delete(prefix+'qc-criteria/' + id);
    return res;
}
export async function deleteManyQCCriteria(ids) {
    const res = await axios.post(prefix+'qc-criteria/delete', {ids});
    return res;
}
export async function exportQCCriteria(params) {
    const res = await axios.get(prefix+'qc-criteria/export', { params });
    return res;
}
export async function importQCCriteria(params) {
    const res = await axios.post(prefix+'qc-criteria/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}
export async function saveAllQCCriteria(params) {
    const res = await axios.post(prefix+'qc-criteria/save-all', params);
    return res;
}