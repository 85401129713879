import axios from "axios";
const prefix = 'p2/oi/';
export async function getLineList(params) {
    const res = await axios.get(prefix+'line-list', {params});
    return res;
}
export async function getMachineList(params) {
    const res = await axios.get(prefix+'machine-list', {params});
    return res;
}
export async function getProductionOverall(params) {
    const res = await axios.get(prefix+'production-overall', {params});
    return res;
}
export async function getLotProductionList(params) {
    const res = await axios.get(prefix+'lot-production-list', {params});
    return res;
}
export async function scanMaterial(params) {
    const res = await axios.post(prefix+'scan-material', params);
    return res;
}
export async function scanManufacture(params) {
    const res = await axios.post(prefix+'scan-manufacture', params);
    return res;
}
export async function getLotErrorLogList(params) {
    const res = await axios.get(prefix+'lot-error-log-list', {params});
    return res;
}
export async function findError(params) {
    const res = await axios.post(prefix+'find-error', params);
    return res;
}
export async function updateLotErrorLog(params) {
    const res = await axios.post(prefix+'update-lot-error-log', params);
    return res;
}
export async function endOfProduction(params) {
    const res = await axios.post(prefix+'end-of-production', params);
    return res;
}
export async function reprintTem(params) {
    const res = await axios.post(prefix+'reprint-tem', params);
    return res;
}

export async function scanForSelectionLine(params) {
    const res = await axios.post(prefix+'scan-for-selection-line', params);
    return res;
}
export async function getAssignment(params) {
    const res = await axios.get(prefix+'assignment', {params});
    return res;
}

export async function getInfoPrintSelection(params) {
    const res = await axios.get(prefix+'get-info-print-selection', {params});
    return res;
}

export async function createAssignment(params) {
    const res = await axios.post(prefix+'assignment', params);
    return res;
}
export async function deleteAssignment(id) {
    const res = await axios.delete(prefix+'assignment/'+id);
    return res;
}
export async function updateAssignment(id, params) {
    const res = await axios.patch(prefix+'assignment/'+id, params);
    return res;
}
export async function printTemSelectionLine(params) {
    const res = await axios.post(prefix+'print-tem-selection-line', params);
    return res;
}
export async function updateOutputProduction(params) {
    console.log(params);
    
    const res = await axios.post(prefix+'update-output-production', params);
    return res;
}

export async function changeStatusNgTracking(params) {
    const res = await axios.post(prefix+'change-status-ng-tracking', params);
    return res;
}
export async function stopNgTracking(params) {
    const res = await axios.post(prefix+'stop-ng-tracking', params);
    return res;
}
export async function checkNgTracking(params) {
    const res = await axios.post(prefix+'check-ng-tracking', params);
    return res;
}
export async function getNGTrackingResultList(params) {
    const res = await axios.get(prefix+'get-ng-tracking-result-list', {params});
    return res;
}