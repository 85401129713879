import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, SaveOutlined, SaveTwoTone, UploadOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, Calendar, Typography, Image, Empty, Popconfirm } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getAllMachine, getCustomers, getDataFilterUI, getLines, getTreeLines } from '../../../api/ui/main';
import { exportKHSX, exportMaintenanceDetail, exportMaintenanceDetailStatus, getListProductPlan } from '../../../api';
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "./maintenance.css"
import { getMaintenancePlansDetail, getMaintenanceDetails, getMaintenancePlansList, getMaintenanceCategories, getMaintenanceItems, uploadMaintenanceLogImages, getMaintenanceLogImages, updateMaintenanceLogs, getMaintenanceSchedules, updateMaintenanceSchedules, updateMaintenanceLogImages, createMaintenanceLogImages, createMaintenanceLogs, getMaintenancePlans, deleteMaintenanceSchedules, deleteMaintenanceLogs, completeAllSelectedMaintenanceDetail } from '../../../api/phase2/ui/maintenance';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { maintenanceQuicker } from '../../../routes/quickerCollection';
dayjs.extend(customParseFormat)
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const DetailMaintenance = () => {
    const [machines, setMachines] = useState([]);
    const [treeLines, setTreeLines] = useState([]);
    const [maintenanceCategories, setMaintenanceCategories] = useState([]);
    const [maintenanceItems, setMaintenanceItems] = useState([]);
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [form] = Form.useForm();
    const [dateType, setDateType] = useState('date');
    const [selectedMaintenance, setSelectedMaintenance] = useState();
    const [loadingUpload, setLoadingUpload] = useState(false);
    useEffect(() => {
        fetchTreeLines();
        fetchMachines();
        fetchMaintenanceCategories();
        fetchMaintenanceItems();
    }, []);

    const fetchMachines = async () => {
        const res = await getAllMachine();
        setMachines((res.data ?? []).map(e => ({ ...e, value: e.code, label: e.code })));
    }

    const fetchTreeLines = async () => {
        const res = await getTreeSelect();
        setTreeLines(res.data);
    }

    const fetchMaintenanceCategories = async () => {
        const res = await getMaintenanceCategories();
        setMaintenanceCategories((res.data ?? []).map(e => ({ ...e, value: e.id, label: e.name })));
    }

    const fetchMaintenanceItems = async () => {
        const res = await getMaintenanceItems();
        setMaintenanceItems((res.data ?? []).map(e => ({ ...e, value: e.id, label: e.name })));
    }

    function btn_click() {
        loadListTable(params);
        setSelectedMaintenance(null);
    }
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getMaintenancePlans(params);
        setStatusData(res.data.map((e, i) => ({ ...e, key: i })));
        setLoading(false);
    }
    useEffect(() => {
        btn_click();
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const exportRecord1 = async () => {
        setLoadingExport(true);
        const res = await exportMaintenanceDetailStatus(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const exportRecord2 = async () => {
        setLoadingExport(true);
        const res = await exportMaintenanceDetail(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }

    const renderQuicker = () => {
        const className = (path) => {
            if (window.location.pathname.indexOf(path) > -1) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
        return (
            <ul>
                {maintenanceQuicker.map(e => {
                    return (
                        <li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
                    )
                })}
            </ul>
        )
    }

    const [editingRow, setEditingRow] = useState();

    const isEditing = (index) => editingRow?.key === (index);

    const statusColumns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            align: 'center',
            render: (_, item, index) => index + 1,
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line_name',
            align: 'center',
        },
        {
            title: 'Tên máy',
            dataIndex: 'machine_code',
            align: 'center',
        },
        {
            title: 'Số hạng mục',
            dataIndex: 'item_number',
            align: 'center',
        },
        {
            title: 'Kế hoạch',
            dataIndex: 'planning_date',
            align: 'center',
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'start_date',
            align: 'center',
        },
        {
            title: 'Người thực hiện',
            dataIndex: 'user_name',
            align: 'center',
        },
    ];

    const [statusData, setStatusData] = useState([]);

    const checkColumns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            align: 'center',
            render: (_, item, index) => index + 1,
            width: 50
        },
        {
            title: 'Công đoạn',
            dataIndex: 'line_name',
            align: 'center',
            width: 150
        },
        {
            title: 'Tên máy',
            dataIndex: 'machine_code',
            align: 'center',
            width: 100
        },
        {
            title: 'Nhóm hạng mục',
            dataIndex: 'category_name',
            align: 'center',
            width: 120
        },
        {
            title: 'Tên công việc',
            dataIndex: 'item_name',
            align: 'center',
            width: 250
        },
        {
            title: 'Kế hoạch',
            dataIndex: 'planning_date',
            align: 'center',
            width: 100
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'log_date',
            align: 'center',
            width: 120,
            render: (text, record, index) =>
                isEditing(index) ? (
                    <Form.Item name={'log_date'} className='mb-0' label={null} shouldUpdate>
                        <DatePicker />
                    </Form.Item>
                ) : (
                    text ? dayjs(text, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
                )
        },
        {
            title: 'Kết quả',
            dataIndex: 'result',
            align: 'center',
            width: 120,
            render: (text, record, index) =>
                isEditing(index) ? (
                    <Form.Item name={'result'} className='mb-0' label={null} shouldUpdate>
                        <Input />
                    </Form.Item>
                ) : (
                    text
                )
        },
        {
            title: 'Bằng chứng',
            dataIndex: 'evidence',
            align: 'center',
            width: 120,
            render: (text, record) => (
                <div style={{ display: "flex", justifyContent: "center", gap: "10px", alignItems: 'center' }}>
                    {(record.images??[]).map(e=>(
                        <Image
                            width={30}
                            height={30}
                            src={`${baseURL}/${e.url}`}
                            style={{ borderRadius: "5px" }}
                            preview={{
                                mask: (
                                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                        <EyeOutlined />
                                    </div>
                                )
                            }}
                        />
                    ))}
                    {(record.images??[]).length < 3 && <Upload
                        showUploadList={false}
                        customRequest={async ({ file }) => uploadImage(file, record)}
                    >
                        <UploadOutlined className='edit-btn'/>
                    </Upload>}
                </div>
            )
        },
        {
            title: 'Nhận xét',
            dataIndex: 'note',
            align: 'center',
            width: 120,
            render: (text, record, index) =>
                isEditing(index) ? (
                    <Form.Item name={'note'} className='mb-0' label={null} shouldUpdate>
                        <Input />
                    </Form.Item>
                ) : (
                    text
                )
        },
        {
            title: 'Thao tác', dataIndex: 'action', align: 'center', fixed: 'right', width: 100, render: (_, record, index) => (
                isEditing(index) ?
                    <Space wrap>
                        <Form.Item name={'id'} hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'maintenance_schedule_id'} hidden>
                            <Input />
                        </Form.Item>
                        <SaveTwoTone className='edit-btn' title='Lưu' onClick={() => save()} />
                        <CloseOutlined style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => cancel(index)} title='Huỷ' />
                    </Space>
                    :
                    <Space wrap>
                        {!record?.log_id && <CheckOutlined className='check-btn' title='Hoàn thành' onClick={() => complete(record)} />}
                        <EditOutlined className='edit-btn' onClick={() => edit(record)} title='Sửa' />
                        <Popconfirm title="Xoá bản ghi" description={"Bạn có chắc muốn xoá" + (record?.log_id ? ' lịch sử BTBD' : ' lịch trình BTBD') + "?"} okText="Có" cancelText="Không" onConfirm={() => deleteRow(record)}>
                            <DeleteOutlined className='delete-btn' title='Xoá' />
                        </Popconfirm>
                    </Space>
            )
        }
    ];
    const [checkData, setCheckedData] = useState([]);

    const [checkedRow, setCheckedRow] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
        setCheckedRow(selectedRows[0]);
    }
    const rowSelection = {
        fixed: 'left',
        selectedRowKeys,
        columnWidth: 30,
        onChange: onSelectChange,
    };

    const edit = (record) => {
        setEditingRow(record);
        var values = {
            ...record,
            id: record?.log_id,
            maintenance_schedule_id: record?.id,
            log_date: record?.log_date ? dayjs(record?.log_date) : dayjs()
        }
        form.setFieldsValue(values);
    }

    const cancel = () => {
        setEditingRow(null);
        form.resetFields();
    }

    const save = async () => {
        var formData = await form.validateFields();
        console.log(formData);
        const params = { ...formData };
        if (params?.id) {
            var res = await updateMaintenanceLogs(params?.id, {...params, _method: 'PATCH'});
        } else {
            var res = await createMaintenanceLogs(params);
        }
        if (res.success) {
            cancel();
            fetchMaintenanceDetails();
        }
    }

    const complete = async (record) => {
        var values = {
            ...record,
            id: record?.log_id,
            maintenance_schedule_id: record?.id,
            log_date: record?.log_date ? dayjs(record?.log_date) : dayjs()
        }
        const params = { ...values, complete: true };
        if (params?.log_id) {
            var res = await updateMaintenanceLogs(params?.id, {...params, _method: 'PATCH'});
        } else {
            var res = await createMaintenanceLogs(params);
        }
        if (res.success) {
            cancel();
            fetchMaintenanceDetails();
        }
    }

    const completeAll = async () => {
        const selectedData = checkData.filter(e => selectedRowKeys.includes(e.key));
        var res = await completeAllSelectedMaintenanceDetail({ data: selectedData.map(e => ({ ...e, id: e?.log_id, maintenance_schedule_id: e?.id, })), complete: true });
        fetchMaintenanceDetails();
    }

    const deleteRow = async (record) => {
        if (record?.log_id) {
            //delete log
            var res = await deleteMaintenanceLogs(record?.log_id);
            fetchMaintenanceDetails();
        } else {
            //delete schedule (include log in it)
            var res = await deleteMaintenanceSchedules(record?.id);
            fetchMaintenanceDetails();
        }
    }

    const uploadImage = async (file, record) => {
        setLoadingUpload(true);
        const params = new FormData();
        params.append("evidence", file);
        params.append("id", record?.log_id);
        params.append("maintenance_schedule_id", record?.id);
        params.append("log_date", record?.log_date ? dayjs(record?.log_date) : dayjs());
        if (record?.log_id) {
            params.append("_method", 'PATCH');
            var res = await updateMaintenanceLogs(record?.log_id, params);
        } else {
            var res = await createMaintenanceLogs(params);
        }
        fetchMaintenanceDetails();
        setLoadingUpload(false);
    }

    const rowClassName = (record, index) => {
        if (selectedMaintenance?.key === record?.key) {
            return "table-row-green";
        }
        return "";
    };

    useEffect(() => {
        fetchMaintenanceDetails();
    }, [selectedMaintenance])

    const [loadingDetail, setLoadingDetail] = useState(false);
    const fetchMaintenanceDetails = async () => {
        setSelectedRowKeys([]);
        setLoadingDetail(true);
        const res = await getMaintenancePlansDetail({ ...params, machine_code: selectedMaintenance?.machine_code, due_date: selectedMaintenance?.due_date });
        setCheckedData(res.data.map((e, i) => ({ ...e, key: i })));
        setLoadingDetail(false);
    }
    const tableRef = useRef();
    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Công đoạn</Divider>
                            <Form.Item className='mb-3'>
                                {
                                    treeLines.length ?
                                        <Tree
                                            defaultExpandedKeys={[2]}
                                            style={{ maxHeight: 250, overflowY: 'auto' }}
                                            checkable
                                            selectable={false}
                                            onCheck={onCheck}
                                            treeData={treeLines}
                                        />
                                        :
                                        <LoadingOutlined />
                                }
                            </Form.Item>
                            <Divider>Quicker</Divider>
                            {renderQuicker()}
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                size='small'
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Ca sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn ca sản xuất"
                                    onChange={(value) => setParams({ ...params, ca_sx: value })}
                                    optionFilterProp="label"
                                    options={[
                                        { value: 1, label: 'Ca 1' },
                                        { value: 2, label: 'Ca 2' }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Nhân viên" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập tên nhân viên"
                                    optionFilterProp="label"
                                    onChange={(value) => setParams({ ...params, user_id: value })}
                                    options={[]}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            </Col>
            <Col span={20} className="custom-col-table">
                <div className='d-flex flex-column gap-2 h-100'>
                    <Card style={{ height: '30%' }} title="Chi tiết trạng thái" extra={
                        <Space>
                            <Button type="primary" onClick={exportRecord1} loading={loadingExport}>Excel</Button>
                        </Space>
                    }>
                        <Table className="table-top" locale={{ emptyText: <Empty style={{ height: '100vh' }} image={Empty.PRESENTED_IMAGE_SIMPLE} /> }} loading={loading} pagination={false} columns={statusColumns} dataSource={statusData} bordered size='small' onRow={(record, index) => {
                            return {
                                onClick: (event) => {
                                    setSelectedMaintenance(record);
                                },
                            }
                        }} scroll={{ y: CalculateTableHeight('table-top') }} rowClassName={rowClassName} />
                    </Card>
                    <Card style={{ height: '70%' }} title="Chi tiết kiểm tra" extra={
                        <Space>
                            {selectedRowKeys.length > 0 && <Button type="primary" onClick={() => completeAll()}>{`Hoàn thành (${selectedRowKeys.length})`}</Button>}
                            <Button type="primary" onClick={exportRecord2} loading={loadingExport}>Excel</Button>
                        </Space>
                    }>
                        <Form form={form} layout="vertical">
                            <Table
                                ref={tableRef}
                                className='custom-table'
                                loading={loadingDetail}
                                pagination={false}
                                columns={checkColumns}
                                rowSelection={rowSelection}
                                onRow={(record, index) => {
                                    return {
                                        onClick: (event) => {
                                            // console.log(record);
                                            // setSelectedRowKeys([record.key]);
                                            // setCheckedRow(record);
                                        },
                                    }
                                }}
                                dataSource={checkData}
                                bordered
                                size='small'
                                scroll={{ y: CalculateTableHeight('custom-table') }}
                            />
                        </Form>
                    </Card>
                </div>
            </Col>
        </Row>
    </>
}

export default DetailMaintenance;
